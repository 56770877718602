import * as React from 'react'
import { BaseModalProps } from '@modals/types'
import { OfferDescriptionModal } from '@modals/offer-modal/offer-description-modal'
import { IconWithText } from '@components/icon-with-text'
import { formatDate } from '@helpers/date-formatter'
import { compareAsc, parseISO } from 'date-fns'
import { GastroCardDynamicDescriptionMonths } from '@modules/offers/gastro-card/dynamic/details/modals/offer-description/gastro-card-dynamic-description-months'
import { GastroCardDynamicDescriptionBonuses } from '@modules/offers/gastro-card/dynamic/details/modals/offer-description/gastro-card-dynamic-description-bonuses'
import { useGastroCardProduct } from '@modules/offers/gastro-card-boost/details/use-gastro-card-product'

export const GastroCardDynamicDescriptionModal = ({ toggleIsVisible }: BaseModalProps): JSX.Element => {
  const gastroCardDynamic = useGastroCardProduct('gastro_card_dynamic')

  const sortedMultipliers = [...(gastroCardDynamic?.multipliers ?? [])].sort((a, b) =>
    compareAsc(parseISO(a.active_from), parseISO(b.active_from)),
  )

  const months = sortedMultipliers.reduce((prevMonths, multiplier) => {
    const month = formatDate(multiplier.active_from, 'LLLL')

    return prevMonths.some(prevMonth => prevMonth === month) ? prevMonths : [...prevMonths, month]
  }, [])

  return (
    <OfferDescriptionModal
      toggleIsVisible={toggleIsVisible}
      title="Fortuna Podwójnej Radości"
      modalBodyClassName="pb-0"
    >
      <div className="d-flex flex-sm-row flex-column-reverse ps-sm-2">
        <div className="col-lg-8 col-12 pt-3">
          <p>
            Wysokość dopłat w promocji <strong>Fortuna Podwójnej Radości</strong> nie jest stała i będzie się zmieniać w
            czasie. Im szybciej kupisz voucher, tym więcej bonusu zyskasz.
          </p>
          <p className="text-center mb-1">Miesiąc zakupu:</p>
          <div className="overflow-x-auto pe-lg-3">
            <table className="table table-borderless text-darker-gray font-md">
              <tbody>
                <tr>
                  <td className="fw-semi-bold">Wpłata:</td>
                  <GastroCardDynamicDescriptionMonths
                    months={months}
                    gastroCardMultipliers={gastroCardDynamic?.multipliers ?? []}
                  />
                </tr>
                <GastroCardDynamicDescriptionBonuses months={months} sortedGastroMultipliers={sortedMultipliers} />
              </tbody>
            </table>
          </div>
          <div className="border-top mx-lg-n4 px-3 py-2">
            <IconWithText
              text="Zamknij"
              iconClassName="uil-times font-xxl"
              onClick={toggleIsVisible}
              textClassName="font-lg fw-semi-bold ms-1"
            />
          </div>
        </div>

        <img
          className="col-sm-4 d-lg-block d-none gastro-card-dynamic__offer-descripion-modal__image m-sm-auto"
          src={require('@assets/images/offers/gastro-card-dynamic/money-stack.webp')}
          alt="Fortuna Podwójnej Radości Holiday Park & Resort"
        />
      </div>
    </OfferDescriptionModal>
  )
}
