import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { Spinner } from '@components/loaders/spinner'
import * as clsx from 'clsx'

export interface TextWithSpinnerProps {
  isLoading: boolean
  text?: string | React.ReactNode
  iconClassName?: string
  loadingText?: string
  spinnerColor?: string
  wrapperClassName?: string
  onClick?: () => void
}

export const TextWithSpinner = ({
  isLoading,
  text = 'Zapisz',
  loadingText = 'Zapisywanie...',
  iconClassName,
  spinnerColor = 'white',
  wrapperClassName,
  onClick,
}: TextWithSpinnerProps): JSX.Element => (
  <div className={clsx('d-flex justify-content-center align-items-center', wrapperClassName)}>
    <IconWithText
      text={isLoading ? loadingText : text}
      iconClassName={clsx(iconClassName)}
      textClassName="mx-3 fw-semi-bold"
      onClick={onClick}
    />
    {isLoading && <Spinner className="text-primary spinner--small" color={spinnerColor} />}
  </div>
)
