import { createCartItem, useGtmEvents } from '@hooks/use-gtm-events'
import {
  ConvertableCodeAvailableConversion,
  SelectedCodeAvailableConversions,
} from '@modules/offers/codes-conversion/models'

interface Response {
  sendAddToCardEvent: (conversion: ConvertableCodeAvailableConversion) => void
  sendViewItemListEvent: (availableConversions: SelectedCodeAvailableConversions) => void
  sendBeginCheckoutEvent: (
    totalPrice: string,
    kindConversion: ConvertableCodeAvailableConversion | null,
    localizationConversion: ConvertableCodeAvailableConversion | null,
  ) => void
}

export const useCodesConversionGtmEvents = (): Response => {
  const { viewItemList, addToCart, beginCheckout } = useGtmEvents()

  const sendViewItemListEvent = (availableConversions: SelectedCodeAvailableConversions) => {
    if (!availableConversions.kindConversions.length && !availableConversions.localizationConversions.length) return

    viewItemList(
      [
        ...availableConversions.localizationConversions.map(createConversionEcommerceItem),
        ...availableConversions.kindConversions.map((conversion, index) =>
          createConversionEcommerceItem(conversion, availableConversions.localizationConversions.length + index),
        ),
      ],
      'ulepszanie-kodu',
    )
  }

  const sendAddToCardEvent = (conversion: ConvertableCodeAvailableConversion) => {
    addToCart(conversion.price_brutto, createConversionEcommerceItem(conversion))
  }

  const sendBeginCheckoutEvent = (
    totalPrice: string,
    kindConversion: ConvertableCodeAvailableConversion | null,
    localizationConversion: ConvertableCodeAvailableConversion | null,
  ) => {
    beginCheckout(totalPrice, [
      ...(kindConversion ? [createConversionEcommerceItem(kindConversion)] : []),
      ...(localizationConversion ? [createConversionEcommerceItem(localizationConversion)] : []),
    ])
  }

  return {
    sendViewItemListEvent,
    sendAddToCardEvent,
    sendBeginCheckoutEvent,
  }
}

const createConversionEcommerceItem = (conversion: ConvertableCodeAvailableConversion, index?: number) =>
  createCartItem({
    index,
    name: conversion.option_kind,
    id: conversion.option_kind,
    price: conversion.price_brutto,
    itemListName: 'ulepszanie-kodu',
  })
