import * as React from 'react'
import { RESERVATION_APP_URLS } from '@helpers/consts'
import { OfferDetailsRow } from '@modules/offers/common/details/offer-details-row'
import { OfferDetailsPersonalDataManualBoxPoint } from '@modules/offers/common/details/personal-data/offer-details-personal-data-manual-box-point'

export const SubscriptionWithBenefitManualBox = (): JSX.Element => (
  <OfferDetailsRow
    title={
      <span className="text-primary">
        <strong className="d-block">Jak wykorzystać</strong> Pakiety Korzyści?
      </span>
    }
  >
    <div className="subscription-with-benefit__personal-data__information-box">
      <OfferDetailsPersonalDataManualBoxPoint number={1}>
        <strong className="text-primary">Zapoznaj się z opisem promocji.</strong> Wybierz wariant oferty przygotowanej
        specjalnie dla Ciebie.
      </OfferDetailsPersonalDataManualBoxPoint>
      <OfferDetailsPersonalDataManualBoxPoint number={2}>
        Jesteś naszym stałym klientem? <strong className="text-primary">Zaloguj się! </strong> <br />
        Jesteś tu pierwszy raz? <strong className="text-primary">Uzupełnij swoje dane.</strong>
      </OfferDetailsPersonalDataManualBoxPoint>
      <OfferDetailsPersonalDataManualBoxPoint number={3}>
        <strong className="text-primary">Zaznacz odpowiednie zgody</strong> i potwierdź założenie zamówienia. Kliknij w
        link otrzymanej wiadomości <span className="text-nowrap">e-mail</span>, sprawdź swoje dane i dokonaj płatności.
      </OfferDetailsPersonalDataManualBoxPoint>
      <OfferDetailsPersonalDataManualBoxPoint number={4}>
        Twój pakiet zostanie utworzony. Zapłać, a po zaksięgowaniu wpłaty
        <strong className="text-primary"> otrzymasz potwierdzenie zakupu wraz z listą kodów.</strong>
      </OfferDetailsPersonalDataManualBoxPoint>
      <OfferDetailsPersonalDataManualBoxPoint number={5}>
        <strong className="text-primary">Zarezerwuj pobyty z wykorzystaniem kodów </strong> i ciesz się wymarzonym
        wypoczynkiem!
      </OfferDetailsPersonalDataManualBoxPoint>
      <OfferDetailsPersonalDataManualBoxPoint number={6}>
        <strong className="text-primary">Jak utworzyć rezerwację?</strong> Skorzystaj z systemu{' '}
        <a className="fw-bold text-decoration-none" href={RESERVATION_APP_URLS.MAIN_PAGE} target="_blank">
          rezerwuj.holidaypark.pl
        </a>
      </OfferDetailsPersonalDataManualBoxPoint>
    </div>
  </OfferDetailsRow>
)
