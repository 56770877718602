import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { useAppDispatch, useAppSelector } from '@store/index'
import { selectAccountUrls, setClientDetails } from '@store/slices/app-slice'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { useApiRequest } from '@hooks/use-request'
import { commonObjectGet } from '@requests/basic-requests'
import { CLIENT_APP_URLS } from '@helpers/consts'
import { ClientDetails } from '@models/client'

interface Props {
  client: ClientDetails
}

export const HeaderUserMenu = ({ client }: Props): JSX.Element | null => {
  const urls = useAppSelector(selectAccountUrls)
  const dispatch = useAppDispatch()

  const { action: handleLogout } = useApiRequest(async () => {
    await commonObjectGet(urls.logout)
    dispatch(setClientDetails(null))
  })

  return (
    <UncontrolledDropdown direction="down">
      <DropdownToggle caret className="btn-unstyled bg-white border-0 p-0 p-sm-2" color="secondary">
        <span className="text-darker-gray fw-semi-bold d-sm-inline d-none">
          {client.first_name} {client.last_name}
        </span>
        <i className="uil-user text-darker-gray font-xxxl d-inline d-sm-none" />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem header className="font-xl">
          Witaj {client.first_name}
        </DropdownItem>
        <DropdownItem color="secondary" href={CLIENT_APP_URLS.MAIN_PAGE} tag="a" target="_blank">
          <IconWithText
            text="Twoje konto Gościa"
            iconClassName="uil-house-user font-xl me-2"
            textClassName="fw-semi-bold font-md"
          />
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem onClick={handleLogout}>
          <IconWithText
            text="Wyloguj się"
            iconClassName="uil-signout font-xl me-2"
            textClassName="fw-semi-bold font-md"
          />
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}
