import * as React from 'react'
import { BaseModalProps } from '@modals/types'
import { OfferDescriptionModal } from '@modals/offer-modal/offer-description-modal'

export const SubscriptionWithBenefitDescriptionModal = ({ toggleIsVisible }: BaseModalProps): JSX.Element => (
  <OfferDescriptionModal toggleIsVisible={toggleIsVisible} title="Czym są Pakiety Pobytów?">
    <div className="d-flex flex-sm-row flex-column-reverse ps-sm-2">
      <article className="col-sm-5 font-sm py-3 pe-sm-4">
        <p>Pakiety obejmują pobyty (noclegi) dla 9 osób w domku lub dla 4 osób w apartamencie.</p>
        <p>
          Pakiet zawiera 5 pobytów, a każdy pobyt to aż <span className="text-nowrap">6 dni</span> wypoczynku.
        </p>
        <p>
          Kup Pakiet 5 pobytów, które zrealizujesz przez 5 lat od daty zakupu we wszystkich dostępnych resortach Holiday
          Park & Resort, <span className="text-nowrap">spośród 7 do wyboru.</span>
        </p>
        <p>
          Nie musisz deklarować terminu rezerwacji od razu przy zakupie pakietu. Otrzymasz 5 voucherów (kodów
          promocyjnych), których następnie użyjesz przy zakładaniu rezerwacji.
        </p>
        <strong>6-dniowy pobyt w ramach pakietu może kosztować mniej niż 1 doba w najwyższym sezonie!</strong>
      </article>
      <img
        className="col-sm-7 subscription-with-benefit__description-modal__image m-n3 m-sm-auto"
        src={require('@assets/images/offers/subscription-with-benefits/subscription-with-benefits.webp')}
        alt="Wakacyjny Pakiet Korzyści Holiday Park & Resort"
      />
    </div>
  </OfferDescriptionModal>
)
