import * as React from 'react'
import { TrapezoidButton } from '@components/controls/buttons/trapezoid-button'
import { IconWithText } from '@components/icon-with-text'
import { OfferModalFormInputs } from '@modals/offer-modal/offer-modal'
import { useFormContext } from 'react-hook-form'
import { useFormRequest } from '@hooks/use-request'
import { TextWithSpinner } from '@components/loaders/text-with-spinner'

interface Props {
  onReturn: () => void
  isSubmitEnabled: boolean
  onSubmit: (payload: OfferModalFormInputs) => Promise<void>
}

export const OfferModalPaymentPaymentAction = ({ onReturn, isSubmitEnabled, onSubmit }: Props): JSX.Element => {
  const { handleSubmit, setError } = useFormContext<OfferModalFormInputs>()

  const { action: submit, isLoading } = useFormRequest(async (payload: OfferModalFormInputs) => {
    await onSubmit(payload)
  }, setError)

  return (
    <>
      <IconWithText
        text="Wstecz"
        iconClassName="uil-angle-left font-xxl lh-initial me-1"
        textClassName="fw-semi-bold"
        className="cursor-pointer"
        onClick={onReturn}
      />
      <TrapezoidButton onClick={handleSubmit(submit)} variant="danger" isDisabled={!isSubmitEnabled || isLoading}>
        <TextWithSpinner
          isLoading={isLoading}
          loadingText="Przetwarzanie..."
          text="Płatność"
          iconClassName="uil-shopping-bag font-xxxl me-2 lh-initial"
        />
      </TrapezoidButton>
    </>
  )
}
