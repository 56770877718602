import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { useAppSelector } from '@store/index'
import { selectClientDetails } from '@store/slices/app-slice'
import { OfferDetailsPersonalDataFormBase } from '@modules/offers/common/details/personal-data/offer-details-personal-data-form-base'
import { OfferDetailsPersonalDataNewClient } from '@modules/offers/common/details/personal-data/offer-details-personal-data-new-client'
import { OfferDetailsPersonalDataLogin } from '@modules/offers/common/details/personal-data/offer-details-personal-data-login'
import { Invoice } from '@components/invoice-form/invoice'
import { OfferDetailsPersonalDataExistingUser } from '@modules/offers/common/details/personal-data/offer-details-personal-data-existing-user'

interface Props {
  children: React.ReactNode
  title: React.ReactNode
  agreements: React.ReactNode
}

export const OfferDetailsPersonalData = ({ children, title, agreements }: Props): JSX.Element => {
  const client = useAppSelector(selectClientDetails)
  const { setValue } = useFormContext()

  React.useEffect(() => {
    if (client) {
      setValue('email', client.email)
      setValue('first_name', client.first_name)
      setValue('last_name', client.last_name)
    }
  }, [client])

  return (
    <div className="container-xl">
      {children}
      <OfferDetailsPersonalDataFormBase title={title}>
        <div className="row">
          {client ? (
            <OfferDetailsPersonalDataExistingUser />
          ) : (
            <>
              <OfferDetailsPersonalDataNewClient />
              <OfferDetailsPersonalDataLogin />
            </>
          )}
          <Invoice />
        </div>
        <div className="mt-4">{agreements}</div>
      </OfferDetailsPersonalDataFormBase>
    </div>
  )
}
