import * as React from 'react'
import { endOfDay, isWithinInterval, parseISO, startOfDay } from 'date-fns'
import { GastroCardMultiplier } from '@models/app-state'
import { useFormContext, useWatch } from 'react-hook-form'
import Decimal from 'decimal.js'
import { useGastroCardProduct } from '@modules/offers/gastro-card-boost/details/use-gastro-card-product'
import { useAppSelector } from '@store/index'
import { selectClientDetails } from '@store/slices/app-slice'
import { hasActiveSubscriptionContract } from '@modules/offers/gastro-card-boost/utils'

interface Response {
  currentOffer: GastroCardMultiplier | null
  nextOffer: GastroCardMultiplier | null
  getPercentageBonus: (offer: GastroCardMultiplier) => string
}

export const useGastroDynamicMultipliers = (): Response => {
  const client = useAppSelector(selectClientDetails)
  const hasActiveSubscription = hasActiveSubscriptionContract(client)

  const { offers } = useGastroCardDynamicCurrentOffers()

  const { control } = useFormContext()
  const amount = useWatch({ control, name: 'declared_sell_price' })

  const sortedOffers = [...offers].sort((a, b) => Number(a.minimal_amount) - Number(b.minimal_amount))
  const activeOffers = [...sortedOffers].reverse().filter(offer => new Decimal(amount).gte(offer.minimal_amount))

  const currentOffer = activeOffers[0]
  const nextOffer = sortedOffers.find(offer => new Decimal(offer.minimal_amount).gt(amount))

  const getPercentageBonus = (offer: GastroCardMultiplier): string => {
    return new Decimal(hasActiveSubscription ? offer.multiplier_value_for_subscriber : offer.multiplier_value)
      .minus(1)
      .mul(100)
      .toString()
  }

  return { currentOffer: currentOffer ?? null, nextOffer: nextOffer ?? null, getPercentageBonus }
}

export const useGastroCardDynamicCurrentOffers = (): { offers: GastroCardMultiplier[] } => {
  const gastroCardDynamic = useGastroCardProduct('gastro_card_dynamic')

  const offers = React.useMemo(
    () =>
      (gastroCardDynamic?.multipliers ?? []).filter((multiplier: GastroCardMultiplier) =>
        isWithinInterval(new Date(), {
          start: startOfDay(parseISO(multiplier.active_from)),
          end: endOfDay(parseISO(multiplier.active_to)),
        }),
      ),
    [gastroCardDynamic?.multipliers],
  )

  return {
    offers,
  }
}
