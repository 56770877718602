import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { apiQueryFunction } from './base'
import { extractSelectOptionsValues } from '@helpers/form-utils'
import { BookingOffersResponse } from '@modules/booking-offers/models'
import { RootState } from '@store/index'
import { BookingOffersFiltersParams } from '@modules/booking-offers'
import { createApiTag } from '@api/query-helper'

export const BOOKING_OFFER_API_TAG = 'BOOKING_OFFERS'
export const BOOKING_OFFER_API_TAG_IDS = {
  LIST: 'LIST',
}

export const bookingOffersApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: '' }),
  reducerPath: 'bookingOffersApi',
  tagTypes: [BOOKING_OFFER_API_TAG],
  endpoints: builder => ({
    getBookingOffers: builder.query<BookingOffersResponse, BookingOffersFiltersParams>({
      queryFn: apiQueryFunction((state: RootState, data: BookingOffersFiltersParams) => ({
        params: extractSelectOptionsValues(data),
        method: 'GET',
        url: state.appState.appData.urls.booking_offers.booking_offers,
      })),
      providesTags: [createApiTag(BOOKING_OFFER_API_TAG, BOOKING_OFFER_API_TAG_IDS.LIST)],
    }),
  }),
})

export const { useGetBookingOffersQuery } = bookingOffersApi
