import * as React from 'react'
import { ExpandableProductDescription } from '@components/expandable-product-description'

export const CodesConversionCostsInfo = (): JSX.Element => (
  <section className="font-sm my-4 codes-conversion__expandable-product-description">
    <ExpandableProductDescription isCollapsable>
      <p>
        Usługa ulepszenia kodu jest dostępna m.in. dla produktów z ofert Pakietów Pobytów, Wakacyjnych Pakietów Korzyści
        i Voucherów Hurtowych.
      </p>
      <p>
        Kod może zostać ulepszony do wyższej wersji kolorystycznej, co umożliwia realizację pobytu z jego użyciem w
        szerszym zakresie terminowym. Przykładowo, kod z wersji brązowej można ulepszyć do koloru srebrnego lub złotego.
      </p>
      <p>
        Istnieje również możliwość konwersji kodu poprzez zmianę typu lokalizacji, do którego przypisany jest kod.
        Dzięki temu kody początkowo przeznaczone do lokalizacji tylko nadmorskich lub tylko górskich mogą stać się
        uniwersalne, co pozwala wykorzystać go w dowolnym ośrodku Holiday Park & Resort (w miarę dostępności terminów).
      </p>
      <p className="mb-0">
        Szczegółowe zasady wykorzystania określa Regulamin opcji zmiany terminu wykorzystania vouchera oraz konwersji
        kodów.
      </p>
    </ExpandableProductDescription>
  </section>
)
