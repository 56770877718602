import * as React from 'react'
import { OfferDetailsRow } from '@modules/offers/common/details/offer-details-row'
import { useFormContext, useWatch } from 'react-hook-form'
import { GoHolidayAccommodationVariantCard } from '@modules/offers/go-holiday/details/go-holiday-accommodation-variant-card'
import { Accommodation } from '@modules/offers/subscription-with-benefit/details/variants/card/subscription-with-benefit-variants-card'
import { useGoHolidayProducts } from '@modules/offers/go-holiday/use-go-holiday-products'
import { useGoHolidayGtmEvents } from '@modules/offers/go-holiday/use-go-holiday-gtm-events'

export const GoHolidayAccommodationVariants = (): JSX.Element => {
  const { sendAddToCartEvent } = useGoHolidayGtmEvents()
  const { products } = useGoHolidayProducts()
  const { setValue, control } = useFormContext()

  const [selectedAccommodationType, clientVariant] = useWatch({ control, name: ['accommodation', 'clientVariant'] })

  const handleSelectionChange = (accommodation: Accommodation) => () => {
    setValue('accommodation', selectedAccommodationType === accommodation ? null : accommodation)

    if (selectedAccommodationType !== accommodation) {
      sendAddToCartEvent(products[accommodation][clientVariant][0])
    }
  }

  const isSelected = (accommodation: Accommodation) => selectedAccommodationType === accommodation

  const regularClientPrice = products.house?.regular_client?.length
    ? products.house.regular_client[0].subscription_product.price_brutto
    : '0'

  const newClientPrice = products.house?.new_client?.length
    ? products.house.new_client[0].subscription_product.price_brutto
    : '0'

  return (
    <div className="container-xl mt-5 py-2">
      <OfferDetailsRow>
        <div className="row">
          <GoHolidayAccommodationVariantCard
            kind="house"
            image={require('@assets/images/house-big.webp')}
            isSelected={isSelected('house')}
            isAnotherSelected={selectedAccommodationType === 'apartment'}
            onSelectionChange={handleSelectionChange('house')}
            regularClientPrice={regularClientPrice}
            newClientPrice={newClientPrice}
          />
        </div>
      </OfferDetailsRow>
    </div>
  )
}
