import * as React from 'react'
import { commonObjectPost } from '@requests/basic-requests'
import { useFormContext, useWatch } from 'react-hook-form'
import { BaseFormFields, OfferPaymentBar } from '@modules/offers/common/details/personal-data/offer-payment-bar'
import { useSubscriptionContractPlans } from '@modules/offers/subscription-contract/details/use-subscription-contract-product'
import { SubscriptionContractPaymentWrapper } from '@modules/offers/subscription-contract/details/subscription-contract-payment-wrapper'

export const SubscriptionContractPayment = (): JSX.Element => {
  const plans = useSubscriptionContractPlans()

  const { control, getValues } = useFormContext()

  const selectedPlanKind = useWatch({ control, name: 'planKind' })
  const selectedPlan = plans[selectedPlanKind]

  const paymentAction = async (baseFields: BaseFormFields) => {
    const { subscription_contract_rules, subscription_contract_rules_attachment_1, subscription_contract_price_list } =
      getValues()

    return await commonObjectPost<{ urls: { sale: string } }>(selectedPlan.urls.sale, {
      subscription_contract_rules,
      subscription_contract_rules_attachment_1,
      subscription_contract_price_list,
      plan: selectedPlan,
      ...baseFields,
    })
  }

  return (
    <SubscriptionContractPaymentWrapper selectedPlan={selectedPlan} selectedPlanKind={selectedPlanKind}>
      <OfferPaymentBar
        paymentAction={paymentAction}
        canSubmit={!!selectedPlan}
        totalPrice={plans[selectedPlan]?.single_price_brutto ?? '0'}
        rulesKeywords={[
          'subscription_contract_rules',
          'subscription_contract_rules_attachment_1',
          'subscription_contract_price_list',
        ]}
      />
    </SubscriptionContractPaymentWrapper>
  )
}
