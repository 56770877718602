import * as React from 'react'

export const StatisticsCounts = (): JSX.Element => (
  <div className="pt-4 col-10 mx-auto bg-white">
    <h3 className="text-darker-gray text-center fw-bold font-header opacity-75">Jakość, która mówi sama za siebie</h3>
    <div className="row mt-5 pb-xl-5">
      <SingleCount count="8" text="lat na rynku" />
      <SingleCount count="12 658" text="dostępnych miejsc noclegowych" />
      <SingleCount count="7" text="resortów nad morzem i w górach" />
      <SingleCount
        count="832 045"
        text={
          <span>
            gości odwiedziło nasze resorty od 2016 roku
            <small className="font-3xs d-block">*do 30.11.2023</small>
          </span>
        }
      />
    </div>
  </div>
)

const SingleCount = ({ count, text }) => (
  <div className="d-flex justify-content-center col-xl-3 col-12 flex-column align-items-center mb-3 mb-xl-0">
    <p className="text-primary app-footer__statistics-counter__count mb-1 fw-bold">{count}</p>
    <span className="text-center text-darker-gray col-8 fw-semi-bold">{text}</span>
  </div>
)
