import * as React from 'react'
import Decimal from 'decimal.js'
import { GastroCardMultiplier } from '@models/app-state'
import { useGastroCardDynamicBonusStyle } from '@modules/offers/gastro-card/dynamic/details/modals/offer-description/use-gastro-card-dynamic-bonus-style'

interface Props {
  months: string[]
  gastroCardMultipliers: GastroCardMultiplier[]
}

export const GastroCardDynamicDescriptionMonths = ({ months, gastroCardMultipliers }: Props): React.ReactNode => {
  const lowestMultiplier = gastroCardMultipliers.reduce(
    (prev, multiplier) => (new Decimal(prev).lt(multiplier.multiplier_value) ? prev : multiplier.multiplier_value),
    2,
  )

  const { backgroundColor } = useGastroCardDynamicBonusStyle(lowestMultiplier)

  return months.map(month => (
    <td key={month}>
      <div className="rounded border text-center p-2 fw-semi-bold" style={{ backgroundColor }}>
        {month}
      </div>
    </td>
  ))
}
