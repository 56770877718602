import * as React from 'react'
import { OfferSection } from '@modules/offers/offer-section'
import { formatPrice } from '@helpers/price-helper'
import { Badge } from '@components/badge'

export const UpcomingProductSection = (): JSX.Element => (
  <div className="container-xl upcoming-product">
    <div className="upcoming-product__wrapper">
      <OfferSection
        badges={<Badge variant="gold" text="nowość" className="mb-2 me-3" />}
        title="Subskrypcja"
        description="Opis nadchodzącego produtku"
        onDetailsClick={() => {}}
        onOrderClick={() => {}}
        orderText="Dołącz teraz"
        expirationDate="2022-12-12"
        price={{
          pre: 'tylko',
          price: formatPrice('39.90'),
          after: 'miesięcznie',
          shapePosition: 'bottom',
        }}
        offerKind="subscription-contract"
      />
    </div>
    <div className="upcoming-product__message">
      <p className="text-secondary mb-0">
        Już niebawem kolejny produkt dostępny <span className="text-nowrap">on-line</span>.
      </p>
      <p className="text-darker-gray font-xxxl">Zaglądaj do nas codziennie i upoluj najlepsze okazje!</p>
    </div>
  </div>
)
