export type ConvertableCodeKind = 'subscription' | 'package'
export type ConvertableCodeLocalizationKind = 'sea' | 'mountains' | 'universal'
export type ConvertableCodeSubscriptionKind = 'gold' | 'silver' | 'brown' | 'gray'

export const AvailableConvertableCodeOptionKind = [
  'single_code_kind_convert_to_gold',
  'single_code_kind_convert_to_silver',
  'single_code_kind_convert_to_brown',
] as const

export const AvailableConvertableCodeOptionLocalization = ['localization'] as const

const AllAvailableConvertableCodeOptionKinds = [
  ...AvailableConvertableCodeOptionLocalization,
  ...AvailableConvertableCodeOptionKind,
] as const

export type ConvertableCodeOptionKind = (typeof AllAvailableConvertableCodeOptionKinds)[number]

export interface ConvertableCodeUrls {
  sale: string
  rules: string
}

export interface ConvertableCodeAvailableConversion {
  option_kind: ConvertableCodeOptionKind
  price_brutto: string
}

export interface ConvertableCode {
  available_conversions: ConvertableCodeAvailableConversion[]
  code: string
  expire_after: string
  kind: ConvertableCodeKind
  urls: ConvertableCodeUrls
  localization_kind: ConvertableCodeLocalizationKind
  subscription_kind: ConvertableCodeSubscriptionKind
}

export interface SelectedCodeAvailableConversions {
  kindConversions: ConvertableCodeAvailableConversion[]
  localizationConversions: ConvertableCodeAvailableConversion[]
}
