import * as React from 'react'
import { formatPrice } from '@helpers/price-helper'
import { TextWithSpinner } from '@components/loaders/text-with-spinner'

interface Props {
  onSubmit: () => void
  isSubmitDisabled: boolean
  isSubmitting: boolean
  price: string | number
}

export const PaymentBar = ({ isSubmitDisabled, price, onSubmit, isSubmitting }: Props): JSX.Element => (
  <div className="bg-white py-5">
    <div className="container-xl subscription-with-benefit__payment-bar">
      <div className="text-white">
        <p className="fw-semi-bold font-lg mb-0 ">Łącznie do zapłaty</p>
        <strong className="subscription-with-benefit__payment-bar__price">{formatPrice(price)}</strong>
      </div>
      <button
        className="btn btn-danger px-lg-5 px-2 py-3 d-block col-12 col-sm-auto"
        disabled={isSubmitDisabled}
        onClick={onSubmit}
        type="button"
      >
        <TextWithSpinner
          isLoading={isSubmitting}
          loadingText="Proszę czekać..."
          text={
            <span className="d-flex flex-column align-items-md-end align-items-center">
              <span className="fw-semi-bold">Podsumowanie zamówienia</span>
            </span>
          }
        />
      </button>
    </div>
  </div>
)
