import * as React from 'react'
import { OfferDetailsLayout } from '@layouts/offer-details-layout'
import { IconWithText } from '@components/icon-with-text'
import { OfferDetailsBaseInfo } from '@modules/offers/common/details/offer-details-base-info'
import { FormProvider, useForm } from 'react-hook-form'
import { formatPriceShort } from '@helpers/price-helper'
import { OfferRuleLink } from '@modules/offers/common/details/offer-rule-link'
import { GastroCardCostsInfo } from '@modules/offers/gastro-card/normal/details/gastro-card-costs-info'
import { GastroCardAmount } from '@modules/offers/gastro-card/normal/details/gastro-card-amount'
import { GastroCardPersonalData } from '@modules/offers/gastro-card/normal/details/gastro-card-personal-data'
import { GastroCardPayment } from '@modules/offers/gastro-card/normal/details/gastro-card-payment'
import { GastroCardAgreements } from '@modules/offers/gastro-card/normal/details/gastro-card-agreements'

export type AvailableGastroCardKind = 'gastro_card_2026'

interface FormInputs {
  kind: AvailableGastroCardKind
  declared_sell_price: number
}

export const GastroCardView = (): JSX.Element => {
  const resortGalleryRef = React.useRef<HTMLDivElement>(null)

  const methods = useForm<FormInputs>({
    defaultValues: { declared_sell_price: 1000 },
    mode: 'onChange',
  })

  const handleScrollToResortsGallery = () => {
    resortGalleryRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <OfferDetailsLayout
      questions={FAQ_QUESTIONS}
      ref={resortGalleryRef}
      resortGalleryTitle="Gdzie wykorzystać Vouchery?"
    >
      <FormProvider {...methods}>
        <OfferDetailsBaseInfo
          images={SLIDER_IMAGES}
          benefits={BENEFITS}
          title="Voucher Podwójna Radość 2026/2027"
          description={
            <>
              <p>
                Skorzystaj ze specjalnej promocji na Voucher Podwójna Radość 2026/2027 do wykorzystania w Kawiarni,
                Bistro, Beach Barze i Słodkim Kąciku podczas pobytów w terminie{' '}
                <span className="text-nowrap">1.01.2026 - 31.12.2027</span> w Resortach{' '}
                <span className="text-nowrap">Holiday Park & Resort.</span>
              </p>
              <p>
                Możesz nabyć voucher o wartości powiększonej o 100% wpłaconej kwoty. <br />
                <strong>
                  Wpłacony {formatPriceShort(1000)} to {formatPriceShort(2000)} do wykorzystania!
                </strong>
              </p>
            </>
          }
        >
          <GastroCardCostsInfo />
          <div className="d-flex flex-wrap mt-n2 gap-4">
            <OfferRuleLink keyword="gastro_card_2026_rules" title="Regulamin akcji Podwójna Radość 2026/2027" />

            <IconWithText
              text="Gdzie wykorzystasz swój voucher?"
              iconClassName="uil-map-marker font-xxxl me-2 lh-1"
              textClassName="font-lg lh-1"
              className="text-darker-gray fw-semi-bold mt-3 mt-sm-0"
              onClick={handleScrollToResortsGallery}
            />
          </div>
        </OfferDetailsBaseInfo>
        {/*<GastroCardKindVariants />*/}
        <GastroCardAmount />
        <GastroCardPersonalData agreements={<GastroCardAgreements />} />
        <GastroCardPayment />
      </FormProvider>
    </OfferDetailsLayout>
  )
}

const BENEFITS = [
  'Wartość vouchera zostanie powiększona o 100% Twojej wpłaty',
  'Voucher możesz wykorzystać w otwartych punktach gastronomicznych (z wyłączeniem restauracji) na terenie Resortu.',
  'Ilość voucherów jakie możesz kupić jest nieograniczona',
  'Voucher jest wielokrotnego użytku (częściowo, w różnych rezerwacjach)',
]

const SLIDER_IMAGES = [
  {
    original: require('@assets/images/offers/gastro-card/gallery/cafe.webp'),
    thumbnail: require('@assets/images/offers/gastro-card/gallery/cafe.webp'),
  },
  {
    original: require('@assets/images/offers/gastro-card/gallery/animations.webp'),
    thumbnail: require('@assets/images/offers/gastro-card/gallery/animations.webp'),
  },
  {
    original: require('@assets/images/offers/gastro-card/gallery/dessert.webp'),
    thumbnail: require('@assets/images/offers/gastro-card/gallery/dessert.webp'),
  },
  {
    original: require('@assets/images/offers/gastro-card/gallery/drinks.webp'),
    thumbnail: require('@assets/images/offers/gastro-card/gallery/drinks.webp'),
  },
  {
    original: require('@assets/images/offers/gastro-card/gallery/fruits.webp'),
    thumbnail: require('@assets/images/offers/gastro-card/gallery/fruits.webp'),
  },
  {
    original: require('@assets/images/offers/gastro-card/gallery/bistro.webp'),
    thumbnail: require('@assets/images/offers/gastro-card/gallery/bistro.webp'),
  },
]

const FAQ_QUESTIONS = [
  {
    question: 'W jakiej kwocie mogę nabyć voucher Podwójna Radość 2026/2027?',
    answer: 'Minimalna kwota zakupu to 500,00 zł. Górnej granicy nie ma. :)',
  },
  {
    question: 'Gdzie mogę użyć voucher?',
    answer:
      'Voucher możesz wykorzystać w Kawiarni, Bistro, Beach Barze i Słodkim Kąciku (z wyłączeniem Restauracji) podczas pobytów w Resortach.',
  },
  {
    question: 'Ile mam czasu na wykorzystanie zakupionego vouchera?',
    answer: 'Twój voucher Podwójna Radość 2026/2027 będzie ważny do końca 2027 roku.',
  },
  {
    question: 'Kiedy mogę skorzystać z vouchera?',
    answer: 'Voucher Podwójna Radość 2026/2027 możesz wykorzystać podczas pobytów w terminie 01.01.2026 – 31.12.2027.',
  },
  {
    question: 'Jak zapłacić voucherem w punktach gastronomicznych?',
    answer:
      'Po złożeniu zamówienia w punkcie, zbliż opaskę lub podaj numer swojego pokoju. Zakupy automatycznie zostaną opłacone voucherem (w ramach dostępnych środków).',
  },
  {
    question: 'Czy muszę wykorzystać cały voucher od razu?',
    answer:
      'Nie, niewykorzystana kwota nie przepada. Voucher jest wielokrotnego użytku, możesz go przypisać do kolejnych rezerwacji (w terminie jego ważności).',
  },
  {
    question: 'Jak dodać voucher do rezerwacji?',
    answer:
      'Nic prostszego! Przypisz voucher w swoim Koncie Gościa. Znajdziesz go w kategorii produktów specjalnych. Możesz również to zrobić z pomocą Recepcji Ośrodka, podczas meldowania.',
  },
  {
    question: 'Czy mogę przypisać więcej niż 1 voucher Podwójna Radość do rezerwacji?',
    answer: 'Tak, natomiast kolejny voucher możesz przypisać po całkowitym wykorzystaniu tego poprzedniego.',
  },
]
