import * as React from 'react'
import {
  Accommodation,
  SubscriptionWithBenefitVariant,
} from '@modules/offers/subscription-with-benefit/details/variants/card/subscription-with-benefit-variants-card'
import { Subscription } from '@models/app-state'
import { ClientVariant } from '@modules/offers/subscription-with-benefit/details/variants/subscription-with-benefit-variant-selection'
import { useAppSelector } from '@store/index'
import { selectAppData } from '@store/slices/app-slice'

export const SubscriptionWIthBenefitProductPrefix = 'pakiet__korzysci__'

export interface SubscriptionWithBenefitVariantData {
  house: { new_client: Subscription[]; regular_client: Subscription[] }
  apartment: { new_client: Subscription[]; regular_client: Subscription[] }
}

export type SubscriptionWithBenefitsProducts = {
  [key in SubscriptionWithBenefitVariant]: SubscriptionWithBenefitVariantData
}

interface Response {
  products: SubscriptionWithBenefitsProducts
  getSelectedProduct: (
    offerVariant: SubscriptionWithBenefitVariant | null,
    offerAccommodationType: Accommodation | null,
    clientVariant: ClientVariant,
  ) => Subscription | null
}

export const useSubscriptionWithBenefits = (): Response => {
  const { subscriptions } = useAppSelector(selectAppData)

  const products = React.useMemo(
    () =>
      subscriptions.reduce((products, subscription) => {
        if (subscription.code.startsWith(SubscriptionWIthBenefitProductPrefix)) {
          const [clientVariant, offerVariant, , accommodation] = subscription.code
            .replace(SubscriptionWIthBenefitProductPrefix, '')
            .toLowerCase()
            .split('__')

          return {
            ...products,
            ...(products[offerVariant]
              ? {
                  [offerVariant]: {
                    ...products[offerVariant],
                    ...(products[offerVariant][accommodation]
                      ? {
                          [accommodation]: {
                            ...products[offerVariant][accommodation],
                            ...(products[offerVariant][accommodation][clientVariant]
                              ? {
                                  [clientVariant]: [
                                    ...products[offerVariant][accommodation][clientVariant],
                                    subscription,
                                  ],
                                }
                              : { [clientVariant]: [subscription] }),
                          },
                        }
                      : { [accommodation]: { [clientVariant]: [subscription] } }),
                  },
                }
              : { [offerVariant]: { [accommodation]: { [clientVariant]: [subscription] } } }),
          }
        }

        return products
      }, {}),
    [subscriptions],
  ) as SubscriptionWithBenefitsProducts

  const getSelectedProduct = (
    offerVariant: SubscriptionWithBenefitVariant,
    accommodation: Accommodation | null,
    clientVariant: ClientVariant,
  ) => {
    if (!offerVariant || !accommodation) return null

    return products[offerVariant][accommodation][clientVariant][0]
  }
  return { products, getSelectedProduct }
}
