import * as React from 'react'
import { formatPriceShort } from '@helpers/price-helper'
import { useFormContext, useWatch } from 'react-hook-form'
import { SubscriptionWithBenefitVariant } from '@modules/offers/subscription-with-benefit/details/variants/card/subscription-with-benefit-variants-card'
import { getSubscriptionWithBenefitDiscounts } from '@modules/offers/subscription-with-benefit/details/variants/subscription-with-benefit-discounts-list'
import Decimal from 'decimal.js'
import { subscriptionWithBenefitsUsageYears } from '@modules/offers/subscription-with-benefit/consts'

interface Props {
  variant: SubscriptionWithBenefitVariant
}

export const SubscriptionWithBenefitVariantCardHeader = ({ variant }: Props): JSX.Element => {
  const { control } = useFormContext()

  const accommodationVariant = useWatch({ control, name: 'offerVariantAccommodation' })

  const benefits = getSubscriptionWithBenefitDiscounts(variant, accommodationVariant)

  const amountToSpent = React.useMemo(() => {
    return Object.values(benefits)
      .reduce((sum, curr) => sum.plus(curr), new Decimal(0))
      .toString()
  }, [benefits])

  return (
    <>
      <h3 className="subscription-with-benefit__variant-card__header">{variantTitle[variant]}</h3>
      <strong className="text-center d-block">
        5 pobytów 6-dniowych (5 dób) + <br /> {formatPriceShort(amountToSpent)} na zakupy
      </strong>
      <span className="text-muted font-sm d-block text-center">
        Do wykorzystania do {subscriptionWithBenefitsUsageYears.at(-1)} roku
      </span>
    </>
  )
}

const variantTitle = {
  optimum: 'Pakiet OPTIMUM',
  comfort: 'Pakiet COMFORT',
  lux: 'Pakiet LUX',
}
