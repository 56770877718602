import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { Button } from 'reactstrap'

interface Props {
  onClick?: () => void
  text?: string
}

export const OrderNowButton = ({ text = 'Kup teraz', onClick }: Props): JSX.Element => (
  <Button
    className="order-now-button btn btn-danger rounded py-2 px-4 fw-bold border-0"
    onClick={onClick}
    type="button"
  >
    <IconWithText
      text={text}
      iconClassName="uil-shopping-bag font-xxl me-2 lh-initial z-index-1"
      className="text-white justify-content-center justify-content-sm-start"
      textClassName="font-lg"
    />
  </Button>
)
