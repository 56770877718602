import * as React from 'react'
import { OfferDetailsRow } from '@modules/offers/common/details/offer-details-row'

interface Props {
  children: React.ReactNode
  title: React.ReactNode
}

export const OfferDetailsPersonalDataFormBase = ({ children, title }: Props): JSX.Element => (
  <OfferDetailsRow title={<span className="font-xxxl text-secondary d-block">{title}</span>}>
    {children}
  </OfferDetailsRow>
)
