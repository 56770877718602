import * as React from 'react'
import { useDispatch } from 'react-redux'
import { ModalConfig, ModalParams, ModalsMap, ModalTypes } from '@modals/types'
import { setHideModal, setModalIsOpen } from '@store/slices/modal-slice'
import { Modal } from 'reactstrap'
import * as clsx from 'clsx'

interface ModalComponentRowProps<T> {
  modal: ModalTypes
  params: ModalParams<T>
  config?: ModalConfig
  isOpen: boolean
}

export const ModalComponentRow = <T,>({
  modal,
  isOpen,
  params,
  config,
}: ModalComponentRowProps<T>): React.ReactNode => {
  const dispatch = useDispatch()

  const [ModalContent, options] = ModalsMap[modal]

  const onClose = React.useCallback(() => {
    config?.onClose?.()
    return dispatch(setHideModal({ modal }))
  }, [modal, config])
  const toggleIsOpen = React.useCallback(() => dispatch(setModalIsOpen({ modal, isOpen: !isOpen })), [modal, isOpen])

  return (
    <Modal
      key={modal}
      isOpen={isOpen}
      toggle={toggleIsOpen}
      onClosed={onClose}
      role="modal"
      {...options}
      className={clsx('custom-modal', options.className)}
    >
      <ModalContent {...params} toggleIsVisible={toggleIsOpen} />
    </Modal>
  )
}
