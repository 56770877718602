import * as React from 'react'
import { SubscriptionWithBenefitOffer } from '@modules/offers/subscription-with-benefit/subscription-with-benefit-offer'
import { SubscriptionContractOffer } from '@modules/offers/subscription-contract'
import { CodesConversionOffer } from '@modules/offers/codes-conversion'
import { GoHolidayOffer } from '@modules/offers/go-holiday'
import { BookingOffers } from '@modules/booking-offers'
import { GastroCardDynamicOffer } from '@modules/offers/gastro-card/dynamic'
import { GastroCardOffer } from './gastro-card/normal'

export const OffersSection = (): JSX.Element => (
  <>
    <SubscriptionWithBenefitOffer />
    <GastroCardOffer />
    <GastroCardDynamicOffer />
    <CodesConversionOffer />
    <GoHolidayOffer />
    <SubscriptionContractOffer />
    <BookingOffers />
  </>
)
