import * as React from 'react'
import { useLocation } from 'react-router-dom'

interface Response {
  ref: React.RefObject<HTMLDivElement>
}

export const useScrollToOffer = (sectionId: string): Response => {
  const location = useLocation()

  const ref = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (location.hash.includes(sectionId)) ref.current?.scrollIntoView({ behavior: 'smooth' })
  }, [location.hash, sectionId])

  return {
    ref,
  }
}
