import * as React from 'react'
import { RESERVATION_APP_URLS } from '@helpers/consts'
import { OfferDetailsRow } from '@modules/offers/common/details/offer-details-row'
import { OfferDetailsPersonalDataManualBoxPoint } from '@modules/offers/common/details/personal-data/offer-details-personal-data-manual-box-point'

export const CodesConversionManualBox = (): JSX.Element => (
  <OfferDetailsRow
    title={
      <span className="text-primary">
        <strong>Co</strong> musisz
        <strong className="d-block">zrobić?</strong>
      </span>
    }
  >
    <div className="subscription-with-benefit__personal-data__information-box codes-conversion__personal-data__information-box">
      <OfferDetailsPersonalDataManualBoxPoint number={1}>
        Jesteś naszym stałym klientem? <strong className="text-primary">Zaloguj się!</strong> <br />
        Jesteś tu pierwszy raz? <strong className="text-primary">Uzupełnij swoje dane.</strong>
      </OfferDetailsPersonalDataManualBoxPoint>
      <OfferDetailsPersonalDataManualBoxPoint number={2}>
        Zapoznaj się z opisem promocji.
        <strong className="text-primary"> Wpisz posiadany kod</strong> lub wybierz go z listy, jeżeli jesteś zalogowany.
      </OfferDetailsPersonalDataManualBoxPoint>
      <OfferDetailsPersonalDataManualBoxPoint number={3}>
        Wybierz wariant oferty.
        <strong className="text-primary"> Zaznacz odpowiednie zgody </strong>i potwierdź złożenie zamówienia.
      </OfferDetailsPersonalDataManualBoxPoint>
      <OfferDetailsPersonalDataManualBoxPoint number={4}>
        Zapłać, a po zaksięgowaniu wpłaty
        <strong className="text-primary"> otrzymasz potwierdzenie zakupu.</strong>
      </OfferDetailsPersonalDataManualBoxPoint>
      <OfferDetailsPersonalDataManualBoxPoint number={5}>
        <strong className="text-primary">Zarezerwuj pobyt z wykorzystaniem kodu</strong> i ciesz się wymarzonym
        wypoczynkiem!
      </OfferDetailsPersonalDataManualBoxPoint>
      <OfferDetailsPersonalDataManualBoxPoint number={6}>
        <strong className="text-primary">Jak utworzyć rezerwację?</strong> Skorzystaj z systemu{' '}
        <a className="fw-bold text-decoration-none" href={RESERVATION_APP_URLS.MAIN_PAGE} target="_blank">
          rezerwuj.holidaypark.pl
        </a>
      </OfferDetailsPersonalDataManualBoxPoint>
    </div>
  </OfferDetailsRow>
)
