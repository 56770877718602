import { EcommerceEventData, EcommerceEventItem, GTMEvents, ViewItemListEventData } from '@models/gtm'
import { useGtm } from '@hooks/use-gtm'
import { useAppSelector } from '@store/index'
import { selectClientDetails } from '@store/slices/app-slice'

const CommonFields = { item_brand: 'Holiday Park & Resort', item_category: 'e-sklep' }

type CartItem = Omit<EcommerceEventItem, 'item_brand'>
type ListItemName = 'doladowanie-smaku' | 'pakiet-korzysci' | 'ulepszanie-kodu' | 'subskrypcja' | 'go-holiday'

interface Response {
  addToCart: (value: string | number, item: CartItem) => void
  beginCheckout: (price: string, items: CartItem[]) => void
  viewItemList: (items: Partial<EcommerceEventItem>[], itemsListItem: ListItemName) => void
}

export const useGtmEvents = (): Response => {
  const { sendEvent } = useGtm()
  const clientDetails = useAppSelector(selectClientDetails)

  const sendEventWithEcommerceCleanup = (...params: Parameters<typeof sendEvent>) => {
    sendEvent({ ecommerce: null })
    return sendEvent(...params)
  }

  const createBaseEventData = <T = unknown>(ecommerceData: Partial<EcommerceEventData> & T) => ({
    userID: clientDetails?.id ?? '',
    ecommerce: {
      ...ecommerceData,
    },
  })

  const addToCart = (value: string, item: CartItem) => {
    sendEventWithEcommerceCleanup({
      event: GTMEvents.AddToCart,
      ...createBaseEventData({ currency: 'PLN', value, items: [{ ...CommonFields, ...item }] }),
    })
  }

  const beginCheckout = (price: string, items: CartItem[]) => {
    sendEventWithEcommerceCleanup({
      event: GTMEvents.BeginCheckout,
      ...createBaseEventData({
        value: price,
        step: 1,
        items: items,
      }),
    })
  }

  const viewItemList = (items: Partial<EcommerceEventItem>[], itemListName: ListItemName) => {
    sendEventWithEcommerceCleanup({
      event: GTMEvents.ViewItemList,
      ...createBaseEventData<ViewItemListEventData>({
        item_list_name: itemListName,
        items,
      }),
    })
  }

  return {
    addToCart,
    beginCheckout,
    viewItemList,
  }
}

interface CartItemPayload {
  id: string | number
  price: string
  itemListName: ListItemName
  name: string
  index?: number
}

export const createCartItem = (payload: CartItemPayload): CartItem => ({
  price: payload.price,
  item_id: payload.id,
  item_name: payload.name,
  index: payload.index ?? 0,
  item_list_name: payload.itemListName,
  quantity: 1,
  ...CommonFields,
})
