import * as React from 'react'
import { OfferDetailsRow } from '@modules/offers/common/details/offer-details-row'
import { SubscriptionContractOfferSelectionPlanCard } from '@modules/offers/subscription-contract/details/subscription-contract-offer-selection-plan-card'
import { useSubscriptionContractPlans } from '@modules/offers/subscription-contract/details/use-subscription-contract-product'
import { useFormContext, useWatch } from 'react-hook-form'
import { SubscriptionContractPlanKind } from '@models/app-state'

export const SubscriptionContractOfferSelection = (): JSX.Element => {
  const plans = useSubscriptionContractPlans()

  const { control } = useFormContext()

  const selectedAccommodation = useWatch({ control, name: 'accommodation' })

  const plansForSelectedAccommodation = Object.entries(plans).filter(([, plan]) => {
    const { hasHouse, hasApartment } = plan.accommodation_types.reduce(
      (acc, curr) => ({
        hasHouse: acc.hasHouse || curr.type === 'house',
        hasApartment: acc.hasApartment || curr.type === 'apartment',
      }),
      { hasHouse: false, hasApartment: false },
    )

    if (hasHouse && hasApartment) return selectedAccommodation === 'house'

    return plan.accommodation_types.find(accommodationType => {
      return accommodationType.type === selectedAccommodation
    })
  })

  return (
    <div className="container-xl">
      <OfferDetailsRow
        title={
          <span className="text-secondary">
            Wybierz swój <br />
            <strong>plan subskrypcji</strong>
          </span>
        }
      >
        <div className="row mt-4 mt-lg-0">
          {plansForSelectedAccommodation.map(([planKind, plan]) => (
            <SubscriptionContractOfferSelectionPlanCard
              isRecommended={['plan_3', 'plan_7'].includes(planKind)}
              key={planKind}
              planKind={planKind as SubscriptionContractPlanKind}
              plan={plan}
            />
          ))}
        </div>
        <div className="mt-3 font-xxs">
          <ul className="ps-0 mb-0">
            <li>
              <span className="subscription-contract__markings">*</span> Z wyłączeniem Terminów Specjalnych
            </li>
            <li>
              <span className="subscription-contract__markings">**</span> Rabat liczony z voucherem wyjazdowym
            </li>
            <li>
              <span className="subscription-contract__markings">***</span> Możliwość rezerwacji pobytu pakietowego w
              Terminach Specjalnych z wykorzystaniem mniejszej ilości kodów
            </li>
          </ul>
        </div>
      </OfferDetailsRow>
    </div>
  )
}
