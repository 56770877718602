import * as React from 'react'
import { OrderNowButton } from '@components/controls/buttons/order-now-button'
import { ToggleButton, ToggleButtonSelectionSide } from '@components/controls/buttons/toggle-button'
import { IconWithText } from '@components/icon-with-text'
import { formatPriceShort } from '@helpers/price-helper'
import * as clsx from 'clsx'
import { useDidUpdateEffect } from '@hooks/use-did-update-effect'
import { Link } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { useSubscriptionWithBenefits } from '@modules/offers/subscription-with-benefit/details/use-subscription-with-benefits'

export const SubscriptionWithBenefitOffer = (): JSX.Element => {
  const [isAnimating, setIsAnimating] = React.useState(false)
  const [activeSide, setActiveSide] = React.useState<ToggleButtonSelectionSide>('left')

  const { products: subscriptionWithBenefits } = useSubscriptionWithBenefits()

  const minimumHousePrice =
    subscriptionWithBenefits.optimum?.house.regular_client?.[0].subscription_product?.price_brutto ?? 0
  const minimumApartmentPrice =
    subscriptionWithBenefits.optimum?.apartment.regular_client?.[0].subscription_product?.price_brutto ?? 0

  useDidUpdateEffect(() => {
    setIsAnimating(true)
    setTimeout(() => setIsAnimating(false), 500)
  }, [activeSide])

  const isApartmentAccommodationSelected = activeSide === 'right'

  const handleToggle = (side: ToggleButtonSelectionSide) => {
    if (isAnimating) return
    setActiveSide(side)
  }

  return (
    <div className="subscription-with-benefit container-xl">
      <video className="subscription-with-benefit__video" autoPlay playsInline muted loop controlsList="nofullscreen">
        <source src={require('@assets/images/holiday-video.mp4')} />
      </video>

      <div className="subscription-with-benefit__content">
        <h2 className="text-white py-4 fw-bold text-center text-sm-start font-header-lg">
          <span className="d-block">Wakacyjny </span>Pakiet Korzyści
        </h2>
        <ul className="ps-0 mt-1">
          {getItems(isApartmentAccommodationSelected).map((item, index) => (
            <ListItem key={index}>{item}</ListItem>
          ))}
        </ul>
        <div className="ms-xl-auto d-inline-block text-white mt-4 col-12 col-sm-5 col-lg-3">
          <label className="fw-semi-bold mb-2">Wybierz wariant zakwaterowania:</label>
          <ToggleButton
            disabled={isAnimating}
            left="domek"
            right="apartament"
            onSideChange={handleToggle}
            toggleInterval={5000}
          />
        </div>
        <div className="fw-semi-bold mt-5 subscription-with-benefit__price-wrapper__mobile">
          <div>
            Już od:
            <strong className="font-xxxl ms-4">
              {formatPriceShort(isApartmentAccommodationSelected ? minimumApartmentPrice : minimumHousePrice)}
            </strong>{' '}
          </div>
          za pakiet 5 pobytów 6 dniowych dla {isApartmentAccommodationSelected ? 4 : 9} osób
        </div>

        <div className="mt-5 mt-sm-4">
          <Link to={NavigationPath.SubscriptionWithBenefits}>
            <OrderNowButton text="Zobacz ofertę" />
          </Link>
        </div>
      </div>
      <div className="subscription-with-benefit__price-wrapper">
        <div className={clsx('text-white fw-semi-bold offer-slide__price__container', { animate: isAnimating })}>
          <strong className="d-block font-header-lg lh-initial text-end offer-slide__price__amount">
            {formatPriceShort(isApartmentAccommodationSelected ? minimumApartmentPrice : minimumHousePrice)}
          </strong>
          <span className="d-block text-end ">za pakiet 5 pobytów</span>
          <span className="d-block text-end">6 dniowych</span>
          <span className="d-block text-end">dla {isApartmentAccommodationSelected ? 4 : 9} osób</span>
          <span className="d-block text-end">w {isApartmentAccommodationSelected ? 'apartamencie' : 'domku'}</span>
        </div>
      </div>
    </div>
  )
}

const ListItem = ({ children }) => (
  <li className="font-xl lh-initial">
    <IconWithText iconClassName="uil-check font-header me-1" text={children} />
  </li>
)

const getItems = (isApartmentAccommodationSelected: boolean) => [
  <>
    <strong>5 pobytów 6-dniowych</strong> {isApartmentAccommodationSelected ? 'w apartamencie' : 'w domku'}
  </>,
  <>
    <strong>{formatPriceShort(isApartmentAccommodationSelected ? 4000 : 5000)}</strong> do wykorzystania na napoje i
    jedzenie
  </>,
  <>
    <strong>specjalna pula lokali</strong> na wyłączność
  </>,
]
