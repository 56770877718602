import * as React from 'react'
import { Button, Progress } from 'reactstrap'
import { BookingOffersCard } from '@modules/booking-offers/cards/booking-offers-card'
import { BookingOffersResponse } from '@modules/booking-offers/models'
import { BookingOffersNotFound } from '@modules/booking-offers/booking-offers-not-found'
import { useFormContext, useWatch } from 'react-hook-form'
import { BookingOffersFiltersParams } from '@modules/booking-offers'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  offerResponse: BookingOffersResponse
  isFiltered: boolean
}

export const BookingOfferCards = ({ offerResponse, isFiltered }: Props): JSX.Element => {
  const { control, setValue } = useFormContext<BookingOffersFiltersParams>()

  const pageSize = useWatch({ control, name: 'page_size' })
  const nextOffersAmount = Math.min(offerResponse.count - pageSize, 12)

  const showedOffersCount = Math.min(offerResponse.count, pageSize)

  const handleShowMoreOffer = () => {
    setValue('page_size', pageSize + nextOffersAmount)
  }

  const progress = (pageSize * 100) / offerResponse.count

  return (
    <div>
      <div className="row mt-md-5 g-4">
        {offerResponse.results.map(offer => (
          <BookingOffersCard key={offer.id} offer={offer} />
        ))}
      </div>

      {isFiltered && !offerResponse.results.length && <BookingOffersNotFound aggregation={offerResponse.aggregation} />}

      {!!offerResponse.results.length && (
        <div className="col-sm-4 col-10 mx-auto mt-5">
          <p className="fw-semi-bold text-center">{`${showedOffersCount} z ${offerResponse.count} ofert`}</p>
          <Progress value={progress} style={{ height: 3 }} />
        </div>
      )}

      {offerResponse.next && (
        <Button color="light" outline className="border mt-3 mx-auto d-block px-5" onClick={handleShowMoreOffer}>
          <IconWithText
            text="Pokaż więcej"
            textClassName="fw-semi-bold"
            iconClassName="uil-plus font-xl me-2"
            className="text-darker-gray"
          />
        </Button>
      )}
    </div>
  )
}
