import * as React from 'react'
import { useGastroDynamicMultipliers } from '@modules/offers/gastro-card/dynamic/details/use-gastro-dynamic-multipliers'
import { GastroCardDynamicOfferExpirationTime } from '@modules/offers/gastro-card/dynamic/details/gastro-card-dynamic-offer-expiration-time'

export const GastroCardDynamicPaypoBanner = (): JSX.Element => {
  const { currentOffer } = useGastroDynamicMultipliers()

  return (
    <div className="container">
      <div className="gastro-card-dynamic__deferred-payment-banner col-lg-9 ms-auto ">
        <div>
          <strong className="d-block font-xxl text-dark">Kup już dziś, zapłać za 30 dni:</strong>
          <div className="d-flex flex-md-row flex-column align-items-center text-dark my-3 my-md-0">
            <span className="font-lg">
              Śpiesz się,{' '}
              {currentOffer ? 'wartość dopłaty zmniejszy się za:' : 'wartość dopłaty wkrótce się zmniejszy!'}
            </span>
            {currentOffer && (
              <GastroCardDynamicOfferExpirationTime
                gastroProductMultiplier={currentOffer}
                spinnerClassName="text-dark"
              />
            )}
          </div>
        </div>
        <img src={require('@assets/images/paypo-logo.svg')} alt="PayPo" height={30} />
      </div>
    </div>
  )
}
