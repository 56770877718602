import * as React from 'react'
import * as clsx from 'clsx'
import { OfferSelectionButton } from '@modules/offers/common/details/offer-selection-button'
import { useFormContext, useWatch } from 'react-hook-form'
import { formatPriceShort } from '@helpers/price-helper'
import { IconWithText } from '@components/icon-with-text'
import {
  SubscriptionContractPlan,
  SubscriptionContractPlanKind,
  SubscriptionContractPlanKindColor,
} from '@models/app-state'
import { useSubscriptionContractsGtmEvents } from '@modules/offers/subscription-contract/details/use-subscription-contract-gtm-events'

export const SubscriptionContractPlanColors: Record<
  SubscriptionContractPlanKind,
  { color: SubscriptionContractPlanKindColor; name: string; date: string }
> = {
  plan_1: { color: 'brown', name: 'brąz', date: '01.10 - 31.05*' },
  plan_2: { color: 'silver', name: 'srebro', date: '01.09 - 30.06*' },
  plan_3: { color: 'gold', name: 'złoto', date: '26.08 - 07.07*' },
  plan_4: { color: 'platinum', name: 'platyna', date: 'przez cały rok' },
  plan_5: { color: 'brown', name: 'brąz', date: '01.10 - 31.05*' },
  plan_6: { color: 'silver', name: 'srebro', date: '01.09 - 30.06*' },
  plan_7: { color: 'gold', name: 'złoto', date: '26.08 - 07.07*' },
  plan_8: { color: 'platinum', name: 'platyna', date: 'przez cały rok' },
}

interface Props {
  isRecommended?: boolean
  plan: SubscriptionContractPlan
  planKind: SubscriptionContractPlanKind
}

export const SubscriptionContractOfferSelectionPlanCard = ({ plan, planKind, isRecommended }: Props): JSX.Element => {
  const { control, setValue } = useFormContext()
  const { sendAddToCartEvent } = useSubscriptionContractsGtmEvents()

  const [selectedPlanLKind, selectedAccommodation] = useWatch({ control, name: ['planKind', 'accommodation'] })

  const isSelected = selectedPlanLKind === planKind
  const isAnotherSelected = selectedPlanLKind !== null && selectedPlanLKind !== planKind

  const toggleIsSelected = () => {
    setValue('planKind', planKind)
    sendAddToCartEvent(plan, planKind)
  }

  const accommodationTypeDisplay = selectedAccommodation === 'apartment' ? 'apartamencie' : 'domku'

  const planData = SubscriptionContractPlanColors[planKind]

  return (
    <div className="col-12 col-sm-6 col-md-3 mt-5 mt-md-0 position-relative">
      {isRecommended && (
        <div className={clsx('subscription-contract__recommended-label', { 'is-selected': isSelected })}>
          Polecany dla Ciebie
        </div>
      )}
      <div
        className={clsx(
          `subscription-contract__wrapper ps-4 pt-4 is-${planData.color}`,
          { 'is-selected': isSelected },
          { 'is-recommended': isRecommended },
          { 'is-another-card-selected': isAnotherSelected },
        )}
      >
        <div className="text-white align-self-start pe-4">
          <div className="text-center text-sm-start">
            <span className="font-xxl lh-1 fw-semi-bold d-lg-block">Plan subskrypcji </span>
            <strong className="font-xxxl">{planData.name}</strong>
          </div>
          <div className="mt-5">
            <IconWithText
              text={
                <span>
                  Kod na 5-dniowy pobyt w {accommodationTypeDisplay} w okresie
                  <div>
                    <div className="subscription-contract__date">{planData.date}</div>
                  </div>
                </span>
              }
              iconClassName="uil-check font-lg lh-1 me-2 text-primary subscription-contract__benefit-icon-wrapper align-self-start mt-1"
              className="text-white fw-semi-bold font-sm mb-1"
            />

            <IconWithText
              text="Do -65% na pobyt**"
              iconClassName="uil-check font-lg lh-1 me-2 text-primary subscription-contract__benefit-icon-wrapper"
              className="text-white fw-semi-bold font-sm mb-1"
            />

            <IconWithText
              text="Do -25% na wyżywienie**"
              iconClassName="uil-check font-lg lh-1 me-2 text-primary subscription-contract__benefit-icon-wrapper"
              className="text-white fw-semi-bold font-sm mb-1"
            />

            <IconWithText
              text="Dostęp do Terminów Specjalnych ***"
              iconClassName="uil-check font-lg lh-1 me-2 text-primary subscription-contract__benefit-icon-wrapper"
              className="text-white fw-semi-bold font-sm mb-1"
            />
          </div>
          <img
            className="subscription-contract__icon"
            src={require('@assets/images/icons/sygnet.svg')}
            alt="Holiday Park & Resort"
          />
        </div>
        <div className="d-flex flex-sm-column justify-content-between align-items-end">
          <div className="mb-sm-4 mb-3 mt-5 d-flex flex-column align-items-end text-white me-5">
            <span className="fw-semi-bold font-xl lh-1 mb-2">Opłata miesięczna</span>
            <strong className="d-block font-xxxl align-self-start align-self-sm-end">
              {formatPriceShort(plan.single_price_brutto)}
            </strong>
          </div>
          <OfferSelectionButton
            text="Wybieram"
            activeText="Wybrany"
            isSelected={isSelected}
            onSelectionChange={toggleIsSelected}
          />
        </div>
      </div>
    </div>
  )
}
