import * as React from 'react'
import { ExpandableProductDescription } from '@components/expandable-product-description'

export const GastroCardDynamicCostsInfo = (): JSX.Element => (
  <section className="font-sm my-4">
    <ExpandableProductDescription isCollapsable>
      <p>Minimalna kwota, za jaką możesz kupić voucher to 500,00 zł</p>
      <p>
        O wartości uzyskanego bonusu decyduje moment i wartość wpłaty. Przykładowo:
        <ul className="list-unstyled">
          <li className="my-1">
            Nabycie vouchera we wrześniu 2024 za kwotę 1 000,00 zł zwiększa wartość Twojego vouchera aż do{' '}
            <span className="text-nowrap">1 700,00 zł (+70% bonusu)!</span>
          </li>
          <li>Zakup vouchera w październiku 2024 w kwocie 500,00 zł zwiększa jego wartość do 775,00 zł (+ 55%).</li>
        </ul>
        <strong>Posiadacze aktywnej subskrypcji otrzymują zawsze dodatkowe +10%.</strong>
      </p>
      <p>
        Z vouchera możesz korzystać wiele razy, przypisać go do wielu rezerwacji i przy każdej wykorzystać tylko jego
        część aż do momentu całkowitego wykorzystania.
      </p>
      <p>
        Voucher nie łączy się z kuponem Doładowanie Smaku i Strzał w dziesiątkę. Nie można go wykorzystać w rezerwacji,
        do której przypisany jest jakikolwiek voucher gastronomiczny. Po zużyciu środków z vouchera Podwójna Radość
        można podpiąć kolejny dowolny voucher gastronomiczny.
      </p>

      <p className="mb-0">Szczegółowe zasady wykorzystania określa Regulamin oferty.</p>
    </ExpandableProductDescription>
  </section>
)
