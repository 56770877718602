import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'

export const AppDownloadBanner = (): JSX.Element => (
  <div className="app-footer__download-banner">
    <div className="col-xl-4 col-12 text-white text-center text-xl-start">
      <strong className="font-xxxl">Pobierz aplikację mobilną</strong>
      <br />
      <span className="font-xxxl">i monitoruj swoje wakacje</span>
      <IconWithText
        text="Dostęp do pełnej historii rezerwacji"
        iconClassName="uil-check me-2 font-xxxl lh-initial"
        textClassName="font-xl text-start"
        className="mt-4 px-4 px-xl-0"
      />
      <IconWithText
        text="Meldowanie i wymeldowanie on-line"
        iconClassName="uil-check me-2 font-xxxl lh-initial"
        textClassName="font-xl mt-1 text-start"
        className="px-4 px-xl-0"
      />
      <IconWithText
        text="Kontrola rachunku podczas pobytu"
        iconClassName="uil-check me-2 font-xxxl lh-initial"
        textClassName="font-xl mt-1 text-start"
        className="px-4 px-xl-0"
      />
      <div className="mt-5">
        <a href="https://holidaypark.pl/aplikacja-mobilna/app-store/">
          <img
            src={require('@assets/images/icons/appstore.svg')}
            alt="Holiday Park & Resort Konto Gościa app store"
            height="40"
            className="me-4"
          />
        </a>
        <a href="https://holidaypark.pl/aplikacja-mobilna/google-play/">
          <img
            src={require('@assets/images/icons/google-play.svg')}
            alt="Holiday Park & Resort Konto Gościa google play"
            height="40"
          />
        </a>
      </div>
    </div>
    <div>
      <img
        className="app-footer__download-banner__image"
        src={require('@assets/images/app-bg.webp')}
        alt="Holiday Park & Resort Konto Gościa"
      />
    </div>
  </div>
)
