import * as React from 'react'
import { useApiRequest } from '@hooks/use-request'
import { commonObjectGet } from '@requests/basic-requests'
import { Rule } from '@models/app-state'
import { DynamicAgreementsSection } from '@components/dynamic-agreements-section'
import { useSelector } from 'react-redux'
import { selectClientDetails } from '@store/slices/app-slice'

interface Props {
  rulesUrl: string
}

export const OfferDetailsAgreements = ({ rulesUrl }: Props): JSX.Element => {
  const client = useSelector(selectClientDetails)
  const [rules, setRules] = React.useState<Rule[]>([])

  const { action: fetchProductRules } = useApiRequest(async () => {
    if (rulesUrl) {
      setRules(await commonObjectGet(rulesUrl))
    }
  })

  React.useEffect(() => {
    fetchProductRules()
  }, [rulesUrl, client])

  return <DynamicAgreementsSection rules={rules} />
}
