import * as React from 'react'
import { BaseModalProps } from '@modals/types'
import { ModalBody, ModalHeader } from 'reactstrap'

interface Props extends BaseModalProps {
  title: string
  children: React.ReactNode
  modalBodyClassName?: string
}

export const OfferDescriptionModal = ({ toggleIsVisible, title, children, modalBodyClassName }: Props): JSX.Element => (
  <>
    <ModalHeader className="text-primary ps-4">
      <strong>{title}</strong>
      <button className="modal-close-button" onClick={toggleIsVisible}>
        <i className="uil-times text-white font-xxxl" />
      </button>
    </ModalHeader>
    <ModalBody className={modalBodyClassName}>{children}</ModalBody>
  </>
)
