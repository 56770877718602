import * as React from 'react'
import * as clsx from 'clsx'
import { formatPriceShort } from '@helpers/price-helper'
import { CustomUncontrolledTooltip } from '@components/custom-uncontrolled-tooltip'
import { IconWithText } from '@components/icon-with-text'
import { OfferSelectionButton } from '@modules/offers/common/details/offer-selection-button'
import { useAppSelector } from '@store/index'
import { selectClientDetails } from '@store/slices/app-slice'
import { useModal } from '@modals/use-modal'

interface Props {
  regularClientPrice: string
  newClientPrice: string
  isSelected: boolean
  onSelectionChange: () => void
  tooltipTarget: string
}

export const ClientVariantFooter = ({
  regularClientPrice,
  newClientPrice,
  isSelected,
  onSelectionChange,
  tooltipTarget,
}: Props): JSX.Element => {
  const clientDetails = useAppSelector(selectClientDetails)
  const [showLoginModal] = useModal('LoginModal')

  const handleLogin = (event: React.MouseEvent) => {
    event.stopPropagation()
    showLoginModal()
  }

  return (
    <div className="subscription-with-benefit__variant-card__footer pt-3 ps-4 z-1">
      <span className="font-sm fw-semi-bold">
        Cena dla {clientDetails ? 'nowych' : 'stałych'} Klientów:{' '}
        <span className={clsx({ 'price-crossed': clientDetails })}>
          {formatPriceShort(clientDetails ? newClientPrice : regularClientPrice)}
        </span>
      </span>
      {!clientDetails && (
        <CustomUncontrolledTooltip
          target={tooltipTarget}
          tooltipContent={
            <div className="p-3">
              <strong>Stałym Klientem</strong> jest osoba, która korzystała już z naszych usług związanych z pobytem w
              Resorcie i posiada Konto Gościa
            </div>
          }
        >
          <IconWithText
            text="Zaloguj się, jeżeli jesteś stałym Klientem"
            className="text-primary subscription-with-benefit__variant-card__footer__login"
            iconClassName="uil-info-circle font-xl me-1"
            textClassName="font-sm fw-semi-bold"
            onClick={handleLogin}
            id={tooltipTarget}
          />
        </CustomUncontrolledTooltip>
      )}
      <div className="d-flex align-items-center flex-wrap mt-3 fw-semi-bold">
        Cena dla {clientDetails ? 'stałych' : 'nowych'} Klientów:
      </div>
      <div className="d-flex align-items-center justify-content-between flex-wrap">
        <div>
          <div className="font-xxxl text-primary fw-bold subscription-with-benefit__variant-card__footer__price">
            {formatPriceShort(clientDetails ? regularClientPrice : newClientPrice)}
          </div>
        </div>
        <OfferSelectionButton isSelected={isSelected} onSelectionChange={onSelectionChange} className="ms-auto" />
      </div>
    </div>
  )
}
