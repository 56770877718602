import * as React from 'react'
import { InputHTMLAttributes } from 'react'
import * as clsx from 'clsx'
import { PriceRegex } from '@helpers/price-helper'

interface Props extends Partial<Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>> {
  onIncrement?: (newValue: number) => void
  onDecrement?: (newValue: number) => void
  onChange?: (newValue: number) => void
}

const inMaxRange = (newValue, props) => props.max === undefined || newValue <= props.max
const inMinRange = (newValue, props) => props.min === undefined || newValue >= props.min
const getIntValue = (val: string | number) => {
  if (typeof val === 'number') return val
  return parseFloat(val)
}

export const SpinInput = ({ onIncrement, onDecrement, onChange, ...props }: Props): JSX.Element => {
  const step = props.step ? getIntValue(props.step) : 1

  const [value, setValue] = React.useState<number | string>(0)

  const handleValueChange = (newValue: number) => {
    setValue(newValue)
    onChange?.(newValue)
  }

  React.useEffect(() => {
    if (typeof props.value === 'number') {
      handleValueChange(props.value)
    }
  }, [props.value])

  const handleIncrement = () => {
    const newValue = getIntValue(value) + step
    if (!inMaxRange(newValue, props)) return
    if (onIncrement) {
      onIncrement(newValue)
      return
    }

    handleValueChange(newValue)
  }

  const handleDecrement = () => {
    const newValue = getIntValue(value) - step
    if (!inMinRange(newValue, props)) return
    if (onDecrement) {
      onDecrement(newValue)
      return
    }

    handleValueChange(newValue)
  }

  const handleChange = e => {
    const val = e.target.value
    if (val === '') {
      setValue('')
      return
    }

    if (!PriceRegex.test(val)) return

    const newValue = parseFloat(val)

    if (!inMinRange(newValue, props) || !inMaxRange(newValue, props)) return
    handleValueChange(newValue)
  }

  return (
    <div className="spin-input__container">
      <button
        type="button"
        data-testid="spinbutton-decrement"
        className={clsx('uil-minus spin-input__minus btn-unstyled', {
          'is-disabled': !inMinRange(getIntValue(value) - step, props),
        })}
        onClick={handleDecrement}
      />
      <input
        {...props}
        type="tel"
        className={clsx('spin-input', props?.className)}
        value={value}
        onChange={handleChange}
      />
      <button
        type="button"
        data-testid="spinbutton-increment"
        className={clsx('uil-plus spin-input__plus btn-unstyled', {
          'is-disabled': !inMaxRange(getIntValue(value) + step, props),
        })}
        onClick={handleIncrement}
      />
    </div>
  )
}
