import * as React from 'react'
import { OfferModalPaymentClientData } from '@modals/offer-modal/offer-modal-payment-client-data'
import { OfferModalPaymentInvoiceData } from '@modals/offer-modal/offer-modal-payment-invoice-data'

export const OfferModalPaymentStep = (): JSX.Element => (
  <div>
    <h5 className="text-primary fw-semi-bold mb-1">Szczegóły płatności</h5>
    <span className="font-sm">Uzupełnij poniższe pola.</span>
    <OfferModalPaymentClientData />
    <hr className="mx-n3" />
    <OfferModalPaymentInvoiceData />
  </div>
)
