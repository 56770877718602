import axios, { AxiosRequestConfig, Method } from 'axios'

export async function commonObjectGet<Response>(url: string, config?: AxiosRequestConfig): Promise<Response> {
  return await callMethod<Response>({ method: 'get', url, config })
}

export async function commonObjectDelete<Response, Payload = void>(url: string, payload?: Payload): Promise<Response> {
  return callMethod<Response, Payload>({ method: 'delete', url, payload })
}

export async function commonObjectPost<Response, Payload = unknown>(
  url: string,
  payload?: Payload,
  config?: AxiosRequestConfig,
): Promise<Response> {
  return callMethod<Response, Payload>({ method: 'post', url, payload, config })
}

export async function commonObjectPut<Response, Payload>(url: string, payload?: Payload): Promise<Response> {
  return callMethod<Response, Payload>({ method: 'put', url, payload })
}

export async function commonObjectPatch<Response, Payload = void>(url: string, payload?: Payload): Promise<Response> {
  return callMethod<Response, Payload>({ method: 'patch', url, payload })
}

interface CallMethod<Payload> {
  method: Method
  url: string
  payload?: Payload | Payload[]
  config?: AxiosRequestConfig
}

async function callMethod<Response, Payload = void>({
  method,
  payload,
  url,
  config,
}: CallMethod<Payload>): Promise<Response> {
  const { data } = await axios({ url, method, data: payload, ...config })
  return data
}
