import { ModalProps } from 'reactstrap'
import { SubscriptionOfferModal } from '@modules/offers/subscriptions/subscription-offer-modal'
import { PackageVipOfferModal } from '@modules/offers/package-vip/package-vip-offer-modal'
import { WeekendStayOfferModal } from '@modules/offer-carousel/offer-slides/weekend-stay-offer-modal'
import { LoginModal } from '@components/header/login/login-modal'
import { NotificationMessageModal } from '@modals/notification-message-modal'
import { SubscriptionWithBenefitDescriptionModal } from '@modules/offers/subscription-with-benefit/modals/subscription-with-benefit-description-modal'
import { BookingOffersAdvancedFiltersModal } from '@modules/booking-offers/filters/advanced-filters/booking-offers-advanced-filters-modal'
import { GastroCardDynamicDescriptionModal } from '@modules/offers/gastro-card/dynamic/details/modals/offer-description/gastro-card-dynamic-description-modal'

export type ModalTypes =
  | 'SubscriptionOfferModal'
  | 'NotificationMessageModal'
  | 'PackageVipOfferModal'
  | 'WeekendStayOfferModal'
  | 'LoginModal'
  | 'SubscriptionWithBenefitDescriptionModal'
  | 'BookingOffersAdvancedFiltersModal'
  | 'GastroCardDynamicDescriptionModal'

export type ModalParams<T = unknown> = Pick<ModalProps, 'centered' | 'backdrop' | 'size' | 'className'> | T

type ModalComponent = (params) => JSX.Element

type ModalsMapType<T> = {
  [key in ModalTypes]: [ModalComponent, ModalParams<T>]
}

export const ModalsMap: ModalsMapType<never> = {
  NotificationMessageModal: [
    NotificationMessageModal,
    { centered: true, className: 'custom-modal-with-image is-notification-modal' },
  ],
  LoginModal: [LoginModal, { size: 'lg', className: 'custom-modal-with-image is-login-modal', centered: true }],
  SubscriptionOfferModal: [SubscriptionOfferModal, { size: 'lg', className: 'offer-modal', centered: true }],
  PackageVipOfferModal: [PackageVipOfferModal, { size: 'lg', className: 'offer-modal', centered: true }],
  BookingOffersAdvancedFiltersModal: [BookingOffersAdvancedFiltersModal, { size: 'semi-lg', centered: true }],
  WeekendStayOfferModal: [WeekendStayOfferModal, { size: 'lg', className: 'offer-modal', centered: true }],
  SubscriptionWithBenefitDescriptionModal: [
    SubscriptionWithBenefitDescriptionModal,
    { className: 'is-dark-close subscription-with-benefit__description-modal', centered: true },
  ],
  GastroCardDynamicDescriptionModal: [
    GastroCardDynamicDescriptionModal,
    { className: 'is-dark-close', centered: true, size: 'lg' },
  ],
}

export interface ModalConfig {
  persist?: boolean
  onClose?: () => void
}

export interface BaseModalProps {
  toggleIsVisible: () => void
}
