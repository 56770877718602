import * as React from 'react'
import { BookingOfferHeader } from '@modules/booking-offers/booking-offers-header'
import { BookingOfferFilters } from '@modules/booking-offers/filters/booking-offers-filters'
import { useGetBookingOffersQuery } from '@api/booking-offers'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { TypedQueryResult } from '@api/base'
import { BookingOffersResponse } from '@modules/booking-offers/models'
import { ContentLoader } from '@components/loaders/content-loader'
import { BookingOfferCards } from '@modules/booking-offers/cards/booking-offers-cards'
import { BookingOffersAdvancedFiltersFormInputs } from '@modules/booking-offers/filters/advanced-filters/booking-offers-advanced-filters-modal'
import { useBookingOffersWebsocket } from '@modules/booking-offers/use-booking-offers-websocket'

export type BookingOfferSeason = 'winter' | 'summer'

export interface BookingOffersFiltersParams {
  page_size: number
  ordering: string
  season: BookingOfferSeason
  advancedFilters: BookingOffersAdvancedFiltersFormInputs | null
}

export const BookingOffers = (): JSX.Element | null => {
  useBookingOffersWebsocket()

  const methods = useForm<BookingOffersFiltersParams>({
    defaultValues: { ordering: 'date_from', season: 'summer', page_size: 12, advancedFilters: null },
  })

  const filterValues = useWatch({ control: methods.control })

  const getQueryParams = () => {
    const { advancedFilters, ...params } = filterValues
    return { ...params, ...advancedFilters }
  }

  const { data, isFetching, isLoading } = useGetBookingOffersQuery(
    getQueryParams(),
  ) as TypedQueryResult<BookingOffersResponse>

  const handleAdvancedFiltersChange = (filters: BookingOffersAdvancedFiltersFormInputs | null) => {
    methods.setValue('advancedFilters', filters)
  }

  const isSeasonFiltered = filterValues.season !== 'summer'

  if (!filterValues.advancedFilters && !isSeasonFiltered && !data?.results.length) return null

  return (
    <FormProvider {...methods}>
      <section className="container-xl booking-offers">
        <BookingOfferHeader />
        {data && (
          <BookingOfferFilters
            aggregation={data.aggregation}
            onFiltersChange={handleAdvancedFiltersChange}
            initialFilters={filterValues.advancedFilters as BookingOffersAdvancedFiltersFormInputs | null}
          />
        )}
        <ContentLoader isLoading={isFetching || isLoading}>
          {data && (
            <BookingOfferCards offerResponse={data} isFiltered={!!filterValues.advancedFilters || isSeasonFiltered} />
          )}
        </ContentLoader>
      </section>
    </FormProvider>
  )
}
