import * as React from 'react'
import clsx from 'clsx'
import { useFormContext, useWatch } from 'react-hook-form'
import { BookingOffersAdvancedFiltersFormInputs } from '@modules/booking-offers/filters/advanced-filters/booking-offers-advanced-filters-modal'

interface Props {
  days: number
}

export const BookingOffersAdvancedFiltersStayLengthButton = ({ days }: Props): JSX.Element => {
  const { setValue, control } = useFormContext<BookingOffersAdvancedFiltersFormInputs>()

  const activeDays = useWatch({ control, name: 'days' })
  const isActive = activeDays === days

  const handleClick = () => {
    setValue('days', days)
  }

  return (
    <div className="col-4">
      <button
        onClick={handleClick}
        className={clsx(
          'w-100 border shadow-sm p-3 rounded',
          { 'bg-primary': isActive },
          isActive ? 'text-white' : 'text-darker-gray',
        )}
      >
        <span className="fw-semi-bold text-start d-block">{days} doby</span>
        <div className="d-flex gap-1">
          {Array.from(Array(days).keys()).map(el => (
            <div
              key={el}
              style={{ height: 10, width: 10 }}
              className={clsx(' mt-2 rounded-circle', isActive ? 'bg-white' : 'bg-darker-gray')}
            />
          ))}
        </div>
      </button>
    </div>
  )
}
