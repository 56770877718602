import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { useResponsivenessImages } from '@hooks/use-responsiveness-images'
import { Gallery } from '@components/gallery'
import { Resort } from '@models/app-state'

interface Props {
  resort: Resort
}

export const ResortSectionRow = ({ resort }: Props): JSX.Element => (
  <div className="bg-white flex-fill resorts-gallery__resort ">
    <Gallery
      items={useResponsivenessImages(resort.reservation_images)}
      additionalClass="image-gallery--rounded-lg-left"
    />
    <div className="p-3 resorts-gallery__resort__description">
      <div className="font-xl text-dark-blue font-700"> {resort.name}</div>
      <span className="font-xxs">
        {resort.address_street}, {resort.address_postcode} {resort.address_city}
      </span>
      <div className="d-flex flex-column font-700 font-xs pt-1 flex-1 mt-auto">
        {!!resort.house_amount && (
          <IconWithText
            text={`${resort.house_amount} domków`}
            iconClassName="uil-estate text-primary me-2"
            className="text-darker-gray font-500"
          />
        )}
        {!!resort.apartment_amount && (
          <IconWithText
            text={`${resort.apartment_amount} apartamentów`}
            iconClassName="uil-building text-primary me-2"
            className="text-darker-gray font-500"
          />
        )}
      </div>
    </div>
  </div>
)
