import * as React from 'react'
import { commonObjectPost } from '@requests/basic-requests'
import { useFormContext, useWatch } from 'react-hook-form'
import { useSubscriptionWithBenefits } from '@modules/offers/subscription-with-benefit/details/use-subscription-with-benefits'
import { BaseFormFields, OfferPaymentBar } from '@modules/offers/common/details/personal-data/offer-payment-bar'
import { AvailableRuleKeyword } from '@models/app-state'
import { SubscriptionProductPaymentWrapper } from '@modules/offers/common/subscription-product-payment-wrapper'
import { useSubscriptionWithBenefitsGtmEvents } from '@modules/offers/subscription-with-benefit/details/use-subscription-with-benefits-gtm-events'

export const SubscriptionWithBenefitPayment = (): JSX.Element => {
  const { control, getValues } = useFormContext()
  const { getSelectedProduct } = useSubscriptionWithBenefits()

  const [offerVariant, offerVariantAccommodation, clientVariant] = useWatch({
    control,
    name: ['offerVariant', 'offerVariantAccommodation', 'clientVariant'],
  })

  const selectedProduct = getSelectedProduct(offerVariant, offerVariantAccommodation, clientVariant)
  const totalPrice = selectedProduct?.subscription_product.price_brutto ?? '0'

  const productRulesKeywords = selectedProduct
    ? ((selectedProduct.subscription_product.type === 'WITH_BENEFIT_20'
        ? ['subscription_with_benefit_20_rules', 'subscription_with_benefit_20_special_rules']
        : ['subscription_with_benefit_rules', 'subscription_with_benefit_special_rules']) as AvailableRuleKeyword[])
    : []

  const paymentAction = async (baseFields: BaseFormFields) => {
    const rules = productRulesKeywords.reduce(
      (previousRules, keyword) => ({ ...previousRules, [keyword]: getValues(keyword) }),
      {},
    )

    return await commonObjectPost<{ urls: { sale: string } }>(selectedProduct?.urls?.sale || '', {
      id: selectedProduct?.id,
      ...rules,
      ...baseFields,
    })
  }

  return (
    <SubscriptionProductPaymentWrapper
      gtmEventHook={useSubscriptionWithBenefitsGtmEvents}
      selectedProduct={selectedProduct}
    >
      <OfferPaymentBar
        totalPrice={totalPrice}
        canSubmit={!!offerVariant && !!selectedProduct}
        paymentAction={paymentAction}
        rulesKeywords={productRulesKeywords}
      />
    </SubscriptionProductPaymentWrapper>
  )
}
