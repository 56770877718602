import { CustomReactSelectOption } from '@components/controls/custom-react-select'

export const extractSelectOptionsValues = <T extends {}, R>(object: T): R => {
  // eslint-disable-next-line
  const isSelectOption = (element: any): element is CustomReactSelectOption => {
    if (typeof element !== 'object' || !element) return false

    const paramsLength = Object.keys(element).length
    if (paramsLength >= 2) {
      return 'label' in element && 'value' in element
    }

    return false
  }

  return Object.entries(object).reduce((cum, [key, value]) => {
    const isValueArray = Array.isArray(value)

    if ((isValueArray && value.every(item => isSelectOption(item))) || isSelectOption(value)) {
      return { ...cum, [key]: isValueArray ? value.map(el => el.value) : value.value }
    }

    return { ...cum, [key]: value }
  }, {}) as R
}
