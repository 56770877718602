import * as React from 'react'
import * as clsx from 'clsx'
import { useToggle } from '@hooks/use-toggle'
import { useFormContext } from 'react-hook-form'
import { CustomFormGroup, CustomFormGroupProps } from '@components/controls/custom-form-group'

interface Props extends CustomFormGroupProps {
  isLabelTop?: boolean
  wrapperClassName?: string
}

export const PasswordFormGroup = ({ isLabelTop, wrapperClassName, ...props }: Props): JSX.Element => {
  const [isVisible, toggleIsVisible] = useToggle()
  const { isFailed, isLoading, isSucceed } = props

  const {
    formState: { errors },
  } = useFormContext()

  const hasError = errors && !!errors[props.inputName]

  return (
    <div className={clsx('position-relative', wrapperClassName)}>
      <CustomFormGroup {...props} formControlProps={{ type: isVisible ? 'text' : 'password' }} />
      <i
        className={clsx('password-form-group__visibility-icon', isVisible ? 'uil-eye-slash' : 'uil-eye', {
          'is-password-check-eye-moved': isFailed || isLoading || isSucceed || hasError,
          'is-label-top': isLabelTop,
        })}
        onClick={toggleIsVisible}
      />
    </div>
  )
}
