import * as React from 'react'
import * as clsx from 'clsx'
import { useFormContext } from 'react-hook-form'
import { FormError } from '@components/controls/form-error'
import { FormGroup, FormGroupProps, Input, Label } from 'reactstrap'

interface Props extends FormGroupProps {
  inputName: string
  label: React.ReactNode
  indeterminate?: boolean
}

export const Checkbox = ({ inputName, label, indeterminate, ...props }: Props): JSX.Element => {
  const { register } = useFormContext()
  const inputRef = React.useRef<HTMLInputElement>()

  const { ref, ...rest } = register(inputName)

  React.useEffect(() => {
    if (inputRef.current && indeterminate !== undefined) {
      inputRef.current.indeterminate = indeterminate
    }
  }, [indeterminate])

  return (
    <>
      <FormGroup check type="checkbox" className={clsx('checkbox cursor-pointer', props.className)}>
        <Input
          type="checkbox"
          id={inputName}
          {...props}
          {...rest}
          {...props}
          innerRef={(input: HTMLInputElement) => {
            ref(input)
            inputRef.current = input
          }}
        />
        <Label for={inputName} check className="font-sm fw-semi-bold cursor-pointer">
          {label}
        </Label>
      </FormGroup>
      <FormError inputName={inputName} className="mb-3" />
    </>
  )
}
