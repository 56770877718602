import * as React from 'react'
import { BaseModalProps } from '@modals/types'
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { CircleStep } from '@components/circle-step'
import { OfferModalPriceRow } from '@modals/offer-modal/offer-modal-price-section'
import { OfferModalPaymentStep } from '@modals/offer-modal/offer-modal-payment-step'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { OfferModalPaymentDetailsActions } from '@modals/offer-modal/footer/offer-modal-paymnent-details-actions'
import { OfferModalPaymentPaymentAction } from '@modals/offer-modal/footer/offer-modal-paymnent-payment-actions'
import { Invoice } from '@models/client'
import { AvailableRuleKeyword } from '@models/app-state'

export interface BaseFormInputs extends Invoice {
  name: string
  phone: string
  email: string
}

export type OfferModalFormInputs = Record<keyof BaseFormInputs | keyof Invoice | AvailableRuleKeyword, string | boolean>

interface Props extends BaseModalProps {
  title: string
  children: React.ReactNode
  price: React.ReactNode
  requiredRulesKeywords: AvailableRuleKeyword[]
  onSubmit: (payload: OfferModalFormInputs) => Promise<void>
}

export const OfferModal = ({ title, toggleIsVisible, children, price, onSubmit }: Props): JSX.Element => {
  const [activeStep, setActiveStep] = React.useState(1)

  const methods = useForm<OfferModalFormInputs>({
    defaultValues: {
      name: '',
      phone: '',
      email: '',
      invoice_type: 'company',
    },
  })

  const requiredFields = useWatch({
    control: methods.control,
    name: ['name', 'phone', 'email'],
  })

  const isSubmitEnabled = requiredFields.every(Boolean)

  const handleStepChange = (step: number) => () => {
    setActiveStep(step)
  }

  return (
    <FormProvider {...methods}>
      <ModalHeader className="offer-modal__header" toggle={toggleIsVisible}>
        <div className="d-flex justify-content-between align-items-center">
          <strong className="text-primary">{title}</strong>
          <CircleStep step={activeStep} />
        </div>
      </ModalHeader>
      <ModalBody className="offer-modal__body" style={{ minHeight: '450px' }}>
        <img src={require('@assets/images/hp.jpg')} alt="Holiday Park & Resort" className="offer-modal__image" />
        <div className="flex-grow-1 p-3">{activeStep === 1 ? children : <OfferModalPaymentStep />}</div>
        <OfferModalPriceRow price={price} />
      </ModalBody>
      <ModalFooter className="offer-modal__footer">
        {activeStep === 1 ? (
          <OfferModalPaymentDetailsActions onClose={toggleIsVisible} onStepChange={handleStepChange(2)} />
        ) : (
          <OfferModalPaymentPaymentAction
            onReturn={handleStepChange(1)}
            isSubmitEnabled={isSubmitEnabled}
            onSubmit={onSubmit}
          />
        )}
      </ModalFooter>
    </FormProvider>
  )
}
