import * as React from 'react'
import { FormGroupProps, InputProps, LabelProps } from 'reactstrap'
import { FormControlWithLabel } from '@components/controls/form-control-with-label'
import { CustomFormControl } from '@components/controls/form-control'
import { MaskedFormControl, MaskedFormControlProps } from './masked-form-control'
import { FieldError } from 'react-hook-form'

export interface CustomFormGroupProps {
  label?: React.ReactNode
  inputName: string
  formControlProps?: InputProps
  formLabelProps?: LabelProps
  formGroupProps?: FormGroupProps
  isLoading?: boolean
  isSucceed?: boolean
  isFailed?: boolean
  inputSuffix?: React.ReactNode
  maskedFormControlProps?: Omit<MaskedFormControlProps, 'name'>
  renderError?: (error: FieldError) => React.ReactNode
}

export const CustomFormGroup = ({
  label,
  inputName,
  formControlProps,
  formLabelProps,
  formGroupProps,
  isLoading,
  isSucceed,
  isFailed,
  inputSuffix,
  maskedFormControlProps,
  renderError,
}: CustomFormGroupProps): React.ReactElement => {
  return (
    <FormControlWithLabel
      inputName={inputName}
      label={label}
      formLabelProps={formLabelProps}
      formGroupProps={formGroupProps}
      isLoading={isLoading}
      isSucceed={isSucceed}
      isFailed={isFailed}
      inputSuffix={inputSuffix}
      renderError={renderError}
    >
      {maskedFormControlProps ? (
        <MaskedFormControl name={inputName} className={formControlProps?.className} {...maskedFormControlProps} />
      ) : (
        <CustomFormControl inputName={inputName} formControlProps={formControlProps} />
      )}
    </FormControlWithLabel>
  )
}
