import { useSelector } from 'react-redux'
import * as React from 'react'
import { RootState } from '@store/index'
import { NotificationEvents } from '@requests/web-sockets/models'

type WebSocketCallback<T> = (message: NotificationEvents, payload: T) => void

export const useWebSocket = <T, D>(cb: WebSocketCallback<T>, deps: D[]): null => {
  const webSocket = useSelector((state: RootState) => state.webSocketState)

  React.useEffect(() => {
    if (webSocket.message) {
      cb(webSocket.message, webSocket.payload)
    }
  }, [webSocket, ...deps])

  return null
}
