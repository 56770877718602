import * as React from 'react'

import { IconWithText } from '@components/icon-with-text'
import { CustomFormGroup } from '@components/controls/custom-form-group'

export const OfferModalPaymentClientData = (): JSX.Element => (
  <div className="d-flex row mx-0 col-xl-9 col-12">
    <CustomFormGroup
      label="Imię i nazwisko:"
      inputName="name"
      formLabelProps={{ className: 'fw-bold' }}
      formControlProps={{ type: 'text', placeholder: 'np. Andrzej Nowak' }}
    />

    <CustomFormGroup
      label="Numer telefonu:"
      inputName="phone"
      maskedFormControlProps={{
        mask: '(+48) ***-***-***',
        className: 'letter-spacing-2',
        alwaysShowMask: true,
        type: 'tel',
      }}
      formLabelProps={{ className: 'fw-bold' }}
      formControlProps={{ type: 'tel' }}
    />
    <CustomFormGroup
      label="E-mail:"
      inputName="email"
      formLabelProps={{ className: 'fw-bold' }}
      formControlProps={{ type: 'email', placeholder: 'adres@email.pl' }}
    />
    <IconWithText
      text={
        <span className="font-xs">
          <strong>Pamiętaj!</strong> Sprawdź poprawność adresu e-mail i numeru telefonu, ponieważ wyślemy na nie
          potwierdzenie Twojego zakupu.
        </span>
      }
      iconClassName="uil-exclamation-circle text-primary font-xl me-2 align-self-start lh-initial "
      className="col-12 ms-sm-auto font-sm text-darker-gray px-0 mt-2"
    />
  </div>
)
