import * as React from 'react'
import { useInViewRef } from 'rooks'
import { SubscriptionContractPlan, SubscriptionContractPlanKind } from '@models/app-state'
import { useDidUpdateEffect } from '@hooks/use-did-update-effect'
import { useSubscriptionContractsGtmEvents } from '@modules/offers/subscription-contract/details/use-subscription-contract-gtm-events'
import { useFormContext, useWatch } from 'react-hook-form'
import { selectClientDetails } from '@store/slices/app-slice'
import { useAppSelector } from '@store/index'

interface Props {
  children: React.ReactNode
  selectedPlan: SubscriptionContractPlan
  selectedPlanKind: SubscriptionContractPlanKind
}

export const SubscriptionContractPaymentWrapper = ({
  children,
  selectedPlan,
  selectedPlanKind,
}: Props): JSX.Element => {
  const clientDetails = useAppSelector(selectClientDetails)
  const [checkoutBegins, setCheckoutBegins] = React.useState(false)
  const [ref, inView] = useInViewRef({ rootMargin: '0% 0% 20% 0%', threshold: 0.2 })

  const { sendBeginCheckoutEvent } = useSubscriptionContractsGtmEvents()

  const { control } = useFormContext()
  const clientFormData = useWatch({ control, name: ['first_name', 'last_name', 'email', 'username', 'password'] })

  useDidUpdateEffect(() => {
    if (clientDetails) return

    if (clientFormData.some(value => !!value) && selectedPlan && selectedPlanKind && !checkoutBegins) {
      sendBeginCheckoutEvent(selectedPlan, selectedPlanKind)
      setCheckoutBegins(true)
    }
  }, [clientFormData])

  useDidUpdateEffect(() => {
    setCheckoutBegins(false)
  }, [selectedPlanKind])

  React.useEffect(() => {
    if (!selectedPlanKind || !selectedPlan || !clientDetails) return

    if (inView && !checkoutBegins) {
      sendBeginCheckoutEvent(selectedPlan, selectedPlanKind)
      setCheckoutBegins(true)
    }
  }, [inView])

  return <div ref={ref}>{children}</div>
}
