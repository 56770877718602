import * as React from 'react'
import { commonObjectPost } from '@requests/basic-requests'
import { useFormContext, useWatch } from 'react-hook-form'
import { BaseFormFields, OfferPaymentBar } from '@modules/offers/common/details/personal-data/offer-payment-bar'
import { useGastroCardProduct } from '@modules/offers/gastro-card-boost/details/use-gastro-card-product'

export const GastroCardPayment = (): JSX.Element => {
  const { control, getValues } = useFormContext()

  const gastroCardProduct = useGastroCardProduct('gastro_card_2026')

  const [amountToSpent, declared_sell_price] = useWatch({
    control,
    name: ['amountToSpent', 'declared_sell_price'],
  })

  const paymentAction = async (baseFields: BaseFormFields) => {
    const payload = getValues()

    return await commonObjectPost<{ urls: { sale: string } }>(gastroCardProduct.urls.sale, {
      declared_sell_price: declared_sell_price,
      gastro_card_2026_rules: payload.gastro_card_2026_rules,
      ...baseFields,
    })
  }

  return (
    <OfferPaymentBar
      paymentAction={paymentAction}
      canSubmit={!!amountToSpent}
      totalPrice={declared_sell_price}
      rulesKeywords={['gastro_card_2026_rules']}
    />
  )
}
