import * as React from 'react'
import { HeaderUserInfo } from '@components/header/app-header-user-info'
import { NavigationPath } from '@models/routes'

export const AppHeader = (): JSX.Element => {
  const goToMainPage = () => {
    window.location.href = NavigationPath.Homepage
  }

  return (
    <header className="header">
      <div className="container-xl d-flex align-items-center">
        <img
          className="cursor-pointer"
          src={require('@assets/images/logo_holiday_sklep_color.svg')}
          alt="Holiday Park & Resort"
          height={35}
          onClick={goToMainPage}
        />

        <div className="border-start ms-sm-4 ms-2 d-flex align-items-center">
          <p className="text-primary ms-sm-4 ms-2 font-sm mb-0 lh-initial fw-semi-bold">
            <span className="text-nowrap">Najlepsze okazje</span> <br />{' '}
            <span className="text-nowrap">w jednym miejscu</span>
          </p>
        </div>

        <div className="ms-auto align-items-center d-flex justify-content-end">
          <HeaderUserInfo />
        </div>
      </div>
    </header>
  )
}
