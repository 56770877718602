import * as React from 'react'
import { useRemainingTime } from '@hooks/use-remaining-time'
import { IconWithText } from '@components/icon-with-text'
import { Spinner } from 'reactstrap'
import * as clsx from 'clsx'

interface Props {
  expirationDate: string
  wrapperClassName?: string
}

export const RemainingTime = ({ expirationDate, wrapperClassName }: Props): JSX.Element => {
  const { remainingTime, isCalculating } = useRemainingTime(expirationDate)

  return (
    <div className={clsx('border rounded bg-light-gray', wrapperClassName)}>
      <div className="d-flex align-items-center px-3 py-1">
        <strong className="me-2 font-xl">Kończy się za:</strong>
        <IconWithText
          text={isCalculating ? <Spinner className="spinner-border-sm" /> : remainingTime}
          iconClassName="uil-clock-five me-2 font-xxl"
          className="fw-bold text-danger"
          textClassName="font-xl"
        />
      </div>
    </div>
  )
}
