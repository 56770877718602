import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { formatPriceShort } from '@helpers/price-helper'

interface Props {
  min: number
  max: number
  initialMinValue: number
  initialMaxValue: number
  minInputName: string
  maxInputName: string
}

export const RangeInput = ({
  initialMinValue,
  initialMaxValue,
  min,
  max,
  minInputName,
  maxInputName,
}: Props): React.ReactNode => {
  const [minVal, setMinVal] = React.useState(initialMinValue)
  const [maxVal, setMaxVal] = React.useState(initialMaxValue)
  const minValRef = React.useRef<number>(initialMinValue)
  const maxValRef = React.useRef(initialMaxValue)
  const range = React.useRef<HTMLDivElement>(null)

  const { setValue } = useFormContext()

  const getPercent = React.useCallback(value => Math.round(((value - min) / (max - min)) * 100), [min, max])

  React.useEffect(() => {
    const minPercent = getPercent(minVal)
    const maxPercent = getPercent(maxValRef.current)

    if (range.current) {
      range.current.style.left = `${minPercent}%`
      range.current.style.width = `${maxPercent - minPercent}%`
    }
  }, [minVal, getPercent])

  React.useEffect(() => {
    const minPercent = getPercent(minValRef.current)
    const maxPercent = getPercent(maxVal)

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`
    }
  }, [maxVal, getPercent])

  React.useEffect(() => {
    setValue(minInputName, minVal)
    setValue(maxInputName, maxVal)
  }, [minVal, maxVal])

  return (
    <div className="w-100 position-relative slider__wrapper">
      <input
        name={minInputName}
        type="range"
        min={min}
        max={max}
        value={minVal}
        onChange={event => {
          const value = Math.min(Number(event.target.value), maxVal - 50)
          setMinVal(value)
          minValRef.current = value
        }}
        className="thumb thumb--left"
        style={{ ...(minVal > max - 100 && { zIndex: 5 }) }}
      />
      <input
        name={maxInputName}
        type="range"
        min={min}
        max={max}
        value={maxVal}
        onChange={event => {
          const value = Math.max(Number(event.target.value), minVal + 50)
          setMaxVal(value)
          maxValRef.current = value
        }}
        className="thumb thumb--right"
      />

      <div className="slider">
        <div className="slider__track" />
        <div ref={range} className="slider__range" />
        <div className="slider__left-value">{formatPriceShort(minVal)}</div>
        <div className="slider__right-value">{formatPriceShort(maxVal)}</div>
      </div>
    </div>
  )
}
