import * as React from 'react'
import * as clsx from 'clsx'

type BadgeVariant = 'gold' | 'red'

const BadgeVariant: Record<BadgeVariant, string> = {
  gold: 'bg-primary',
  red: 'bg-danger',
}

interface Props {
  variant: BadgeVariant
  className?: string
  text: string
}

export const Badge = ({ text, variant, className }: Props): JSX.Element => (
  <div
    className={clsx(
      'py-1 px-2 text-white d-inline-block fw-semibold font-md rounded',
      BadgeVariant[variant],
      className,
    )}
  >
    {text}
  </div>
)
