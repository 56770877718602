import * as React from 'react'
import { UncontrolledTooltip } from 'reactstrap'
import { UncontrolledTooltipProps } from 'reactstrap/types/lib/Tooltip'

interface Props extends UncontrolledTooltipProps {
  tooltipContent: React.ReactNode
  children: React.ReactNode
}

export const CustomUncontrolledTooltip = ({ tooltipContent, children, ...props }: Props): JSX.Element => (
  <>
    <UncontrolledTooltip delay={300} {...props}>
      {tooltipContent}
    </UncontrolledTooltip>
    {children}
  </>
)
