import * as React from 'react'
import { ContentLoader } from '@components/loaders/content-loader'
import { useSelector } from 'react-redux'
import { selectAppState } from '@store/slices/app-slice'

interface Props {
  children: React.ReactNode
}

export const Layout = ({ children }: Props): JSX.Element => {
  const { isAppReady } = useSelector(selectAppState)

  return (
    <ContentLoader isLoading={!isAppReady} className="is-full-screen">
      {isAppReady && children}
    </ContentLoader>
  )
}
