import 'core-js/stable'
import 'regenerator-runtime/runtime'
import '@iconscout/unicons/css/line.css'

import '@assets/styles/app.scss'
import * as React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { StartupView } from './startup'
import { ModalComponent } from '@modals/modal-component'
import store from '@store/index'
import { AnimatedRouter } from './animated-router'
import TagManager from 'react-gtm-module'

if (process.env.ECOMMERCE_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.ECOMMERCE_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.005,
  })
}

if (process.env.GOOGLE_TAG_MANAGER) {
  TagManager.initialize({ gtmId: process.env.GOOGLE_TAG_MANAGER })
}

export const AppView = (): React.ReactNode => {
  return (
    <Provider store={store}>
      <StartupView>
        <BrowserRouter basename="/">
          <ModalComponent />
          <AnimatedRouter />
        </BrowserRouter>
      </StartupView>
    </Provider>
  )
}
