import * as React from 'react'
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, AccordionProps } from 'reactstrap'
import * as clsx from 'clsx'

export interface FaqQuestion {
  question: string
  answer: React.ReactNode
}

interface Props {
  questions: FaqQuestion[]
}

export const FaqSection = ({ questions }: Props): JSX.Element => {
  const [openAccordion, setOpenAccordion] = React.useState('')

  const toggleOpenAccordion = (index: string) => {
    setOpenAccordion(accordion => (accordion === index ? '' : index))
  }

  const isShowed = (index: number) => openAccordion === index.toString()

  return (
    <div className="col-12 col-sm-8 col-lg-5 mx-auto my-5 faq-section">
      <h4 className="fw-bold font-xxl text-secondary text-center mb-4">Najczęściej zadawanie pytania</h4>
      {questions.map((question, index) => (
        <CustomAccordion
          flush
          key={index}
          open={openAccordion}
          toggle={toggleOpenAccordion}
          className={clsx('faq-section__accordion', { 'is-showed': isShowed(index) })}
        >
          <AccordionItem>
            <AccordionHeader targetId={index.toString()}>
              <div className="d-flex align-items-center justify-content-between w-100">
                <div>{question.question}</div>
                <div className="d-block">
                  <i className={`font-xl text-darker-gray lh-1 ${isShowed(index) ? 'uil-minus' : 'uil-plus'}`} />
                </div>
              </div>
            </AccordionHeader>
            <AccordionBody className="font-md" accordionId={index.toString()}>
              {question.answer}
            </AccordionBody>
          </AccordionItem>
        </CustomAccordion>
      ))}
    </div>
  )
}

const CustomAccordion = ({ ...params }: { toggle: (index: string) => void } & Omit<AccordionProps, 'toggle'>) => (
  <Accordion {...params}>{params.children}</Accordion>
)
