import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { RootState } from '@store/index'
import { ModalConfig, ModalParams, ModalTypes } from '@modals/types'
import { selectModalById, setModalIsOpen, setShowModal } from '@store/slices/modal-slice'

type ToggleIsOpen = (event?, params?: ModalParams, showModal?: boolean) => void

type HideModal = () => void

export const useModal = <T,>(
  modalName: ModalTypes,
  modalParams?: ModalParams<T>,
  config?: ModalConfig,
): [ToggleIsOpen, HideModal] => {
  const dispatch = useDispatch()
  const hasModal = !!useSelector((state: RootState) => selectModalById(state, modalName))

  React.useEffect(() => {
    return () => {
      if (!config?.persist) {
        dispatch(setModalIsOpen({ modal: modalName, isOpen: false }))
      }
    }
  }, [])

  const toggleIsOpen = React.useCallback(
    (event?, optionalParams?: ModalParams<T>, showModal?: boolean) => {
      event?.preventDefault?.()

      if (hasModal && !showModal) {
        return hideModal()
      } else {
        return dispatch(
          setShowModal({ modal: modalName, params: { ...optionalParams, ...modalParams }, isOpen: true, config }),
        )
      }
    },
    [modalName, hasModal, modalParams],
  )

  const hideModal = React.useCallback(() => dispatch(setModalIsOpen({ modal: modalName, isOpen: false })), [modalName])

  return [toggleIsOpen, hideModal]
}
