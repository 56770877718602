import * as React from 'react'
import { ExpandableProductDescription } from '@components/expandable-product-description'

export const SubscriptionContractCostsInfo = (): JSX.Element => (
  <section className="font-sm my-4">
    <ExpandableProductDescription isCollapsable>
      <p>
        Aktywacja i dalsze utrzymanie Subskrypcji odbywa się w ramach płatności cyklicznych, tj. comiesięcznej opłaty w
        ustalonej wcześniej kwocie. Cena (opłata miesięczna) Subskrypcji jest zależna od wybranego planu, spośród ośmiu
        do wyboru
      </p>
      <p>
        Subskrybent podaje dane karty, które są przechowywane przez bezpiecznego operatora płatności Przelewy24.
        Operator pośrednicząc w dokonaniu płatności cyklicznej, automatycznie pobiera środki z karty w ustalonej kwocie,
        w podanych wcześniej terminach.
      </p>
      <p>
        Subskrybent zobowiązany jest do utrzymywania wskazanej karty w okresie korzystania z promocji. Można anulować
        Subskrypcję w dowolnym momencie. Jej anulacja lub wygaśnięcie każdorazowo powoduje wycofanie otrzymanych
        bonusów.
      </p>
      <p className="mb-0">Szczegółowe zasady wykorzystania określa Regulamin oferty.</p>
    </ExpandableProductDescription>
  </section>
)
