import * as React from 'react'
import * as clsx from 'clsx'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isActive: boolean
  onClick: () => void
  text?: string
  textWrapperClassName?: string
  wrapperClassName?: string
}

export const ToggleSwitch = ({
  isActive,
  onClick,
  text,
  textWrapperClassName,
  wrapperClassName,
  ...props
}: Props): React.ReactElement => (
  <div className={clsx('d-inline-flex align-items-center', wrapperClassName)} onClick={onClick}>
    <button {...props} className={clsx('toggle-switch', { 'is-active': isActive }, props.className)}>
      <div className="toggle-switch__state" />
    </button>
    {text && <strong className={clsx('toggle-switch__text', textWrapperClassName)}>{text}</strong>}
  </div>
)
