import * as React from 'react'
import { Checkbox } from '@components/controls/checkbox'
import { AgreementSection, BASIC_RULES } from '@components/agreement/agreement-section'
import { useRules } from '@hooks/use-rules'
import { Rule } from '@models/app-state'

interface Props {
  rules: Rule[]
}

export const DynamicAgreementsSection = ({ rules }: Props): JSX.Element => {
  const { getRules } = useRules()

  const rulesKeywords = rules.reduce(
    (previousValue, currentValue) =>
      currentValue.required && !BASIC_RULES.includes(currentValue?.keyword)
        ? [...previousValue, currentValue.keyword]
        : previousValue,
    [],
  )

  const rulesToShow = getRules(rulesKeywords)

  return (
    <AgreementSection className="mt-3" additionalRulesNames={rulesKeywords}>
      {rulesToShow.map(rule => (
        <Checkbox
          key={rule?.keyword}
          inputName={rule?.keyword ?? ''}
          className="checkbox--small"
          label={
            <span>
              Oświadczam, że zapoznałem się z treścią dokumentu
              <a className="text-darker-gray text-decoration-none fw-bold mx-1" href={rule?.url} target="_blank">
                {rule?.name}
              </a>
              i akceptuję jego postanowienia.
            </span>
          }
        />
      ))}
    </AgreementSection>
  )
}
