import * as React from 'react'
import { CircleCheck } from '@components/circle-check'
import {
  Accommodation,
  SubscriptionWithBenefitVariant,
} from '@modules/offers/subscription-with-benefit/details/variants/card/subscription-with-benefit-variants-card'
import { formatPriceShort } from '@helpers/price-helper'
import { subscriptionWithBenefitsUsageYears } from '@modules/offers/subscription-with-benefit/consts'

interface Props {
  variant: SubscriptionWithBenefitVariant
  accommodation: Accommodation
}

export const SubscriptionWithBenefitDiscountsList = ({ variant, accommodation }: Props): JSX.Element => {
  const { firstYear, secondYear, thirdYear, fourthYear } = getSubscriptionWithBenefitDiscounts(variant, accommodation)

  return (
    <ul className="ps-0 mb-0">
      <ListItem>
        voucher na pobyt w latach:{' '}
        <span className="text-nowrap">
          {subscriptionWithBenefitsUsageYears[0]} - {subscriptionWithBenefitsUsageYears.at(-1)}
        </span>
        <div className="font-xs fw-normal">do wykorzystania w dowolnym roku</div>
      </ListItem>

      <ListItem>
        voucher na pobyt w {subscriptionWithBenefitsUsageYears[1]} roku
        <div>
          <strong>+ {formatPriceShort(firstYear)}</strong> na zakupy
        </div>
      </ListItem>

      <ListItem>
        voucher na pobyt w {subscriptionWithBenefitsUsageYears[2]} roku
        <div>
          <strong> + {formatPriceShort(secondYear)}</strong> na zakupy
        </div>
      </ListItem>

      <ListItem>
        voucher na pobyt w {subscriptionWithBenefitsUsageYears[3]} roku
        <div>
          <strong>+ {formatPriceShort(thirdYear)}</strong> na zakupy
        </div>
      </ListItem>

      <ListItem>
        voucher na pobyt w {subscriptionWithBenefitsUsageYears[4]} roku
        <div>
          <strong>+ {formatPriceShort(fourthYear)} </strong> na zakupy <div className="fw-bold"> + pakiet VIP</div>
        </div>
      </ListItem>
    </ul>
  )
}

const ListItem = ({ children }) => (
  <li className="font-sm mb-2">
    <hr className="mx-n4 my-2" />
    <CircleCheck circleClassName="is-small">
      <span className="fw-semi-bold">{children}</span>
    </CircleCheck>
  </li>
)

interface SubscriptionWithBenefitDiscounts {
  firstYear: number
  secondYear: number
  thirdYear: number
  fourthYear: number
}

export const getSubscriptionWithBenefitDiscounts = (
  variant: SubscriptionWithBenefitVariant,
  accommodation: Accommodation,
): SubscriptionWithBenefitDiscounts => {
  const createBenefit = (firstYear, secondYear, thirdYear, fourthYear) => ({
    firstYear,
    secondYear,
    thirdYear,
    fourthYear,
  })

  if (variant === 'lux')
    return accommodation === 'house' ? createBenefit(1000, 2000, 3000, 4000) : createBenefit(500, 1500, 2500, 3500)

  if (variant === 'comfort')
    return accommodation === 'house' ? createBenefit(500, 1000, 1500, 3000) : createBenefit(400, 800, 1000, 2800)

  if (variant === 'optimum')
    return accommodation === 'house' ? createBenefit(500, 1000, 1500, 2000) : createBenefit(400, 800, 1000, 1800)

  return createBenefit(0, 0, 0, 0)
}
