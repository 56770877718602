import * as React from 'react'
import * as clsx from 'clsx'
import { IconWithText } from '@components/icon-with-text'
import { useFormContext } from 'react-hook-form'

interface Props {
  inputName: string
  className?: string
}

export const FormError = ({ inputName, className }: Props): JSX.Element | null => {
  const {
    formState: { errors },
  } = useFormContext()

  const hasError = errors && !!errors[inputName]

  return hasError ? <ErrorMessage message={errors[inputName]?.message?.toString() ?? ''} className={className} /> : null
}

export const ErrorMessage = ({
  className,
  flexClassName,
  message,
}: {
  className?: string
  flexClassName?: string
  message: string
}): React.ReactNode => (
  <IconWithText
    className={clsx('text-danger align-items-center mt-2', className)}
    flexClassName={flexClassName}
    textClassName="font-xs fw-normal form-error-text"
    iconClassName="uil-info-circle me-2 align-self-start form-error-icon"
    text={message}
  />
)
