import * as React from 'react'
import { useApiRequest } from '@hooks/use-request'
import { commonObjectGet } from '@requests/basic-requests'
import { AppDataWithClientDetails } from '@models/app-state'
import { setAppData } from '@store/slices/app-slice'
import { useAppDispatch } from '@store/index'
import { useNotificationModal } from '@hooks/use-notification-modal'
import { useWebsocketsHandler } from '@requests/web-sockets/use-web-socket-handler'

const APP_DATA_URL = 'api/ecommerce/app-data/'

interface Props {
  children: JSX.Element
}

export const StartupView = ({ children }: Props): JSX.Element | null => {
  const dispatch = useAppDispatch()
  useNotificationModal()
  useWebsocketsHandler()

  const { action: fetchData } = useApiRequest(async () => {
    const appData = await commonObjectGet<AppDataWithClientDetails>(APP_DATA_URL)

    dispatch(setAppData(appData))
  })

  React.useEffect(() => {
    fetchData()
  }, [])

  return children
}
