import * as React from 'react'
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { BaseModalProps } from '@modals/types'
import { IconWithText } from '@components/icon-with-text'
import { TrapezoidButton } from '@components/controls/buttons/trapezoid-button'
import { FormProvider, useForm } from 'react-hook-form'
import { TextWithSpinner } from '@components/loaders/text-with-spinner'
import { BookingOffersAdvancedFiltersStayLength } from '@modules/booking-offers/filters/advanced-filters/booking-offers-advancde-filters-stay-length'
import { BookingOffersAdvancedFiltersDates } from '@modules/booking-offers/filters/advanced-filters/booking-offers-advancde-filters-dates'
import { BookingOffersAdvancedFiltersPrice } from '@modules/booking-offers/filters/advanced-filters/booking-offers-advancde-filters-price'
import { BookingOffersAggregation } from '@modules/booking-offers/models'

export interface BookingOffersAdvancedFiltersFormInputs {
  date_from: Date | null
  date_to: Date | null
  days: number | null
  price_brutto_min: number
  price_brutto_max: number
}

interface Props extends BaseModalProps {
  onFiltersChange: (filters: BookingOffersAdvancedFiltersFormInputs) => void
  initialFilters?: BookingOffersAdvancedFiltersFormInputs
  aggregation: BookingOffersAggregation
}

export const BookingOffersAdvancedFiltersModal = ({
  toggleIsVisible,
  onFiltersChange,
  initialFilters,
  aggregation,
}: Props): JSX.Element => {
  const defaultValues = {
    days: null,
    date_from: null,
    date_to: null,
    price_brutto_min: Number(aggregation.price_brutto.min),
    price_brutto_max: Number(aggregation.price_brutto.max),
  }

  const methods = useForm<BookingOffersAdvancedFiltersFormInputs>({
    defaultValues: {
      ...defaultValues,
      ...(initialFilters && initialFilters),
    },
  })

  const handleSubmit = async () => {
    onFiltersChange(methods.getValues())
    toggleIsVisible()
  }

  return (
    <FormProvider {...methods}>
      <ModalHeader toggle={toggleIsVisible} className="text-primary px-sm-5">
        Wyszukiwanie zaawansowane
      </ModalHeader>
      <ModalBody className="px-sm-5">
        <IconWithText
          iconClassName="uil-exclamation-circle text-primary font-xl me-2 align-self-start"
          text={
            <>
              System pokazuje tylko <strong>krótkie</strong> wolne terminy w kalendarzu. <br /> W procesie rezerwacji
              nadal możesz znaleźć terminy dla dłuższych pobytów
            </>
          }
        />

        <h4 className="text-secondary mt-4">Szukam krótkiego terminu....</h4>

        <BookingOffersAdvancedFiltersDates />
        <BookingOffersAdvancedFiltersStayLength />
        <BookingOffersAdvancedFiltersPrice
          initialMax={initialFilters?.price_brutto_max ?? defaultValues.price_brutto_max}
          initialMin={initialFilters?.price_brutto_min ?? defaultValues.price_brutto_min}
          max={defaultValues.price_brutto_max}
          min={defaultValues.price_brutto_min}
        />
      </ModalBody>
      <ModalFooter className="p-0 d-flex justify-content-between">
        <button className="btn-unstyled ms-sm-5 ms-3" onClick={toggleIsVisible}>
          <IconWithText text="Zamknij" iconClassName="uil-times font-xxl me-2" className="fw-bold" />
        </button>
        <TrapezoidButton onClick={handleSubmit} variant="secondary">
          <TextWithSpinner
            isLoading={false}
            loadingText="Proszę czekać"
            wrapperClassName="align-items-center d-flex"
            text="Pokaż terminy"
          />
        </TrapezoidButton>
      </ModalFooter>
    </FormProvider>
  )
}
