import * as React from 'react'
import {
  SubscriptionWithBenefitVariant,
  SubscriptionWithBenefitVariantCard,
} from './card/subscription-with-benefit-variants-card'
import { useSubscriptionWithBenefits } from '@modules/offers/subscription-with-benefit/details/use-subscription-with-benefits'
import { OfferDetailsRow } from '@modules/offers/common/details/offer-details-row'

export const SubscriptionWithBenefitOfferVariants = (): JSX.Element => {
  const { products } = useSubscriptionWithBenefits()

  const sortedProducts = {
    ...(products.optimum && { optimum: products.optimum }),
    ...(products.comfort && { comfort: products.comfort }),
    ...(products.lux && { lux: products.lux }),
  }

  return (
    <OfferDetailsRow
      title={
        <span className="text-secondary">
          <strong className="d-block">Wybierz swój</strong> Pakiet Korzyści
        </span>
      }
    >
      <div className="row">
        {Object.entries(sortedProducts).map(([variant, variantData]) => (
          <SubscriptionWithBenefitVariantCard
            key={variant}
            variant={variant as SubscriptionWithBenefitVariant}
            variantData={variantData}
          />
        ))}
      </div>
    </OfferDetailsRow>
  )
}
