import { useSelector } from 'react-redux'
import { selectAppData } from '@store/slices/app-slice'
import { SubscriptionContractPlan, SubscriptionContractPlanKind, SubscriptionContractProduct } from '@models/app-state'
import { useAppSelector } from '@store/index'

export const useSubscriptionContractProduct = (): SubscriptionContractProduct => {
  const { subscription_contracts } = useSelector(selectAppData)

  return subscription_contracts[0] as SubscriptionContractProduct
}

export const useSubscriptionContractPlans = (): Record<SubscriptionContractPlanKind, SubscriptionContractPlan> => {
  const { subscription_contract_plans } = useAppSelector(selectAppData)
  return subscription_contract_plans
}
