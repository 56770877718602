import { createCartItem, useGtmEvents } from '@hooks/use-gtm-events'
import { SubscriptionWIthBenefitProductPrefix } from '@modules/offers/subscription-with-benefit/details/use-subscription-with-benefits'
import { useAppSelector } from '@store/index'
import { selectAppData } from '@store/slices/app-slice'
import { Subscription } from '@models/app-state'

interface Response {
  sendViewItemListEvent: () => void
  sendAddToCartEvent: (product: Subscription) => void
  sendBeginCheckoutEvent: (product: Subscription) => void
}

export const useSubscriptionWithBenefitsGtmEvents = (): Response => {
  const { subscriptions } = useAppSelector(selectAppData)
  const { viewItemList, addToCart, beginCheckout } = useGtmEvents()

  const sendViewItemListEvent = () => {
    const subscriptionsWithBenefits = subscriptions.filter(subscription =>
      subscription.code.startsWith(SubscriptionWIthBenefitProductPrefix),
    )

    if (!subscriptionsWithBenefits.length) return
    viewItemList(subscriptionsWithBenefits.map(createSubscriptionWithBenefitEcommerceItem), 'pakiet-korzysci')
  }

  const sendAddToCartEvent = (product: Subscription) => {
    addToCart(product.subscription_product.price_brutto, createSubscriptionWithBenefitEcommerceItem(product))
  }

  const sendBeginCheckoutEvent = (product: Subscription) => {
    beginCheckout(product.subscription_product.price_brutto, [createSubscriptionWithBenefitEcommerceItem(product)])
  }

  return {
    sendViewItemListEvent,
    sendAddToCartEvent,
    sendBeginCheckoutEvent,
  }
}

const createSubscriptionWithBenefitEcommerceItem = (item: Subscription, index?: number) =>
  createCartItem({
    index,
    name: item.name,
    id: item.subscription_product.id,
    price: item.subscription_product.price_brutto,
    itemListName: 'pakiet-korzysci',
  })
