import * as React from 'react'
import { TrapezoidButton } from '@components/controls/buttons/trapezoid-button'
import { IconWithText } from '@components/icon-with-text'
import { ConfettiRef, ConfettiWrapper } from '@components/confetti-wrapper'
import clsx from 'clsx'

interface Props {
  isSelected: boolean
  onSelectionChange: () => void
  text?: string
  activeText?: string
  className?: string
  icon?: string
}

export const OfferSelectionButton = ({
  isSelected,
  onSelectionChange,
  text = 'Wybieram',
  activeText = 'Wybrany',
  className,
  icon = 'uil-shopping-bag',
}: Props): JSX.Element => {
  const confettiRef = React.useRef<ConfettiRef>(null)

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    onSelectionChange()

    if (!isSelected) {
      confettiRef.current?.play()
    }
  }

  return (
    <ConfettiWrapper ref={confettiRef}>
      <TrapezoidButton onClick={handleClick} variant={isSelected ? 'success' : 'danger'} className={className}>
        {isSelected ? (
          <IconWithText
            text={activeText}
            iconClassName="uil-check font-xxxl me-2 lh-initial"
            textClassName="text-nowrap"
          />
        ) : (
          <IconWithText
            text={text}
            iconClassName={clsx('font-xxxl me-2 lh-initial', icon)}
            textClassName="text-nowrap"
          />
        )}
      </TrapezoidButton>
    </ConfettiWrapper>
  )
}
