import * as React from 'react'
import { Gallery } from '@components/gallery'
import { CircleCheck } from '@components/circle-check'
import { ReactImageGalleryItem } from 'react-image-gallery'

interface Props {
  images: ReactImageGalleryItem[]
  title: React.ReactNode
  description: React.ReactNode
  benefits: string[]
  children: React.ReactNode
}

export const OfferDetailsBaseInfo = ({ images, title, description, benefits, children }: Props): JSX.Element => (
  <div className="container-xl row col-12 col-xl-11 mx-auto flex-lg-row flex-wrap mb-lg-5">
    <div className="col-12 col-lg-4 mb-5 mb-lg-0 subscription-with-benefit__gallery-img">
      <Gallery items={images} showThumbnails={true} />
    </div>
    <div className="col-lg-8 col-12 ps-lg-5">
      <div>
        <h2 className="font-xxxl text-secondary mt-4 mb-3 fw-bold text-center text-lg-start">{title}</h2>
        <div className="col-12 col-lg-9 text-center text-lg-start">{description}</div>
        <hr />
        <div className="mt-2 row">
          {benefits.map(benefit => (
            <CircleCheck key={benefit} className="col-lg-6 mb-1 fw-semi-bold font-sm">
              {benefit}
            </CircleCheck>
          ))}
        </div>
      </div>
      <hr />
      {children}
    </div>
  </div>
)
