import * as React from 'react'
import DatePickerInput from '@components/controls/date-picker-input'
import { isAfter, startOfToday } from 'date-fns'
import { useFormContext, useWatch } from 'react-hook-form'
import { BookingOffersAdvancedFiltersFormInputs } from '@modules/booking-offers/filters/advanced-filters/booking-offers-advanced-filters-modal'
import { useDidUpdateEffect } from '@hooks/use-did-update-effect'

export const BookingOffersAdvancedFiltersDates = (): JSX.Element => {
  const { control, setValue } = useFormContext<BookingOffersAdvancedFiltersFormInputs>()
  const [dateFrom, dateTo] = useWatch({ control, name: ['date_from', 'date_to'] })

  useDidUpdateEffect(() => {
    if (dateFrom && dateTo && isAfter(dateFrom, dateTo)) setValue('date_to', dateFrom)
  }, [dateFrom])

  return (
    <div className="d-flex align-items-center mt-4 flex-wrap">
      <span className="font-xl fw-semi-bold col-sm-4">W przedziale czasowym:</span>
      <div className="col-sm-8 col-12 d-flex align-items-center mt-3 mt-sm-0">
        <DatePickerInput name="date_from" minDate={startOfToday()} inputGroupClassName="w-50" />
        <i className="uil-arrow-right text-primary font-xxxl mx-2" />
        <DatePickerInput name="date_to" minDate={dateFrom} inputGroupClassName="w-50" />
      </div>
    </div>
  )
}
