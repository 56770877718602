import * as React from 'react'
import * as clsx from 'clsx'
import { OfferSectionData } from '@modules/offers/offer-section-data'

type OfferKind =
  | 'gastro-card'
  | 'gastro-card-dynamic'
  | 'gastro-card-boost'
  | 'subscription'
  | 'subscription-contract'
  | 'vip'
  | 'go-holiday'
  | 'codes-conversion'

type PriceShapePosition = 'top' | 'bottom' | 'left-bottom'

interface BackgroundImagePrice {
  pre?: React.ReactNode
  price?: string
  after: string
  className?: string
  shapePosition: PriceShapePosition
}

interface Props {
  badges?: React.ReactNode
  description: React.ReactNode
  expirationDate?: string | null
  onOrderClick: () => void
  onDetailsClick?: () => void
  onImageClick?: () => void
  orderText?: string
  price?: BackgroundImagePrice
  rowClassName?: string
  title: React.ReactNode
  offerKind?: OfferKind
  children?: React.ReactNode
  backgroundVideo?: string
}

export const OfferSection = ({
  badges,
  description,
  expirationDate,
  onOrderClick,
  onDetailsClick,
  onImageClick,
  orderText,
  price,
  rowClassName,
  title,
  offerKind,
  children,
  backgroundVideo,
}: Props): JSX.Element => {
  return (
    <div className={clsx('row gx-5 mt-5 mt-lg-0 offers__row', rowClassName)}>
      <div className="col-12 col-lg-4 pt-lg-5 pt-4 pb-0 pb-lg-5 px-0">
        <div className="px-4 text-center text-sm-start mb-2 mb-sm-0">{badges}</div>
        <OfferSectionData
          title={title}
          description={description}
          onDetailsClick={onDetailsClick}
          onOrderClick={onOrderClick}
          expirationDate={expirationDate}
          orderText={orderText}
        />
      </div>
      <div className="col-12 col-lg-8 px-0 px-lg-4">
        <div
          className={clsx('offers__background-image', `is-${price?.shapePosition}`, `is-${offerKind}`, {
            'cursor-pointer': !!onImageClick,
            'shadow-none': backgroundVideo,
          })}
          onClick={onImageClick}
        >
          {backgroundVideo && (
            <video
              src={backgroundVideo}
              height={380}
              width="100%"
              autoPlay={true}
              loop={true}
              muted={true}
              className="object-fit-cover rounded"
              playsInline
              controlsList="nofullscreen"
            />
          )}

          {price && (
            <div className={clsx('offers__background-image__price', price.className)}>
              {price.pre}
              {price.price && <span className="offers__background-image__price__price fw-bold">{price.price}</span>}
              <span className="align-self-end">{price.after}</span>
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  )
}
