import * as React from 'react'
import { useAppSelector } from '@store/index'
import { selectAppData } from '@store/slices/app-slice'
import { Subscription } from '@models/app-state'
import { Accommodation } from '@modules/offers/subscription-with-benefit/details/variants/card/subscription-with-benefit-variants-card'
import { ClientVariant } from '@modules/offers/subscription-with-benefit/details/variants/subscription-with-benefit-variant-selection'

export interface GoHolidayVariantData {
  new_client: Subscription[]
  regular_client: Subscription[]
}

type Products = { [key in Accommodation]: GoHolidayVariantData }

export const GoHolidayProductPrefix = 'go_holiday__'

interface Response {
  products: Products
  getSelectedProduct: (
    offerAccommodationType: Accommodation | null,
    clientVariant: ClientVariant,
  ) => Subscription | null
}

export const useGoHolidayProducts = (): Response => {
  const { subscriptions } = useAppSelector(selectAppData)

  const products = React.useMemo(
    () =>
      subscriptions.reduce(
        (subscriptions, subscription) => {
          if (subscription.code.startsWith(GoHolidayProductPrefix)) {
            const [accommodation, clientVariant] = subscription.code
              .replace(GoHolidayProductPrefix, '')
              .toLowerCase()
              .split('__')

            return {
              ...subscriptions,
              ...(subscriptions[accommodation]
                ? {
                    [accommodation]: {
                      ...subscriptions[accommodation],
                      ...(subscriptions[accommodation][clientVariant]
                        ? {
                            [clientVariant]: [...subscriptions[accommodation][clientVariant], subscription],
                          }
                        : { [clientVariant]: [subscription] }),
                    },
                  }
                : { [accommodation]: { [clientVariant]: [subscription] } }),
            }
          }

          return subscriptions
        },
        { house: { new_client: [], regular_client: [] }, apartment: { new_client: [], regular_client: [] } },
      ) as Products,
    [subscriptions],
  )

  const getSelectedProduct = (accommodation: Accommodation | null, clientVariant: ClientVariant) => {
    if (!accommodation) return null
    return products[accommodation]?.[clientVariant][0]
  }

  return { products, getSelectedProduct }
}
