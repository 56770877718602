import * as React from 'react'
import { useFormRequest, useScrollToError } from '@hooks/use-request'
import { useAppSelector } from '@store/index'
import { selectClientDetails } from '@store/slices/app-slice'
import { useFormContext, useWatch } from 'react-hook-form'
import { PaymentBar } from '@components/payment-bar'
import { AvailableRuleKeyword } from '@models/app-state'
import { useUserCheck } from '@modules/offers/common/details/personal-data/use-user-check'

const BASIC_RULES_KEYS = [
  'privacy_policy_rules',
  'main_rules',
  'services_rules',
  'email',
  'first_name',
  'information_clause',
  'last_name',
]

export interface BaseFormFields {
  name: string
  email: string
  privacy_policy_rules: boolean
  main_rules: boolean
  sms_rules: boolean
  email_rules: boolean
  phone_call_rules: boolean
  services_rules: boolean
  information_clause: boolean
}

interface Props {
  rulesKeywords: AvailableRuleKeyword[]
  totalPrice: string
  paymentAction: (baseFields: BaseFormFields) => Promise<{ urls: { sale: string } }>
  canSubmit: boolean
}

export const OfferPaymentBar = ({ totalPrice, rulesKeywords, paymentAction, canSubmit }: Props): JSX.Element => {
  const clientDetails = useAppSelector(selectClientDetails)

  const { control, getValues, setError, clearErrors, formState } = useFormContext()

  useScrollToError(formState)

  const { checkEmail } = useUserCheck(setError)

  const requiredFields = useWatch({
    control,
    name: [...BASIC_RULES_KEYS, ...rulesKeywords],
  })

  const isSubmitDisabled = [canSubmit, ...requiredFields].some(field => !field)

  const { action: handleSubmit, isLoading } = useFormRequest(async () => {
    const {
      email,
      email_rules,
      sms_rules,
      phone_call_rules,
      information_clause,
      first_name,
      last_name,
      main_rules,
      services_rules,
      privacy_policy_rules,
      invoice,
      invoice_city,
      invoice_nip,
      invoice_company,
      invoice_type,
      invoice_postcode,
      invoice_street,
      create_account,
      new_account_password,
    } = getValues()

    const isEmailValid = await checkEmail(email)

    if (!isEmailValid) return

    const baseFields = {
      name: `${first_name} ${last_name}`,
      email,
      privacy_policy_rules,
      main_rules,
      services_rules,
      information_clause,
      sms_rules,
      email_rules,
      phone_call_rules,
      ...(create_account && {
        create_account,
        new_account_password,
      }),
      ...(invoice && {
        invoice,
        invoice_city,
        invoice_nip,
        invoice_type,
        invoice_company,
        invoice_postcode,
        invoice_street,
      }),
    }

    const response = await paymentAction(baseFields)

    document.location.href = response.urls.sale
  }, setError)

  React.useEffect(() => clearErrors(), [clientDetails])

  return (
    <PaymentBar
      price={totalPrice}
      isSubmitDisabled={isSubmitDisabled}
      onSubmit={handleSubmit}
      isSubmitting={isLoading}
    />
  )
}
