import * as React from 'react'
import { OfferDetailsAgreements } from '@modules/offers/common/details/personal-data/offer-details-agreements'
import { useGoHolidayProducts } from '@modules/offers/go-holiday/use-go-holiday-products'

export const GoHolidayAgreements = (): JSX.Element => {
  const { products } = useGoHolidayProducts()

  const product = products.house?.regular_client[0]
  return <OfferDetailsAgreements rulesUrl={product?.urls?.rules || ''} />
}
