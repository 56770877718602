import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { Checkbox } from '@components/controls/checkbox'
import { PasswordFormGroup } from '@components/controls/password-form-group'
import { useFormContext, useWatch } from 'react-hook-form'
import { Collapse } from 'reactstrap'

export const OfferDetailsPersonalDataAccountCreation = (): JSX.Element => {
  const { control } = useFormContext()

  const isAccountCreationSelected = useWatch({ control, name: 'create_account' })

  return (
    <div className="text-start">
      <Checkbox
        inputName="create_account"
        label={<strong className="text-darker-gray">Chcę utworzyć Konto Gościa</strong>}
      />
      <div style={{ marginLeft: '30px' }}>
        <p className="font-sm">Wystarczy, że ustawisz swoje hasło do konta.</p>
        <strong className="font-sm text-primary">Dlaczego warto?</strong>
        <div className="d-flex flex-column flex-sm-row flex-wrap mt-1">
          <IconWithText
            iconClassName="uil-check text-primary font-xl me-1 lh-1 align-self-start"
            text="Dostęp do pełnej historii rezerwacji"
            textClassName="font-sm"
            className="col-sm-6"
          />

          <IconWithText
            iconClassName="uil-check text-primary font-xl me-1 lh-1 align-self-start"
            text="Meldowanie i wymeldowanie on-line"
            textClassName="font-sm"
            className="col-sm-6"
          />

          <IconWithText
            iconClassName="uil-check text-primary font-xl me-1 lh-1 align-self-start"
            text="Kontrola rachunku podczas pobytu"
            textClassName="font-sm"
            className="col-sm-6"
          />

          <IconWithText
            iconClassName="uil-check text-primary font-xl me-1 lh-1 align-self-start"
            text="Zarządzanie produktami"
            textClassName="font-sm align-self-start"
            className="col-sm-6"
          />
          <Collapse isOpen={isAccountCreationSelected} className="col-12">
            <PasswordFormGroup
              wrapperClassName="col-lg-7"
              inputName="new_account_password"
              label="Ustaw hasło do konta:"
              formLabelProps={{ className: 'fw-semi-bold font-md' }}
            />
            <IconWithText
              text="Hasło powinno składać się z minimum 8 znaków"
              iconClassName="uil-info-circle me-2"
              textClassName="font-sm"
              className="mt-1 opacity-75"
            />
          </Collapse>
        </div>
      </div>
    </div>
  )
}
