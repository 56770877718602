import * as React from 'react'
import { OfferSection } from '@modules/offers/offer-section'
import { Badge } from '@components/badge'
import { useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { useScrollToOffer } from '@hooks/use-scroll-to-offer'
import { useDevicesSize } from '@hooks/use-devices-size'
import { useGastroCardDynamicCurrentOffers } from '@modules/offers/gastro-card/dynamic/details/use-gastro-dynamic-multipliers'

export const GastroCardDynamicOffer = (): React.ReactNode => {
  const { ref } = useScrollToOffer('podwojna-radosc')
  const { isMobile } = useDevicesSize()

  const navigate = useNavigate()

  const handleShowDetails = () => {
    navigate(NavigationPath.GastroCardDynamic)
  }

  const { offers } = useGastroCardDynamicCurrentOffers()

  if (!offers.length) return null

  return (
    <div className="container-lg" id="podwojna-radosc" ref={ref}>
      <OfferSection
        onImageClick={handleShowDetails}
        backgroundVideo={
          isMobile
            ? require('@assets/images/offers/gastro-card-dynamic/background-video-mobile.mp4')
            : require('@assets/images/offers/gastro-card-dynamic/background-video.mp4')
        }
        badges={
          <div className="d-flex">
            <Badge variant="red" text="Oferta dostępna czasowo" className="mb-2 me-3 badge-pulsing" />
            <Badge variant="gold" text="Dostępny tylko on-line" className="mb-2 me-3" />
          </div>
        }
        title={<span className="font-header">Fortuna Podwójnej Radości</span>}
        description={
          <span>
            Skorzystaj ze specjalnej promocji na <strong>Voucher Fortuna Podwójnej Radości</strong> do wykorzystania w
            Kawiarni, Bistro, Beach Barze i Słodkim Kąciku podczas pobytów w latach 2025-2026{' '}
            <span className="text-nowrap">w Resortach Holiday Park & Resort.</span>
          </span>
        }
        onOrderClick={handleShowDetails}
        orderText="Zobacz więcej"
        offerKind="gastro-card-dynamic"
        rowClassName="flex-row-reverse"
      />
    </div>
  )
}
