import * as React from 'react'
import { OfferModal } from '@modals/offer-modal/offer-modal'
import { BaseModalProps } from '@modals/types'
import { formatPrice } from '@helpers/price-helper'

export const WeekendStayOfferModal = ({ toggleIsVisible }: BaseModalProps): JSX.Element => {
  return (
    <OfferModal
      title="Pakiety weekendowe"
      toggleIsVisible={toggleIsVisible}
      price={formatPrice(200)}
      requiredRulesKeywords={[]}
      onSubmit={async () => {
        console.log('submit')
      }}
    >
      <div>SZCZEGÓŁY Pakietów weekendowych</div>
    </OfferModal>
  )
}
