import * as React from 'react'
import { OfferDetailsLayout } from '@layouts/offer-details-layout'
import { OfferDetailsBaseInfo } from '@modules/offers/common/details/offer-details-base-info'
import { FormProvider, useForm } from 'react-hook-form'
import { CodesConversionCostsInfo } from '@modules/offers/codes-conversion/details/codes-conversion-costs-info'
import { CodesConversionCodeSelection } from '@modules/offers/codes-conversion/details/codes-conversion-code-selection'
import { CustomReactSelectOption } from '@components/controls/custom-react-select'
import { ConvertableCode, ConvertableCodeAvailableConversion } from '@modules/offers/codes-conversion/models'
import { CodesConversionPersonalData } from '@modules/offers/codes-conversion/details/codes-conversion-personal-data'
import { CodesConversionPayment } from '@modules/offers/codes-conversion/details/codes-conversion-payment'
import { OfferRuleLink } from '@modules/offers/common/details/offer-rule-link'
import { IconWithText } from '@components/icon-with-text'

export interface CodesConversionFormInputs {
  variant: string
  localization: string
  code: CustomReactSelectOption | null | string
  selectedCode: ConvertableCode | null
  selectedConversionKind: ConvertableCodeAvailableConversion | null
  selectedConversionLocalization: ConvertableCodeAvailableConversion | null
  code_option_purchase_rules: boolean
}

export const CodesConversionView = (): JSX.Element => {
  const resortGalleryRef = React.useRef<HTMLDivElement>(null)

  const handleScrollToResortsGallery = () => {
    resortGalleryRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const methods = useForm<CodesConversionFormInputs>({
    defaultValues: {
      variant: '',
      localization: '',
      code: null,
      selectedCode: null,
      selectedConversionKind: null,
      selectedConversionLocalization: null,
    },
    mode: 'onChange',
  })

  return (
    <OfferDetailsLayout questions={FAQ_QUESTIONS} ref={resortGalleryRef} resortGalleryTitle="Gdzie wykorzystać kody?">
      <FormProvider {...methods}>
        <OfferDetailsBaseInfo
          images={SLIDER_IMAGES}
          benefits={BENEFITS}
          title="Opcja zmiany terminu wykorzystania lub konwersji kodu"
          description={
            <section>
              <p>
                Oferujemy możliwość <strong>zmiany terminu wykorzystania</strong> lub{' '}
                <strong>konwersji pojedynczego kodu</strong> z Twojego pakietu za dodatkową jednorazową opłatą. Zmień
                termin wykorzystania kodu poprzez jego zmianę do wyższej wersji kolorystycznej i przyjedź do nas w
                jeszcze dogodniejszym dla Ciebie terminie. Oprócz tego, możesz dokonać konwersji kodu i zmienić kod na
                uniwersalny, dzięki czemu wykorzystasz go w dowolnej lokalizacji nadmorskiej lub górskiej.
              </p>
            </section>
          }
        >
          <CodesConversionCostsInfo />
          <div className="d-flex flex-sm-row flex-column align-items-sm-center">
            <OfferRuleLink keyword="code_option_purchase_rules" title="Regulamin konwersji kodów" />
            <IconWithText
              text="Gdzie wykorzystasz ulepszony kod?"
              iconClassName="uil-map-marker font-xxxl me-2 lh-1"
              textClassName="lh-1"
              className="text-darker-gray fw-semi-bold ms-sm-5 mt-3 mt-sm-0"
              onClick={handleScrollToResortsGallery}
            />
          </div>
        </OfferDetailsBaseInfo>
        <CodesConversionCodeSelection />
        <CodesConversionPersonalData />
        <CodesConversionPayment />
      </FormProvider>
    </OfferDetailsLayout>
  )
}

const BENEFITS = [
  'Opcja zmiany koloru pojedynczego kodu',
  'Szerszy zakres terminów do wyboru',
  'Opcja konwersji kodu pod kątem lokalizacji',
  'Możliwość użycia kodu nawet w wakacje i w dowolnym ośrodku',
]

const SLIDER_IMAGES = [
  {
    original: require('@assets/images/offers/codes-conversion/gallery/children-slide.webp'),
    thumbnail: require('@assets/images/offers/codes-conversion/gallery/children-slide.webp'),
  },
  {
    original: require('@assets/images/offers/codes-conversion/gallery/machines.webp'),
    thumbnail: require('@assets/images/offers/codes-conversion/gallery/machines.webp'),
  },
  {
    original: require('@assets/images/offers/codes-conversion/gallery/playground.webp'),
    thumbnail: require('@assets/images/offers/codes-conversion/gallery/playground.webp'),
  },
  {
    original: require('@assets/images/offers/codes-conversion/gallery/sand-park.webp'),
    thumbnail: require('@assets/images/offers/codes-conversion/gallery/sand-park.webp'),
  },
  {
    original: require('@assets/images/offers/codes-conversion/gallery/terrace.webp'),
    thumbnail: require('@assets/images/offers/codes-conversion/gallery/terrace.webp'),
  },
  {
    original: require('@assets/images/offers/codes-conversion/gallery/water-park.webp'),
    thumbnail: require('@assets/images/offers/codes-conversion/gallery/water-park.webp'),
  },
]

const FAQ_QUESTIONS = [
  {
    question: 'W jaki sposób mogę dokonać zmiany terminu wykorzystania lub konwersji kodu?',
    answer:
      'Możesz zmienić termin wykorzystania kodu poprzez zmianę koloru swojego kodu (np. ulepszyć z brązowego na srebrny), a także dokonać konwersji kodu poprzez zmianę typu lokalizacji na uniwersalny. Możesz zakupić dwie opcje jednocześnie lub jedną z nich, dowolnie.',
  },
  {
    question: 'Jaki jest koszt zmiany terminu wykorzystania lub konwersji kodu?',
    answer: 'Koszty wykupienia opcji zmiany terminu wykorzystania lub konwersji kodu określone są w Cenniku.',
  },
  {
    question: 'Ile kodów mogę ulepszyć lub skonwertować?',
    answer:
      'Możesz ulepszyć (poprzez zmianę terminu wykorzystania) lub skonwertować każdy z posiadanych kodów, jeżeli objęte są one warunkami promocji.',
  },
  {
    question: 'Na czym polega konwersja kodu na uniwersalny?',
    answer:
      'Konwersja kodu na uniwersalny powoduje, że kod który uprzednio był przeznaczony dla lokalizacji tylko nadmorskich lub tylko górskich, można wykorzystać we wszystkich ośrodkach (w miarę dostępności terminów).',
  },
  {
    question: 'Ile mam czasu na wykorzystanie zmienionego lub skonwertowanego kodu?',
    answer:
      'Zmieniony/skonwertowany kod nie zmienia pierwotnej daty ważności. Obowiązuje Cię termin wskazany podczas zakupu pierwotnego pakietu.',
  },
  {
    question: 'Czy muszę dodatkowo aktywować swój kod?',
    answer: 'Twój kod jest już aktywny. Możesz go użyć od razu po zakupie opcji.',
  },
  {
    question: 'W jakich terminach mogę wykorzystać zmieniony kod?',
    answer: (
      <span>
        Terminy wykorzystania kodu różnią się w zależności od wybranej wersji kolorystycznej.
        <br />
        Dla Złotej wersji kolorystycznej: 26.08 - 7.07 każdego roku.
        <br />
        Dla Srebrnej wersji kolorystycznej: 1.09 - 30.06 (z wyłączeniem Terminów Specjalnych) każdego roku.
        <br />
      </span>
    ),
  },
]
