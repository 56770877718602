import axios from 'axios'
import { getState } from '@store/index'

export interface CompanyDataFromGus {
  tax_id: string
  city: string
  name: string
  postal_code: string
  street_address: string
}

export async function getCompanyData(nip: string): Promise<CompanyDataFromGus> {
  const url = getState().appState.appData.urls.sale.nip_search
  const { data } = await axios.get(url, { params: { nip } })
  return data
}
