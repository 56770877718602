import * as React from 'react'
import { BookingOffersAdvancedFiltersStayLengthButton } from '@modules/booking-offers/filters/advanced-filters/booking-offers-advanced-filters-stay-length-button'

export const BookingOffersAdvancedFiltersStayLength = (): JSX.Element => (
  <div className="d-flex mt-4 flex-wrap">
    <span className="font-xl fw-semi-bold col-sm-4 col-12 lh-1">
      Długość pobytu: <br />
      <small className="fw-light font-sm">max 4 doby</small>
    </span>
    <div className="col-sm-8 col-12 mt-3 mt-sm-0">
      <div className="row">
        <BookingOffersAdvancedFiltersStayLengthButton days={2} />
        <BookingOffersAdvancedFiltersStayLengthButton days={3} />
        <BookingOffersAdvancedFiltersStayLengthButton days={4} />
      </div>
    </div>
  </div>
)
