import * as React from 'react'
import { RangeInput } from '@components/controls/range-input'

interface Props {
  initialMin: number
  initialMax: number
  min: number
  max: number
}

export const BookingOffersAdvancedFiltersPrice = ({ initialMin, initialMax, min, max }: Props): JSX.Element => (
  <div className="d-flex flex-wrap my-5">
    <span className="font-xl fw-semi-bold col-sm-4 col-12">W przedziale cenowym:</span>
    <div className="col-sm-8 col-12">
      <RangeInput
        initialMaxValue={initialMax}
        initialMinValue={initialMin}
        min={min}
        max={max}
        maxInputName="price_brutto_max"
        minInputName="price_brutto_min"
      />
    </div>
  </div>
)
