import * as React from 'react'
import * as clsx from 'clsx'
import { CustomFormGroup } from '@components/controls/custom-form-group'
import useDebounce from '@rooks/use-debounce'
import { useFormRequest } from '@hooks/use-request'
import { useFormContext, useWatch } from 'react-hook-form'
import { CodesConversionCodeInputMessage } from '@modules/offers/codes-conversion/details/codes-conversion-code-input-message'
import { Collapse } from 'reactstrap'
import { commonObjectGet } from '@requests/basic-requests'
import { useAppSelector } from '@store/index'
import { selectAppData, selectClientDetails } from '@store/slices/app-slice'
import { CodesConversionFormInputs } from '@modules/offers/codes-conversion/details/index'
import { ConvertableCode } from '@modules/offers/codes-conversion/models'
import { CodesConversionCodeSelect } from '@modules/offers/codes-conversion/details/codes-conversion-code-select'

type InputChangeHandlerReturnType = (event: React.ChangeEvent<HTMLInputElement>) => void

interface Props {
  clientCodes: ConvertableCode[]
}

export const CodesConversionCodeInput = ({ clientCodes }: Props): JSX.Element => {
  const { urls } = useAppSelector(selectAppData)
  const clientDetails = useAppSelector(selectClientDetails)

  const { setValue, control, setError, clearErrors } = useFormContext<CodesConversionFormInputs>()
  const selectedCode = useWatch({ control, name: 'selectedCode' })

  const handleCodeChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const enteredCode = event.target.value
    clearErrors()
    setValue('code', enteredCode)

    if (!enteredCode) return
    await fetchAvailableConversions(enteredCode)
  }

  const { action: fetchAvailableConversions, isLoading } = useFormRequest(
    async (enteredCode: string) => {
      try {
        const selectedCode = await commonObjectGet<ConvertableCode>(`${urls.products.codes}${enteredCode}/`)
        setValue('selectedCode', selectedCode)
        setValue('selectedConversionLocalization', null)
        setValue('selectedConversionKind', null)
      } catch (error) {
        if (error.response.status === 404) setError('code', { message: 'Nie znaleziono kodu.' })
        setValue('selectedCode', null)
        throw error
      }
    },
    setError,
    { nonFieldErrorsAs: 'code' },
  )

  const debouncedInputChange = useDebounce(handleCodeChange, 500) as InputChangeHandlerReturnType

  return (
    <div className="position-relative">
      <div className="bg-light-gray rounded py-4 px-sm-5 px-3">
        <div className="d-flex align-items-start flex-wrap">
          {clientDetails && clientCodes.length ? (
            <CodesConversionCodeSelect
              clientCodes={clientCodes}
              onAvailableConversionFetch={fetchAvailableConversions}
            />
          ) : (
            <CustomFormGroup
              formLabelProps={{ className: 'fw-bold' }}
              inputName="code"
              formGroupProps={{ className: 'col-sm-3 col-12' }}
              isLoading={isLoading}
              formControlProps={{
                placeholder: '_________________',
                className: 'letter-spacing-2 ',
                onChange: debouncedInputChange,
              }}
            />
          )}

          <CodesConversionCodeInputMessage clientCodes={clientCodes} />
        </div>
      </div>
      <img
        src={require('@assets/images/icons/arrow.svg')}
        alt="Holiday Park ulepszanie kodów"
        className={clsx('ps-sm-5 ps-2 mt-sm-5 mt-5 pt-3 pt-sm-0', selectedCode ? 'opacity-0' : 'opacity-50')}
        style={{ position: 'absolute', top: '55px', left: '40px', transition: 'opacity 300ms linear' }}
      />
      <Collapse isOpen={!selectedCode}>
        <img
          src={require('@assets/images/hpr-girl.svg')}
          alt="Holiday Park ulepszanie kodów"
          className="ps-sm-5 mt-sm-3 mt-5 pt-3 pt-sm-0 opacity-50 ms-5"
          height={250}
        />
      </Collapse>
    </div>
  )
}
