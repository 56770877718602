import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { useAppSelector } from '@store/index'
import { selectAppData } from '@store/slices/app-slice'
import { AvailableRuleKeyword } from '@models/app-state'

interface Props {
  keyword: AvailableRuleKeyword
  title: string
}

export const OfferRuleLink = ({ keyword, title }: Props): JSX.Element => {
  const { rules } = useAppSelector(selectAppData)

  const ruleItem = rules.find(rule => rule.keyword === keyword)

  return (
    <IconWithText
      iconClassName="uil-file-check-alt font-xxxl me-2"
      className="text-darker-gray"
      text={
        <a href={ruleItem?.url} target="_blank" className="text-darker-gray text-decoration-none fw-semi-bold">
          {title}
        </a>
      }
    />
  )
}
