import * as React from 'react'
import { CircleProgress } from '@components/circle-progress'

interface Props {
  step: number
}

export const CircleStep = ({ step }: Props): JSX.Element => {
  const progress = step * 50

  return (
    <div className="d-flex align-items-center circle-step">
      <span className="fw-semi-bold me-2 font-lg lh-1">Krok</span>
      <span className="font-sm circle-step__current">{step}/2</span>
      <CircleProgress progress={progress} />
    </div>
  )
}
