import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { useAppSelector } from '@store/index'
import { selectClientDetails } from '@store/slices/app-slice'
import { SubscriptionWithBenefitManualBox } from '@modules/offers/subscription-with-benefit/details/personal-data/subscription-with-benefit-manual-box'
import { OfferDetailsPersonalData } from '@modules/offers/common/details/personal-data/offer-details-personal-data'
import { SubscriptionWithBenefitAgreements } from '@modules/offers/subscription-with-benefit/details/personal-data/subscription-with-benefit-agreements'

export const SubscriptionWithBenefitPersonalData = (): JSX.Element => {
  const client = useAppSelector(selectClientDetails)
  const { setValue } = useFormContext()

  React.useEffect(() => {
    if (client) {
      setValue('clientVariant', 'regular_client')
    }
  }, [client])

  return (
    <OfferDetailsPersonalData
      agreements={<SubscriptionWithBenefitAgreements />}
      title={
        <span>
          <strong className="d-block">Zamów</strong> swój pakiet {client && `${client?.first_name}!`}
        </span>
      }
    >
      <SubscriptionWithBenefitManualBox />
    </OfferDetailsPersonalData>
  )
}
