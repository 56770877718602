import * as React from 'react'
import * as clsx from 'clsx'
import { useFormContext } from 'react-hook-form'
import {
  createSelectOption,
  CustomReactSelect,
  CustomReactSelectOption,
} from '@components/controls/custom-react-select'
import { components } from 'react-select'
import { MenuProps } from 'react-select/dist/declarations/src/components/Menu'
import { CodesConversionFormInputs } from '@modules/offers/codes-conversion/details/index'
import { ConvertableCode } from '@modules/offers/codes-conversion/models'

interface Props {
  clientCodes: ConvertableCode[]
  onAvailableConversionFetch: (code: string) => void
}

export const CodesConversionCodeSelect = ({ clientCodes, onAvailableConversionFetch }: Props): JSX.Element => {
  const { setValue, clearErrors } = useFormContext<CodesConversionFormInputs>()

  const clientCodesOptions = clientCodes.map(clientCode => createSelectOption(clientCode.code, clientCode.code))

  const handleCodeSelectionChange = async (selectedOption: CustomReactSelectOption<string, string> | null) => {
    clearErrors()
    setValue('code', selectedOption)

    if (!selectedOption) {
      setValue('selectedCode', null)
      return
    }

    onAvailableConversionFetch(selectedOption.value)
  }

  return (
    <div className="col-12 col-sm-auto">
      <CustomReactSelect
        inputName="code"
        isClearable={true}
        isCreatable={true}
        options={clientCodesOptions}
        selectStyles={selectStyles}
        placeholder="_________________"
        onChange={handleCodeSelectionChange}
        components={selectComponents}
        formatCreateLabel={userInput => `Wybierz własny: ${userInput.toUpperCase()}`}
      />
    </div>
  )
}

export const Menu = (props: MenuProps): React.ReactNode => {
  const optionsAmount = props.selectProps.options.length

  if (optionsAmount === 0) return null

  return (
    <components.Menu {...props} className={clsx(props.className, { 'shadow-none': !optionsAmount })}>
      {props.children}
    </components.Menu>
  )
}

const selectComponents = { IndicatorSeparator: () => null, Menu }

const selectStyles = {
  container: provided => ({
    ...provided,
    minWidth: '230px',
    '@media only screen and (max-width: 576px)': {
      ...provided['@media only screen and (max-width: 576px)'],
      width: '100%',
    },
  }),
  singleValue: provided => ({ ...provided, border: '1px solid #eff3f7', letterSpacing: '2px' }),
  control: provided => ({
    ...provided,
    backgroundColor: '#eff3f7',
    height: '42px',
    letterSpacing: '2px',
    border: '1px solid #adadad',
    cursor: 'pointer',
    boxShadow: 'none',
    '&:hover': { borderColor: '#adadad' },
  }),
  menu: provided => ({ ...provided, boxShadow: 'none', zIndex: 2 }),
  option: provided => ({
    ...provided,
    borderRadius: '4px',
    border: '1px solid #eff3f7',
    '&:hover': { cursor: 'pointer' },
  }),
  clearIndicator: provided => ({
    ...provided,
    paddingLeft: 0,
    paddingRight: 0,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    paddingLeft: 0,
  }),
}
