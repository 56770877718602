import * as React from 'react'
import { useSelector } from 'react-redux'
import { selectClientDetails } from '@store/slices/app-slice'
import { hasActiveSubscriptionContract } from '@modules/offers/gastro-card-boost/utils'

interface Props {
  noClientMessage: React.ReactNode
  noActiveSubscriptionMessage: React.ReactNode
  activeSubscriptionMessage: React.ReactNode
}

export const GastroCardBaseSubscriptionBonusesInformation = ({
  noActiveSubscriptionMessage,
  noClientMessage,
  activeSubscriptionMessage,
}: Props): JSX.Element => {
  const client = useSelector(selectClientDetails)

  const hasActiveSubscription = hasActiveSubscriptionContract(client)

  return (
    <div className="d-flex justify-content-center flex-column align-items-center">
      {!client && <span className="badge-success font-xxs">Masz Subskrypcję?</span>}
      {client && hasActiveSubscription && <span className="badge-success font-xxs">Subskrypcja aktywna!</span>}
      {client && !hasActiveSubscription && <span className="badge-warning font-xxs">Brak Subskrypcji</span>}

      <span className="font-xs d-block mt-2 text-center">
        {!client && noClientMessage}
        {client && hasActiveSubscription && activeSubscriptionMessage}
        {client && !hasActiveSubscription && noActiveSubscriptionMessage}
      </span>
    </div>
  )
}
