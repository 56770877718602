import { createCartItem, useGtmEvents } from '@hooks/use-gtm-events'
import { useSubscriptionContractPlans } from '@modules/offers/subscription-contract/details/use-subscription-contract-product'
import { SubscriptionContractPlan, SubscriptionContractPlanKind } from '@models/app-state'

interface Response {
  sendViewItemListEvent: () => void
  sendBeginCheckoutEvent: (plan: SubscriptionContractPlan, planKind: SubscriptionContractPlanKind) => void
  sendAddToCartEvent: (plan: SubscriptionContractPlan, planKind: SubscriptionContractPlanKind) => void
}

export const useSubscriptionContractsGtmEvents = (): Response => {
  const { viewItemList, addToCart, beginCheckout } = useGtmEvents()

  const subscriptionContractPlans = useSubscriptionContractPlans()
  const listOfSubscriptionPlans = Object.entries(subscriptionContractPlans)

  const sendViewItemListEvent = () => {
    viewItemList(
      listOfSubscriptionPlans.map(([plan, data], index) =>
        createSubscriptionContractEcommerceItem(data, plan as SubscriptionContractPlanKind, index),
      ),
      'subskrypcja',
    )
  }

  const sendAddToCartEvent = (plan: SubscriptionContractPlan, planKind: SubscriptionContractPlanKind) => {
    addToCart(plan.single_price_brutto, createSubscriptionContractEcommerceItem(plan, planKind))
  }

  const sendBeginCheckoutEvent = (plan: SubscriptionContractPlan, planKind: SubscriptionContractPlanKind) => {
    beginCheckout(plan.single_price_brutto, [createSubscriptionContractEcommerceItem(plan, planKind)])
  }

  return {
    sendViewItemListEvent,
    sendAddToCartEvent,
    sendBeginCheckoutEvent,
  }
}

const createSubscriptionContractEcommerceItem = (
  plan: SubscriptionContractPlan,
  planKind: SubscriptionContractPlanKind,
  index?: number,
) =>
  createCartItem({
    index,
    name: plan.name,
    id: planKind,
    price: plan.single_price_brutto,
    itemListName: 'subskrypcja',
  })
