import * as React from 'react'
import { TrapezoidButton } from '@components/controls/buttons/trapezoid-button'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  onClose: () => void
  onStepChange: () => void
}

export const OfferModalPaymentDetailsActions = ({ onClose, onStepChange }: Props): JSX.Element => (
  <>
    <IconWithText
      text="Anuluj"
      iconClassName="uil-times font-xxl lh-initial me-1"
      textClassName="fw-semi-bold"
      className="cursor-pointer"
      onClick={onClose}
    />
    <TrapezoidButton onClick={onStepChange} variant="danger">
      <IconWithText text="Szczegóły płatności" iconClassName="uil-shopping-bag font-xxxl me-2 lh-initial" />
    </TrapezoidButton>
  </>
)
