import * as React from 'react'
import { useAppSelector } from '@store/index'
import { selectClientDetails } from '@store/slices/app-slice'
import { OfferDetailsPersonalData } from '@modules/offers/common/details/personal-data/offer-details-personal-data'
import { SubscriptionContractAgreements } from '@modules/offers/subscription-contract/details/subscription-contract-agreements'
import { SubscriptionContractManualBox } from '@modules/offers/subscription-contract/details/subscription-contract-manual-box'

export const SubscriptionContractPersonalData = (): JSX.Element => {
  const client = useAppSelector(selectClientDetails)

  return (
    <OfferDetailsPersonalData
      agreements={<SubscriptionContractAgreements />}
      title={
        <span className="text-secondary">
          <strong className="d-block">Zamów</strong> subskrypcję {client && `${client?.first_name}!`}
        </span>
      }
    >
      <SubscriptionContractManualBox />
    </OfferDetailsPersonalData>
  )
}
