import {
  differenceInDays,
  format,
  formatDistanceStrict,
  formatDuration,
  getYear,
  intervalToDuration,
  isAfter,
  parseISO,
} from 'date-fns'
import { pl } from 'date-fns/locale'

export enum DateFormats {
  DAY = 'dd',
  DATE_WITH_DAY = 'dd.MM.yyyy, EEEE',
  SHORT_DAY_FULL_MONTH = 'dd MMMM',
  FULL_MONTH_FULL_YEAR = 'MMMM yyyy',
  MONTH_WITH_FULL_WORD_MONTH_WITH_YEAR = 'dd MMMM yyyy',
  DAY_MONTH_YEAR_SEPARATED_BY_DOT = 'dd.MM.yyyy',
}

export const formatDate = (date: Date | undefined | string | null, dateFormat = 'yyyy-MM-dd'): string => {
  if (!date) {
    return ''
  }
  if (typeof date === 'string') {
    return format(parseISO(date), dateFormat, { locale: pl })
  }
  if (typeof date !== 'undefined') {
    return format(date, dateFormat, { locale: pl })
  }
  return date ? date : ''
}

export const parseISODate = (value: string | Date): Date => {
  if (typeof value === 'string') {
    return parseISO(value)
  }
  return value
}

export const getRangeInDays = (startData: Date | string, endDate: Date | string): string => {
  let parsedStart = startData
  let parsedEnd = endDate
  if (!parsedStart || !parsedEnd) {
    return ''
  }

  if (typeof parsedStart === 'string') {
    parsedStart = parseISO(parsedStart)
  }

  if (typeof parsedEnd === 'string') {
    parsedEnd = parseISO(parsedEnd)
  }

  return formatDistanceStrict(parsedStart, parsedEnd, {
    locale: pl,
    unit: 'day',
    roundingMethod: 'ceil',
  })
}

export const getHoursFromDate = (date: Date | string): string => formatDate(date, 'HH:mm')

export const toDefaultDateFormat = (value: string | Date): string => {
  return formatDate(parseISODate(value), DateFormats.DAY_MONTH_YEAR_SEPARATED_BY_DOT)
}

export const getDifferenceInDays = (startingDate: string, endingDate: string): number =>
  differenceInDays(parseISO(endingDate), parseISO(startingDate))

export const formatRemainingTime = (
  date: string | Date,
  formatter?: ({ years, months, days, hours, minutes, seconds }) => string,
): string => {
  const isoDate = date instanceof Date ? date : parseISODate(date)

  const currentDate = new Date()

  if (isAfter(currentDate, isoDate)) {
    return '0m'
  }

  const duration = intervalToDuration({ start: currentDate, end: isoDate })

  const hours = duration.hours ? `${duration.hours}h` : ''
  const days = duration.days ? (duration.days > 1 ? `${duration.days} dni` : `${duration.days} dzień`) : ''

  if (formatter)
    return formatter({
      years: duration.years,
      months: duration.months,
      days: duration.days,
      hours: duration.hours,
      minutes: duration.minutes,
      seconds: duration.seconds,
    })

  const units = ['years', 'months']
  const availableUnits = Object.entries(duration)
    .filter(el => el[1])
    .map(el => el[0])

  const yearsMonths = formatDuration(duration, {
    format: units.filter(i => new Set(availableUnits).has(i)),
    locale: pl,
  })

  return `${yearsMonths} ${days} ${hours} ${duration.minutes}m ${duration.seconds}s`
}

export const getUpcomingYearsWithCurrent = (number: number): number[] => {
  const now = new Date()
  const currentYear = getYear(now)
  return [...Array(number + 1).keys()].map(year => currentYear + year)
}
