import * as React from 'react'
import { ClientVariantFooter } from '@modules/offers/common/client-variant-footer'
import * as clsx from 'clsx'
import { Accommodation } from '@modules/offers/subscription-with-benefit/details/variants/card/subscription-with-benefit-variants-card'

interface Props {
  kind: Accommodation
  image: string
  isSelected: boolean
  isAnotherSelected: boolean
  onSelectionChange: () => void
  regularClientPrice: string
  newClientPrice: string
}

export const GoHolidayAccommodationVariantCard = ({
  isSelected,
  isAnotherSelected,
  onSelectionChange,
  image,
  regularClientPrice,
  newClientPrice,
}: Props): JSX.Element => (
  <div className="col-12 mb-4 mb-sm-0">
    <div
      className={clsx('go-holiday__card card', {
        'is-card-selected': isSelected,
        'is-another-card-selected': isAnotherSelected,
      })}
    >
      <div className="go-holiday__card__title p-4">
        <span>Oferta dla Ciebie</span>
        <strong className="d-block">GO!Holiday</strong>
      </div>
      <ClientVariantFooter
        tooltipTarget="go-holiday-apartment"
        regularClientPrice={regularClientPrice}
        newClientPrice={newClientPrice}
        isSelected={isSelected}
        onSelectionChange={onSelectionChange}
      />
      <img src={image} alt="Holiday Park & Resort" height={200} width="50%" className="go-holiday__card__image" />
    </div>
  </div>
)
