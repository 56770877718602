import * as React from 'react'
import * as clsx from 'clsx'
import { useFormContext } from 'react-hook-form'
import { Input, InputProps, Label } from 'reactstrap'
import { extractInnerRef } from '@helpers/forms'

interface Props extends InputProps {
  wrapperClassName?: string
  name: string
}

export const CircleOutlineRadioButton = ({ name, wrapperClassName, label, ...props }: Props): JSX.Element => {
  const { register } = useFormContext()

  return (
    <div className={clsx('position-relative', wrapperClassName)}>
      <div className="position-relative cursor-pointer circle-outline-radio-button me-2">
        <Input {...props} {...extractInnerRef(register(name))} type="radio" id={props.id} className={props.className} />
        <i className="uil-check circle-outline-radio-button__check-icon" />
        <Label for={props.id} check>
          {label}
        </Label>
      </div>
    </div>
  )
}
