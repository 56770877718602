import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import {
  Accommodation,
  SubscriptionWithBenefitVariant,
} from '@modules/offers/subscription-with-benefit/details/variants/card/subscription-with-benefit-variants-card'
import { Subscription } from '@models/app-state'
import { SubscriptionWithBenefitVariantData } from '@modules/offers/subscription-with-benefit/details/use-subscription-with-benefits'
import { ClientVariantFooter } from '@modules/offers/common/client-variant-footer'
import { useSubscriptionWithBenefitsGtmEvents } from '@modules/offers/subscription-with-benefit/details/use-subscription-with-benefits-gtm-events'

interface Props {
  variant: SubscriptionWithBenefitVariant
  variantData: SubscriptionWithBenefitVariantData
}

export const SubscriptionWithBenefitVariantCardFooter = ({ variant, variantData }: Props): JSX.Element => {
  const { sendAddToCartEvent } = useSubscriptionWithBenefitsGtmEvents()
  const { control, setValue } = useFormContext()

  const [selectedOfferVariant, selectedAccommodationVariant, clientVariant] = useWatch({
    control,
    name: ['offerVariant', 'offerVariantAccommodation', 'clientVariant'],
  })

  const isSelected = selectedOfferVariant === variant

  const toggleVariantSelection = () => {
    handleVariantChange()
  }

  const handleVariantChange = (accommodation?: Accommodation) => {
    setValue('offerVariant', variant)

    if (!selectedAccommodationVariant) {
      setValue('offerVariantAccommodation', accommodation ?? 'apartment')
    }

    sendAddToCartEvent(variantData[selectedAccommodationVariant][clientVariant][0])
  }

  const subscriptionWithBenefit = {
    regularClient: variantData[selectedAccommodationVariant].regular_client[0] as Subscription,
    newClient: variantData[selectedAccommodationVariant].new_client[0] as Subscription,
  }

  const tooltipTarget = `regular-client-info-${variant}`

  return (
    <ClientVariantFooter
      tooltipTarget={tooltipTarget}
      isSelected={isSelected}
      onSelectionChange={toggleVariantSelection}
      newClientPrice={subscriptionWithBenefit.newClient.subscription_product.price_brutto}
      regularClientPrice={subscriptionWithBenefit.regularClient.subscription_product.price_brutto}
    />
  )
}
