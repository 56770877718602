import * as React from 'react'
import * as clsx from 'clsx'
import { SubscriptionWithBenefitDiscountsList } from '@modules/offers/subscription-with-benefit/details/variants/subscription-with-benefit-discounts-list'
import { useFormContext, useWatch } from 'react-hook-form'
import { SubscriptionWithBenefitVariantData } from '@modules/offers/subscription-with-benefit/details/use-subscription-with-benefits'
import { SubscriptionWithBenefitVariantCardHeader } from '@modules/offers/subscription-with-benefit/details/variants/card/subscription-with-benefit-variants-card-header'
import { SubscriptionWithBenefitVariantCardFooter } from '@modules/offers/subscription-with-benefit/details/variants/card/subscription-with-benefit-variants-card-footer'
import { ConfettiRef, ConfettiWrapper } from '@components/confetti-wrapper'
import { useSubscriptionWithBenefitsGtmEvents } from '@modules/offers/subscription-with-benefit/details/use-subscription-with-benefits-gtm-events'
import { useDidUpdateEffect } from '@hooks/use-did-update-effect'

export type Accommodation = 'house' | 'apartment'
export type SubscriptionWithBenefitVariant = 'optimum' | 'comfort' | 'lux'

interface Props {
  variant: SubscriptionWithBenefitVariant
  variantData: SubscriptionWithBenefitVariantData
}

export const SubscriptionWithBenefitVariantCard = ({ variant, variantData }: Props): JSX.Element => {
  const { sendAddToCartEvent } = useSubscriptionWithBenefitsGtmEvents()
  const { control, setValue } = useFormContext()
  const confettiRef = React.useRef<ConfettiRef>(null)

  const [selectedOfferVariant, selectedAccommodationVariant, clientVariant] = useWatch({
    control,
    name: ['offerVariant', 'offerVariantAccommodation', 'clientVariant'],
  })

  const isSelected = selectedOfferVariant === variant

  const handleVariantChange = (event?: React.MouseEvent) => {
    event?.preventDefault()

    setValue('offerVariant', variant)
    confettiRef.current?.play()
    sendAddToCartEvent(variantData[selectedAccommodationVariant][clientVariant][0])
  }

  useDidUpdateEffect(() => {
    if (selectedOfferVariant === variant) {
      sendAddToCartEvent(variantData[selectedAccommodationVariant][clientVariant][0])
    }
  }, [selectedAccommodationVariant])

  return (
    <div className="col-lg-4 col-12 mb-5 mb-lg-0 position-relative">
      <ConfettiWrapper ref={confettiRef}>
        <div
          onClick={handleVariantChange}
          className={clsx('subscription-with-benefit__variant-card card cursor-pointer', {
            'is-optimum': variant === 'optimum',
            'is-comfort': variant === 'comfort',
            'is-lux': variant === 'lux',
            'is-selected': isSelected,
            'is-another-selected': !!selectedOfferVariant && !isSelected,
          })}
        >
          <div className="px-4 pt-4">
            <SubscriptionWithBenefitVariantCardHeader variant={variant} />
            <SubscriptionWithBenefitDiscountsList variant={variant} accommodation={selectedAccommodationVariant} />
          </div>
          <SubscriptionWithBenefitVariantCardFooter variant={variant} variantData={variantData} />
        </div>
      </ConfettiWrapper>
    </div>
  )
}
