import * as React from 'react'
import { AppHeader } from '@components/header/app-header'
import { AppFooterSection } from '@components/footer/app-footer-sections'
import { Layout } from './layout'
import { StatisticsCounts } from '@components/footer/statistics-counts'
import { AppDownloadBanner } from '@components/footer/app-download-banner'

interface Props {
  children: React.ReactNode
}

export const HomeLayout = ({ children }: Props): JSX.Element => (
  <Layout>
    <AppHeader />
    {children}
    <AppFooterSection>
      <div className="bg-white p-xl-5 card border-0">
        <div className="container-xl">
          <StatisticsCounts />
          <AppDownloadBanner />
        </div>
      </div>
    </AppFooterSection>
  </Layout>
)
