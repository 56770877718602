import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import clsx from 'clsx'

interface Props {
  message: string
  className?: string
}

export const AlertMessage = ({ message, className }: Props): JSX.Element => (
  <div className={clsx('alert alert-light custom-alert bg-light-azure mt-3 py-2', className)}>
    <IconWithText
      text={message}
      textClassName="font-sm text-darker-gray"
      iconClassName="uil-exclamation-circle text-primary font-xl align-self-start me-3 lh-0"
    />
  </div>
)
