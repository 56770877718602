import * as React from 'react'
import { useInViewRef } from 'rooks'
import { Subscription } from '@models/app-state'
import { useDidUpdateEffect } from '@hooks/use-did-update-effect'
import { useFormContext, useWatch } from 'react-hook-form'
import { useAppSelector } from '@store/index'
import { selectClientDetails } from '@store/slices/app-slice'

interface Props {
  children: React.ReactNode
  selectedProduct: Subscription | null
  gtmEventHook: () => { sendBeginCheckoutEvent: (product: Subscription) => void }
}

export const SubscriptionProductPaymentWrapper = ({ children, selectedProduct, gtmEventHook }: Props): JSX.Element => {
  const clientDetails = useAppSelector(selectClientDetails)
  const [checkoutBegins, setCheckoutBegins] = React.useState(false)

  const { sendBeginCheckoutEvent } = gtmEventHook()

  const [ref, inView] = useInViewRef({ rootMargin: '0% 0% 20% 0%', threshold: 0.2 })

  const { control } = useFormContext()
  const clientFormData = useWatch({ control, name: ['first_name', 'last_name', 'email', 'username', 'password'] })

  useDidUpdateEffect(() => {
    if (clientDetails) return

    if (clientFormData.some(value => !!value) && selectedProduct && !checkoutBegins) {
      sendBeginCheckoutEvent(selectedProduct)
      setCheckoutBegins(true)
    }
  }, [clientFormData])

  useDidUpdateEffect(() => {
    setCheckoutBegins(false)
  }, [selectedProduct])

  React.useEffect(() => {
    if (!selectedProduct || !clientDetails) return

    if (inView && !checkoutBegins) {
      sendBeginCheckoutEvent(selectedProduct)
      setCheckoutBegins(true)
    }
  }, [inView])

  return <div ref={ref}>{children}</div>
}
