import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { ModalConfig, ModalParams, ModalTypes } from '@modals/types'
import type { RootState } from '@store/index'

type ModalIsOpenAction = PayloadAction<{
  modal: ModalTypes
  isOpen: boolean
}>

interface ModalItem {
  modal: ModalTypes
  params: ModalParams
  isOpen: boolean
  config: ModalConfig | undefined
}

const modalAdapter = createEntityAdapter<ModalItem, ModalTypes>({ selectId: (modalItem: ModalItem) => modalItem.modal })

const initialState: EntityState<ModalItem, ModalTypes> = modalAdapter.getInitialState()

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setShowModal(state, action: PayloadAction<ModalItem>) {
      modalAdapter.addOne(state, action.payload)
    },
    setHideModal(state, action: PayloadAction<{ modal: ModalTypes }>) {
      modalAdapter.removeOne(state, action.payload.modal)
    },
    setModalIsOpen(state, action: ModalIsOpenAction) {
      const modal = modalAdapter.getSelectors().selectById(state, action.payload.modal)
      if (modal) {
        modalAdapter.upsertOne(state, { ...modal, isOpen: action.payload.isOpen })
      }
    },
  },
})

export const { setModalIsOpen, setHideModal, setShowModal } = modalSlice.actions
export const { selectAll: selectAllModals, selectById: selectModalById } = modalAdapter.getSelectors(
  (state: RootState) => state.modalState,
)

export default modalSlice.reducer
