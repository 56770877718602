import * as React from 'react'
import * as clsx from 'clsx'
import { CustomFormGroup } from '@components/controls/custom-form-group'
import { Button } from 'reactstrap'
import { useFormContext, useWatch } from 'react-hook-form'
import { useFormRequest } from '@hooks/use-request'
import { TextWithSpinner } from '@components/loaders/text-with-spinner'
import { useSelector } from 'react-redux'
import { selectAppData } from '@store/slices/app-slice'
import { commonObjectGet } from '@requests/basic-requests'
import {
  createCompanyFormDataFromNipSearch,
  getFormInputLabelsForType,
  NipSearchResponse,
} from '@components/invoice-form/utils'
import { FormError } from '@components/controls/form-error'
import { CircleOutlineRadioButton } from '@components/controls/circle-outline-radio-button'
import { useDidUpdateEffect } from '@hooks/use-did-update-effect'

interface Props {
  wrapperClassName?: string
}

export const InvoiceForm = ({ wrapperClassName }: Props): JSX.Element => {
  const { urls } = useSelector(selectAppData)
  const { setError, getValues, setValue, control } = useFormContext()

  const invoiceType = useWatch({ control, name: 'invoice_type' })
  const isCompanyInvoiceType = invoiceType === 'company'

  React.useEffect(() => {
    setValue('invoice_type', 'company')
  }, [])

  useDidUpdateEffect(() => {
    ;['invoice_nip', 'invoice_company', 'invoice_postcode', 'invoice_street', 'invoice_city'].forEach(inputName =>
      setValue(inputName, ''),
    )
  }, [invoiceType])

  const { action: fetchCompanyData, isLoading: isFetchingCompanyData } = useFormRequest(async () => {
    const searchResponse = await commonObjectGet<NipSearchResponse>(urls.sale.nip_search, {
      params: { nip: getValues('invoice_nip') },
    })

    Object.entries(createCompanyFormDataFromNipSearch(searchResponse)).forEach(([key, value]) => setValue(key, value))
  }, setError)

  const formLabels = getFormInputLabelsForType(invoiceType || 'company')

  return (
    <div className={clsx('my-3', wrapperClassName)}>
      <div className="row align-items-center">
        <div className="col-5 d-none d-md-block">
          <strong className="text-primary font-xl">Dane do faktury</strong>
        </div>
        <div className="d-sm-flex mt-2 col-sm-7">
          <CircleOutlineRadioButton
            label={<span className="fw-semibold">Faktura na firmę</span>}
            id="invoice_type_company"
            name="invoice_type"
            value="company"
            wrapperClassName="col-6 mt-lg-0 mt-2"
            checked={isCompanyInvoiceType}
          />
          <CircleOutlineRadioButton
            label={<span className="fw-semibold">Faktura na osobę indywidualną</span>}
            id="invoice_type_individual"
            name="invoice_type"
            value="individual"
            wrapperClassName="col-6 my-3 my-sm-0"
            checked={!isCompanyInvoiceType}
          />
        </div>
      </div>

      <div className="d-flex flex-wrap align-items-center mt-sm-2">
        <label className="col-sm-5 col-12 font-md fw-semi-bold">{formLabels.nip}</label>
        <div className="col-sm-7 col-12 row g-0">
          <CustomFormGroup
            inputName="invoice_nip"
            formGroupProps={{ className: `${clsx('pe-md-2', isCompanyInvoiceType ? 'col-md-7' : 'col-12')}` }}
            formControlProps={{
              placeholder: formLabels.nip,
            }}
          />
          {isCompanyInvoiceType && (
            <Button onClick={fetchCompanyData} className="col-md-5 d-block btn-tall mt-2">
              <TextWithSpinner
                isLoading={isFetchingCompanyData}
                loadingText="Pobieranie..."
                text="Pobierz dane z GUS"
              />
            </Button>
          )}
          <FormError inputName="nip" />
        </div>
      </div>

      <div className="d-flex text-darker-gray mt-3 font-sm">
        <span className="col-xl-7 ms-md-auto">{formLabels.header}</span>
      </div>

      <CustomFormGroup
        inputName="invoice_company"
        formControlProps={{ placeholder: formLabels.name }}
        label={formLabels.name}
        {...formGroupLayout}
      />
      <CustomFormGroup
        inputName="invoice_street"
        label={formLabels.address}
        formControlProps={{ placeholder: 'Ulica i numer lokalu' }}
        {...formGroupLayout}
      />
      <div className="col-sm-7 ms-auto row g-0">
        <CustomFormGroup
          inputName="invoice_postcode"
          formGroupProps={{ className: 'col-4 pe-2' }}
          formControlProps={{ placeholder: '__-___' }}
        />
        <CustomFormGroup
          inputName="invoice_city"
          formGroupProps={{ className: 'col-8' }}
          formControlProps={{ placeholder: 'Miejscowość' }}
        />
      </div>
    </div>
  )
}

const formGroupLayout = {
  formLabelProps: { className: 'col-sm-5 col-12 font-md fw-semi-bold' },
  formControlWrapperClassName: 'col-sm-5 col-12',
  formGroupProps: { className: 'd-flex align-items-center flex-sm-row flex-column' },
}
