import * as React from 'react'
import { SubscriptionWithBenefitOfferVariants } from '@modules/offers/subscription-with-benefit/details/variants/subscription-with-benefit-offer-variants'
import { SubscriptionWithBenefitAccommodationVariants } from '@modules/offers/subscription-with-benefit/details/variants/subscription-with-benefit-accommodation-variants'

export type ClientVariant = 'new_client' | 'regular_client'

export const SubscriptionWithBenefitVariantSelection = (): React.ReactNode => (
  <div className="container-xl mx-auto subscription-with-benefit__variants align-items-center">
    <SubscriptionWithBenefitAccommodationVariants />
    <SubscriptionWithBenefitOfferVariants />
  </div>
)
