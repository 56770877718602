import * as React from 'react'
import { OfferModal } from '@modals/offer-modal/offer-modal'
import { BaseModalProps } from '@modals/types'
import { formatPrice } from '@helpers/price-helper'
import { useSelector } from 'react-redux'
import { selectAppData } from '@store/slices/app-slice'
import { Subscription } from '@models/app-state'
import { commonObjectPost } from '@requests/basic-requests'

interface SaleResponse {
  urls: { payment: string; sale: string }
}

interface Props extends BaseModalProps {
  subscription: Subscription
}

export const SubscriptionOfferModal = ({ toggleIsVisible, subscription }: Props): JSX.Element => {
  const { subscription_rules } = useSelector(selectAppData)

  const subscriptionRulesKeywords = React.useMemo(
    () => subscription_rules.reduce((acc, cur) => (cur.required ? [...acc, cur.keyword] : acc), []),
    [subscription_rules],
  )

  const handleSubmit = async payload => {
    const response = await commonObjectPost<SaleResponse>(subscription.urls.sale, payload)
    window.location.href = response.urls.sale
  }

  return (
    <OfferModal
      title={subscription.name}
      toggleIsVisible={toggleIsVisible}
      price={formatPrice(subscription.subscription_product.price_brutto)}
      requiredRulesKeywords={subscriptionRulesKeywords}
      onSubmit={handleSubmit}
    >
      <div dangerouslySetInnerHTML={{ __html: subscription.description }} />
    </OfferModal>
  )
}
