import * as React from 'react'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { IconWithText } from '@components/icon-with-text'
import { useFormContext, useWatch } from 'react-hook-form'
import { BookingOffersFiltersParams } from '@modules/booking-offers'

export const BookingOffersFilterSort = (): JSX.Element => {
  const { setValue, control } = useFormContext<BookingOffersFiltersParams>()

  const activeSorting = useWatch({ control, name: 'ordering' })
  const activeSortOption = SortOption.find(option => option.value === activeSorting)

  const handleSortingChange = (sortValue: string) => {
    setValue('ordering', sortValue)
  }

  const isActive = (sortOptionKey: string) => activeSortOption?.key === sortOptionKey

  return (
    <UncontrolledDropdown setActiveFromChild className="order-3 ms-auto ms-md-0 mt-4 mt-sm-0">
      <DropdownToggle className="bg-transparent border-0 text-darker-gray">
        <IconWithText
          text={
            <div className="text-darker-gray">
              Sortuj: <span className="fw-semi-bold">{activeSortOption?.name}</span>
            </div>
          }
          iconClassName="uil-angle-down font-lg"
          className="text-darker-gray flex-row-reverse"
        />
      </DropdownToggle>
      <DropdownMenu>
        {SortOption.map(option => (
          <DropdownItem
            key={option.key}
            active={isActive(option.key)}
            onClick={() => handleSortingChange(option.value)}
          >
            {option.name}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

const SortOption = [
  { key: 'date_asc', value: 'date_from', name: 'data: najbliższa' },
  { key: 'date_desc', value: '-date_from', name: 'data: najdalsza' },
  { key: 'price_asc', value: 'price_brutto', name: 'cena: najniższa' },
  { key: 'price_desc', value: '-price_brutto', name: 'cena: najwyższa' },
  { key: 'resort_asc', value: 'resort__name', name: 'resort: A-Z' },
  { key: 'resort_desc', value: '-resort__name', name: 'resort: Z-A' },
]
