import * as React from 'react'

import { IconWithText } from '@components/icon-with-text'
import { Checkbox } from '@components/controls/checkbox'
import { useFormContext, useWatch } from 'react-hook-form'
import { OfferModalFormInputs } from '@modals/offer-modal/offer-modal'
import { CircleOutlineRadioButton } from '@components/controls/circle-outline-radio-button'
import { OfferModalPaymentInvoiceTaxNumber } from '@modals/offer-modal/offer-modal-payment-invoice-tax-number'
import { OfferModalPaymentInvoiceCompanyData } from '@modals/offer-modal/offer-modal-payment-invoice-company-data'
import { useDidUpdateEffect } from '@hooks/use-did-update-effect'

export const OfferModalPaymentInvoiceData = (): JSX.Element => {
  const { control, reset } = useFormContext<OfferModalFormInputs>()

  const invoiceEnabled = useWatch({ control, name: 'invoice' })

  useDidUpdateEffect(() => {
    if (!invoiceEnabled) {
      reset({
        invoice_type: 'company',
        invoice_city: '',
        invoice_postcode: '',
        invoice_street: '',
        invoice_company: '',
        invoice_nip: '',
        invoice_country: '',
      })
    }
  }, [invoiceEnabled])

  return (
    <div className="d-flex row mx-0 col-12">
      <Checkbox inputName="invoice" label="Chcę otrzymać fakturę VAT" />

      {invoiceEnabled && (
        <>
          <div className="d-sm-flex mt-2 px-0">
            <CircleOutlineRadioButton
              label="Faktura na firmę"
              id="invoice_type_company"
              name="invoice_type"
              value="company"
              wrapperClassName="col-sm-4 col-6 mt-xl-0 mt-2"
            />
            <CircleOutlineRadioButton
              label="Faktura na osobę indywidualną"
              id="invoice_type_individual"
              name="invoice_type"
              value="individual"
              wrapperClassName="col-sm-7 col-6 mt-2 mt-xl-0"
            />
          </div>
          <div className="col-xl-9 col-12 px-0">
            <OfferModalPaymentInvoiceTaxNumber />
            <OfferModalPaymentInvoiceCompanyData />
          </div>
        </>
      )}

      <IconWithText
        text="Niepodanie numeru NIP jest jednoznaczne z brakiem możliwości wystawienia FV w późniejszym terminie."
        iconClassName="uil-exclamation-circle text-primary font-xl me-2 align-self-start lh-initial "
        className="col-9 font-sm text-darker-gray px-0 mt-2"
        textClassName="font-xs"
      />
    </div>
  )
}
