import * as React from 'react'
import { OfferSection } from '@modules/offers/offer-section'
import { Badge } from '@components/badge'
import { useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { useDevicesSize } from '@hooks/use-devices-size'
import { useScrollToOffer } from '@hooks/use-scroll-to-offer'

const SECTION_ID = 'podwojna-radosc-2026-2027'

export const GastroCardOffer = (): JSX.Element => {
  const { ref } = useScrollToOffer(SECTION_ID)
  const { isMobile } = useDevicesSize()
  const navigate = useNavigate()

  const handleShowDetails = () => {
    navigate(NavigationPath.GastroCard)
  }

  return (
    <div className="container-lg" id={SECTION_ID} ref={ref}>
      <OfferSection
        backgroundVideo={
          isMobile
            ? require('@assets/images/offers/gastro-card/background-video-mobile.mp4')
            : require('@assets/images/offers/gastro-card/background-video.mp4')
        }
        onImageClick={handleShowDetails}
        badges={
          <div className="d-flex flex-wrap justify-content-center justify-content-sm-start">
            <Badge
              variant="red"
              text="Oferta dostępna czasowo"
              className="mb-2 badge-pulsing col-12 col-sm-auto me-0 me-sm-3"
            />
            <Badge variant="gold" text="Nowość" className="mb-2 col-12 col-sm-auto" />
          </div>
        }
        title={<span className="font-header">Podwójna radość 2026/2027</span>}
        description={
          <span>
            Skorzystaj ze specjalnej promocji na Voucher Podwójna Radość 2026/2027 do wykorzystania w Kawiarni, Bistro,
            Beach Barze i Słodkim Kąciku podczas pobytów <span className="text-nowrap">w Resortach </span>
            <span className="text-nowrap">Holiday Park & Resort</span>.
          </span>
        }
        onOrderClick={handleShowDetails}
        orderText="Zobacz więcej"
        offerKind="gastro-card"
        rowClassName="flex-row-reverse"
      />
    </div>
  )
}
