import * as React from 'react'
import { OfferDetailsAgreements } from '@modules/offers/common/details/personal-data/offer-details-agreements'
import { useFormContext, useWatch } from 'react-hook-form'
import { useSubscriptionContractPlans } from '@modules/offers/subscription-contract/details/use-subscription-contract-product'

export const SubscriptionContractAgreements = (): JSX.Element => {
  const plans = useSubscriptionContractPlans()
  const { control } = useFormContext()

  const selectedPlanKind = useWatch({ control, name: 'planKind' })

  const selectedPlan = plans[selectedPlanKind]

  return <OfferDetailsAgreements rulesUrl={selectedPlan?.urls?.rules || plans[0]?.urls?.rules || ''} />
}
