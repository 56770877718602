import * as React from 'react'

interface Props {
  price: React.ReactNode
  rowTitle?: string
}

export const OfferModalPriceRow = ({ price, rowTitle = 'Koszt zakupu łącznie:' }: Props): JSX.Element => (
  <div className="offer-modal__price px-3 py-3">
    <strong>{rowTitle}</strong>
    <strong className="d-block pe-2">{price}</strong>
  </div>
)
