import * as React from 'react'
import { RemainingTime } from '@components/remaining-time'
import { OrderNowButton } from '@components/controls/buttons/order-now-button'
import { ShowDetailsButton } from '@components/controls/buttons/show-details-button'

interface Props {
  description: React.ReactNode
  expirationDate?: string | null
  onOrderClick: () => void
  onDetailsClick?: () => void
  orderText?: string
  title: React.ReactNode
}

export const OfferSectionData = ({
  title,
  description,
  expirationDate,
  orderText,
  onOrderClick,
  onDetailsClick,
}: Props): JSX.Element => (
  <>
    <div className="px-4">
      <h3 className="text-secondary offers__header fw-bold">{title}</h3>
      <div className="font-xl mt-3 mb-2 col-12 col-lg-9 text-center text-sm-start">{description}</div>
      {expirationDate && <RemainingTime wrapperClassName="mb-3 mt-4 col-sm-9 col-12" expirationDate={expirationDate} />}
    </div>

    <div className="d-flex d-lg-block flex-row-reverse justify-content-between px-4 mt-5 mt-lg-4 mb-3 mb-lg-0">
      <OrderNowButton text={orderText} onClick={onOrderClick} />
      {onDetailsClick && <ShowDetailsButton onClick={onDetailsClick} className="text-darker-gray" />}
    </div>
  </>
)
