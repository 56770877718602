import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { CustomFormGroup } from '@components/controls/custom-form-group'
import { OfferModalFormInputs } from '@modals/offer-modal/offer-modal'

export const OfferModalPaymentInvoiceCompanyData = (): JSX.Element => {
  const { control } = useFormContext<OfferModalFormInputs>()

  const invoiceType = useWatch({ control, name: 'invoice_type' })

  return (
    <div>
      <CustomFormGroup
        label={invoiceType === 'company' ? 'Nazwa firmy:' : 'Imię i nazwisko:'}
        inputName="invoice_company"
        formLabelProps={{ className: 'fw-bold' }}
        formControlProps={{ type: 'text', placeholder: 'Andrzej Nowak' }}
      />
      <CustomFormGroup
        label={invoiceType === 'company' ? 'Adres firmy:' : 'Adres:'}
        inputName="invoice_street"
        formLabelProps={{ className: 'fw-bold' }}
        formControlProps={{ type: 'text', placeholder: 'Ulica' }}
      />

      <div className="d-flex">
        <CustomFormGroup
          inputName="invoice_postcode"
          maskedFormControlProps={{
            mask: '**-***',
            alwaysShowMask: true,
            className: 'letter-spacing-2',
          }}
          formControlProps={{ type: 'text' }}
          formGroupProps={{ className: 'col-4' }}
        />
        <CustomFormGroup
          inputName="invoice_city"
          formControlProps={{ type: 'text', placeholder: 'Miasto' }}
          formGroupProps={{ className: 'col-8 ps-2' }}
        />
      </div>
    </div>
  )
}
