import * as React from 'react'
import { UpcomingProductSection } from '@components/upcoming-product-section'
import { HomeLayout } from '@layouts/home-layout'
import { OffersSection } from '@modules/offers'

export const HomeView = (): JSX.Element => (
  <HomeLayout>
    <OffersSection />
    <UpcomingProductSection />
  </HomeLayout>
)
