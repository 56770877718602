import * as React from 'react'
import * as clsx from 'clsx'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  text: string | React.ReactNode
  className?: string
  iconClassName?: string
  textClassName?: string
  flexClassName?: string
}

export const IconWithText = ({
  text,
  textClassName,
  flexClassName = 'd-flex',
  iconClassName,
  className,
  ...props
}: Props): JSX.Element => (
  <div
    className={clsx('align-items-center', flexClassName, { 'cursor-pointer': !!props.onClick }, className)}
    {...props}
  >
    <i className={clsx('', iconClassName)} />
    <span className={clsx('', textClassName)}>{text}</span>
  </div>
)
