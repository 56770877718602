import * as React from 'react'

interface Props {
  title?: React.ReactNode
  children: React.ReactNode
}

export const OfferDetailsRow = ({ title, children }: Props): JSX.Element => (
  <div className="mt-5 pt-lg-5 row">
    <span className="font-xxxl d-block col-lg-3 col-12 text-center text-lg-start mb-4 mb-lg-0">{title}</span>
    <div className="col-lg-9 col-12">{children}</div>
  </div>
)
