import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'

export const NotFoundView = (): JSX.Element => {
  const navigate = useNavigate()

  const handleRedirect = () => {
    navigate(NavigationPath.Homepage)
  }

  return (
    <div className="not-found__wrapper">
      <div className="not-found__content">
        <div className="not-found__logo-section">
          <img src={require('@assets/images/logo_holiday_sklep_color.svg')} alt="Holiday Park & Resort" />
          <div className="not-found__number-wrapper">
            <strong className="not-found__number-msg">BŁĄD</strong>
            <strong className="not-found__number">404</strong>
            <strong className="not-found__number__shadow">404</strong>
          </div>
          <span className="not-found__text">
            Strona, której szukasz <strong className="not-found__text--bold">nie istnieje</strong>
          </span>
          <button className="btn not-found__button" onClick={handleRedirect}>
            Wróć do strony głównej
          </button>
        </div>
        <img src={require('@assets/images/404-bg.webp')} alt="Holidaypark 404" className="not-found__image" />
      </div>
    </div>
  )
}
