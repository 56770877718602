import * as React from 'react'
import { ExpandableProductDescription } from '@components/expandable-product-description'
import { RESERVATION_APP_URLS } from '@helpers/consts'

export const GoHolidayAdditionalCostsInfo = (): JSX.Element => (
  <section className="font-sm my-4">
    <ExpandableProductDescription isCollapsable>
      <p>
        Istnieje również możliwość odbycia krótszego pobytu (3 doby) w terminach od 25.08 do 28.08 lub od 28.08 do 31.08
        <span className="text-nowrap">z wykorzystaniem całego kodu.</span>
      </p>
      <p>
        Każdy voucher (kod) jest możliwy do wykorzystania w dowolnym roku (w ramach terminu ważności pakietu), przy czym
        można odbyć maksymalnie jeden pobyt w danym roku kalendarzowym. Rezerwacji dokonuje się samodzielnie poprzez
        stronę{' '}
        <a className="fw-bold text-decoration-none" href={RESERVATION_APP_URLS.MAIN_PAGE} target="_blank">
          rezerwuj.holidaypark.pl
        </a>{' '}
        lub telefonicznie z pomocą Biura Obsługi Klienta pod numerem{' '}
        <a className="fw-bold text-decoration-none" href="tel:735200800">
          735 200 800
        </a>
        . Przy zakładaniu rezerwacji należy opłacić jednorazową usługę przygotowania lokalu.
      </p>
      <p>
        Do każdej rezerwacji zostanie doliczona opłata eksploatacyjna i opłata klimatyczna, płatne podczas meldowania.
        Przy wyjeździe należy uiścić opłatę z tytułu zużytych mediów. Wysokość opłat została wskazana w Cenniku oraz w
        Regulaminie Głównym Holiday Park & Resort. Do każdego pobytu zostanie doliczona kwota zabezpieczenia na poczet
        kaucji zwrotnej <span className="text-nowrap">w wysokości 750 zł</span>, płatna do 12 miesięcy przed przyjazdem.
      </p>
      <p className="mb-0">Szczegółowe zasady wykorzystania określa Regulamin oferty.</p>
    </ExpandableProductDescription>
  </section>
)
