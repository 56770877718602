import * as React from 'react'
import { useCodesConversionGtmEvents } from '@modules/offers/codes-conversion/details/use-codes-conversion-gtm-events'
import { useInViewRef } from 'rooks'
import { ConvertableCode, ConvertableCodeAvailableConversion } from '@modules/offers/codes-conversion/models'
import { useFormContext, useWatch } from 'react-hook-form'
import { useDidUpdateEffect } from '@hooks/use-did-update-effect'
import { selectClientDetails } from '@store/slices/app-slice'
import { useAppSelector } from '@store/index'

interface Props {
  children: React.ReactNode
  selectedCode: ConvertableCode | null
  selectedConversionKind: ConvertableCodeAvailableConversion | null
  selectedConversionLocalization: ConvertableCodeAvailableConversion | null
  totalPrice: string
}

export const CodesConversionPaymentWrapper = ({
  selectedCode,
  selectedConversionKind,
  selectedConversionLocalization,
  children,
  totalPrice,
}: Props): JSX.Element => {
  const clientDetails = useAppSelector(selectClientDetails)
  const { sendBeginCheckoutEvent } = useCodesConversionGtmEvents()

  const [checkoutBegins, setCheckoutBegins] = React.useState(false)
  const [ref, inView] = useInViewRef({ rootMargin: '0% 0% 20% 0%', threshold: 0.2 })

  const { control } = useFormContext()
  const clientFormData = useWatch({ control, name: ['first_name', 'last_name', 'email', 'username', 'password'] })

  useDidUpdateEffect(() => {
    if (clientDetails) return

    if (
      clientFormData.some(value => !!value) &&
      selectedCode &&
      (selectedConversionKind || selectedConversionLocalization) &&
      !checkoutBegins
    ) {
      sendBeginCheckoutEvent(totalPrice.toString(), selectedConversionKind, selectedConversionLocalization)
      setCheckoutBegins(true)
    }
  }, [clientFormData])

  React.useEffect(() => {
    setCheckoutBegins(false)
  }, [selectedCode])

  React.useEffect(() => {
    if (!selectedCode || (!selectedConversionKind && !selectedConversionLocalization)) return

    if (inView && !checkoutBegins) {
      sendBeginCheckoutEvent(totalPrice.toString(), selectedConversionKind, selectedConversionLocalization)
      setCheckoutBegins(true)
    }
  }, [inView])

  return <div ref={ref}>{children}</div>
}
