import * as React from 'react'
import { useAxiosErrorHandler } from '@hooks/use-axios-error-handler'
import { FieldValues } from 'react-hook-form/dist/types/fields'
import { FormState } from 'react-hook-form'

type RequestState = 'pending' | 'resolved' | 'rejected' | 'idle'

interface ApiRequestResponse<T> {
  state: RequestState
  action: (...params) => Promise<T | undefined>
  data: T | undefined
  isLoading: boolean
}

export const useApiRequest = <T>(asyncCallable: (...params) => Promise<T>): ApiRequestResponse<T> => {
  const [state, setState] = React.useState<RequestState>('idle')
  const [data, setData] = React.useState<T | undefined>(undefined)

  const action = async (...params) => {
    setState('pending')
    try {
      const result = await asyncCallable(...params)
      setData(result)
      setState('resolved')
      return result
    } catch (error) {
      console.warn(error)

      setState('rejected')
    }
  }

  return {
    state,
    action,
    data,
    isLoading: state === 'pending',
  }
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useFormRequest = <T>(asyncCallable, setError, errorHandlerParams?) => {
  const handleAxiosFormErrors = useAxiosErrorHandler()

  return useApiRequest<T>(async (...params) => {
    try {
      return await asyncCallable(...params)
    } catch (error) {
      handleAxiosFormErrors(error, setError, { showGlobalError: false, ...errorHandlerParams })
    }
  })
}

export function useScrollToError<T extends FieldValues = FieldValues>(
  formState: FormState<T>,
  disabled?: boolean,
): void {
  React.useEffect(() => {
    if (disabled) return

    const errorValues = Object.values(formState.errors)
    if (errorValues.length === 0) return

    const error = errorValues[0]?.ref as HTMLElement | undefined

    try {
      if (typeof error?.scrollIntoView === 'function') {
        error.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
      }
    } catch (error) {
      error.scrollIntoView(false)
    }
  }, [formState, disabled])
}
