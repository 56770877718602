export const formatPrice = (value: number | string): string => {
  return Intl.NumberFormat('pl-PL', {
    style: 'currency',
    currency: 'PLN',
  })
    .format(typeof value === 'string' ? parseFloat(value) : value)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export const formatPriceShort = (value: number | string, removeCurrency = false): string => {
  const result = formatPrice(value).replace(',00', '')

  if (removeCurrency) {
    return result.replace(' zł', '')
  }

  return result
}

export const PriceRegex = /^\d+(\.\d{1,2})?$/

export const formatPercentage = (value: number | string): string => {
  return `${value.toString()}%`
}
