import * as React from 'react'
import { TrapezoidButton } from '@components/controls/buttons/trapezoid-button'
import { formatDate, parseISODate } from '@helpers/date-formatter'
import { IconWithText } from '@components/icon-with-text'
import { formatPriceShort } from '@helpers/price-helper'
import { differenceInDays, differenceInSeconds, formatISO } from 'date-fns'
import declination from '@helpers/declination'
import { BookingOffer } from '@modules/booking-offers/models'
import { useAppSelector } from '@store/index'
import { selectAppData } from '@store/slices/app-slice'
import classNames from 'classnames'
import { useFormContext, useWatch } from 'react-hook-form'
import { BookingOffersFiltersParams } from '@modules/booking-offers'
import { useBookingOfferOptimisticUpdate } from '@modules/booking-offers/use-booking-offer-optimistic-update'
import { useTimeCountUp } from '@hooks/use-time-count-up'

interface Props {
  offer: BookingOffer
}

export const BookingOffersCard: React.FC<Props> = ({ offer }) => {
  const { resorts, accommodation_types } = useAppSelector(selectAppData)

  const initialInactiveTime = offer.used_at ? differenceInSeconds(new Date(), parseISODate(offer.used_at)) : 1

  const { duration } = useTimeCountUp(initialInactiveTime, !offer.is_available)

  const { control } = useFormContext<BookingOffersFiltersParams>()
  const season = useWatch({ control, name: 'season' })
  const { updateOffer } = useBookingOfferOptimisticUpdate()

  const dateFrom = React.useMemo(() => parseISODate(offer.date_from) || 0, [offer.date_from])
  const dateTo = React.useMemo(() => parseISODate(offer.date_to) || 0, [offer.date_to])

  const numberOfNights = differenceInDays(dateTo, dateFrom)

  const resort = resorts.find(resort => resort.id === offer.resort_id)
  const accommodationType = accommodation_types.find(
    accommodationType => accommodationType.id === offer.accommodation_type_id,
  )

  const resortImageSrc = resort?.reservation_images.length ? resort.reservation_images[0].image.xs : ''

  const handleOfferClick = () => {
    updateOffer([{ ...offer, is_available: false, used_at: formatISO(new Date()) }])
  }

  return (
    <div className="col-xl-3 col-lg-4 col-md-6 col-12">
      <a
        href={offer.urls.sale}
        target="_blank"
        className={classNames('booking-offers__card', { 'is-inactive pe-none': !offer.is_available })}
        onClick={handleOfferClick}
      >
        <div className={classNames('booking-offers__card__inactive-banner', { 'is-visible': !offer.is_available })}>
          <i className="uil-clock-three font-header-lg" />
          <p className="fw-semi-bold mt-2 mb-3 text-center font-lg">
            Za późno, ta oferta właśnie <br /> została wykupiona
          </p>
          <span>{duration} temu</span>
        </div>
        <img
          src={resortImageSrc}
          alt={`${resort?.name} Holiday Park & Resort`}
          className="booking-offers__card__image rounded-top"
        />
        <div className="booking-offers__card__season-signature__wrapper">
          <div className={classNames('booking-offers__card__season-signature', { 'is-summer': season === 'summer' })} />
        </div>

        <div className="p-3">
          <strong className="text-primary">{resort?.name}</strong>
          <div className="d-flex align-items-center">
            <strong>{formatDate(offer.date_from, 'dd.MM')}</strong>
            <i className="uil-arrow-right text-primary font-xxl mx-2" />
            <strong className="pe-1">{formatDate(offer.date_to, 'dd.MM.yyyy')}</strong>
            <span>
              ({numberOfNights} {declination.night(numberOfNights)})
            </span>
          </div>
        </div>
        <div className="d-flex ps-3 justify-content-between">
          <div className="pb-3">
            <IconWithText
              iconClassName="uil-pathfinder-unite font-xl me-2 text-primary"
              text={`${accommodationType?.name} ${accommodationType?.size}m²`}
            />
            <IconWithText
              iconClassName="uil-usd-circle font-xl me-2 text-primary"
              text={formatPriceShort(offer.price_brutto)}
            />
          </div>
          <TrapezoidButton className="align-self-end position-absolute end-0" variant="danger">
            <strong>Rezerwuj</strong>
          </TrapezoidButton>
        </div>
      </a>
    </div>
  )
}
