import { RefCallBack, UseFormRegisterReturn } from 'react-hook-form'

interface Response extends Omit<UseFormRegisterReturn, 'ref'> {
  innerRef: RefCallBack
}

export const extractInnerRef = <T extends UseFormRegisterReturn>(params: T): Response => {
  const { ref, ...rest } = params
  return { ...rest, innerRef: ref }
}
