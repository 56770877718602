import * as React from 'react'
import { commonObjectPost } from '@requests/basic-requests'
import { useFormContext, useWatch } from 'react-hook-form'
import { BaseFormFields, OfferPaymentBar } from '@modules/offers/common/details/personal-data/offer-payment-bar'
import { useGoHolidayProducts } from '@modules/offers/go-holiday/use-go-holiday-products'
import { SubscriptionProductPaymentWrapper } from '@modules/offers/common/subscription-product-payment-wrapper'
import { useGoHolidayGtmEvents } from '@modules/offers/go-holiday/use-go-holiday-gtm-events'

export const GoHolidayPayment = (): JSX.Element => {
  const { control, getValues } = useFormContext()
  const { getSelectedProduct } = useGoHolidayProducts()

  const [accommodation, clientVariant] = useWatch({ control, name: ['accommodation', 'clientVariant'] })
  const selectedProduct = getSelectedProduct(accommodation, clientVariant)
  const totalPrice = selectedProduct?.subscription_product.price_brutto ?? '0'

  const paymentAction = async (baseFields: BaseFormFields) => {
    const { subscription_go_holiday_rules, subscription_go_holiday_offer } = getValues()

    return await commonObjectPost<{ urls: { sale: string } }>(selectedProduct?.urls?.sale || '', {
      id: selectedProduct?.id,
      subscription_go_holiday_rules,
      subscription_go_holiday_offer,
      ...baseFields,
    })
  }

  return (
    <SubscriptionProductPaymentWrapper gtmEventHook={useGoHolidayGtmEvents} selectedProduct={selectedProduct}>
      <OfferPaymentBar
        totalPrice={totalPrice}
        canSubmit={!!selectedProduct}
        paymentAction={paymentAction}
        rulesKeywords={['subscription_go_holiday_rules', 'subscription_go_holiday_offer']}
      />
    </SubscriptionProductPaymentWrapper>
  )
}
