import clsx from 'clsx'
import * as React from 'react'
import { OfferDetailsRow } from '@modules/offers/common/details/offer-details-row'
import { CodesConversionVariantBlock } from '@modules/offers/codes-conversion/details/codes-conversion-variant-block'
import { CodesConversionLocalizationBlock } from '@modules/offers/codes-conversion/details/codes-conversion-localization-block'
import { CodesConversionCodeInput } from '@modules/offers/codes-conversion/details/codes-conversion-code-input'
import { useFormContext, useWatch } from 'react-hook-form'
import { Collapse } from 'reactstrap'
import { CodesConversionFormInputs } from '@modules/offers/codes-conversion/details/index'
import {
  AvailableConvertableCodeOptionKind,
  AvailableConvertableCodeOptionLocalization,
  ConvertableCode,
  SelectedCodeAvailableConversions,
} from '@modules/offers/codes-conversion/models'
import { useApiRequest } from '@hooks/use-request'
import { commonObjectGet } from '@requests/basic-requests'
import { useAppSelector } from '@store/index'
import { selectAppData, selectClientDetails } from '@store/slices/app-slice'
import { AlertMessage } from '@modules/offers/codes-conversion/alert-message'
import { useCodesConversionGtmEvents } from '@modules/offers/codes-conversion/details/use-codes-conversion-gtm-events'

export const CodesConversionCodeSelection = (): JSX.Element => {
  const { sendViewItemListEvent } = useCodesConversionGtmEvents()

  const clientDetails = useAppSelector(selectClientDetails)
  const { urls } = useAppSelector(selectAppData)

  const [clientCodes, setClientCodes] = React.useState<ConvertableCode[]>([])

  const { control } = useFormContext<CodesConversionFormInputs>()
  const selectedCode = useWatch({ control, name: 'selectedCode' })

  const { action: fetchClientCodes } = useApiRequest(async () => {
    setClientCodes(await commonObjectGet(urls.products.codes))
  })

  React.useEffect(() => {
    if (clientDetails) fetchClientCodes()
  }, [clientDetails])

  const availableConversions: SelectedCodeAvailableConversions = (selectedCode?.available_conversions ?? []).reduce(
    (prev, conversion) => {
      return {
        kindConversions: [
          ...prev.kindConversions,
          ...(AvailableConvertableCodeOptionKind.some(kind => kind === conversion.option_kind) ? [conversion] : []),
        ],
        localizationConversions: [
          ...prev.localizationConversions,
          ...(AvailableConvertableCodeOptionLocalization.some(kind => kind === conversion.option_kind)
            ? [conversion]
            : []),
        ],
      }
    },
    { kindConversions: [], localizationConversions: [] },
  )

  React.useEffect(() => {
    sendViewItemListEvent(availableConversions)
  }, [selectedCode?.code])

  const hasAvailableConversions =
    availableConversions.localizationConversions.length || availableConversions.kindConversions.length

  return (
    <div className="container-xl">
      <OfferDetailsRow
        title={
          <span className="text-secondary">
            Wpisz
            <strong> swój kod:</strong>
            <div className="fw-normal font-lg text-muted mt-1 col-sm-8">
              Sprawdź czy możesz ulepszyć swój kod pobytowy:
            </div>
          </span>
        }
      >
        <CodesConversionCodeInput clientCodes={clientCodes} />
        <Collapse isOpen={!!selectedCode}>
          <>
            <div
              className={clsx('bg-light-gray rounded codes-conversion__no-options-available-block', {
                'is-visible': !hasAvailableConversions,
              })}
            >
              <AlertMessage message="Wprowadzony kod nie może zostać ulepszony." className="d-inline-block" />
            </div>
            <CodesConversionVariantBlock
              selectedCode={selectedCode}
              conversions={availableConversions.kindConversions}
            />
            <CodesConversionLocalizationBlock
              selectedCode={selectedCode}
              conversions={availableConversions.localizationConversions}
            />
          </>
        </Collapse>
      </OfferDetailsRow>
    </div>
  )
}
