import * as React from 'react'
import { Button } from 'reactstrap'
import { IconWithText } from '@components/icon-with-text'
import { useModal } from '@modals/use-modal'
import { useSelector } from 'react-redux'
import { selectClientDetails } from '@store/slices/app-slice'
import { ConvertableCode } from '@modules/offers/codes-conversion/models'

interface Props {
  clientCodes: ConvertableCode[]
}

export const CodesConversionCodeInputMessage = ({ clientCodes }: Props): JSX.Element => {
  const [showLoginModal] = useModal('LoginModal')
  const clientDetails = useSelector(selectClientDetails)

  return (
    <div className="d-flex align-items-center ms-sm-4">
      {clientDetails ? (
        <div className="mt-3 d-flex align-items-center">
          <IconWithText
            text={clientDetails.first_name}
            iconClassName="uil-user text-primary font-xxl me-2"
            textClassName="text-darker-gray fw-semi-bold"
          />
          , wpisz kod {clientCodes.length ? 'lub wybierz go z listy' : ''}
        </div>
      ) : (
        <div className="mt-2">
          <Button className="btn-unstyled p-0" onClick={showLoginModal}>
            <IconWithText
              text="Zaloguj się"
              iconClassName="uil-user text-primary font-xxl me-2"
              textClassName="text-darker-gray fw-semi-bold"
            />
          </Button>
          , aby wyświetlić wszystkie swoje kody
        </div>
      )}
    </div>
  )
}
