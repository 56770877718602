import * as React from 'react'
import { OfferDetailsPersonalData } from '@modules/offers/common/details/personal-data/offer-details-personal-data'
import { CodesConversionManualBox } from '@modules/offers/codes-conversion/details/codes-conversion-manual-box'
import { CodesConversionAgreements } from '@modules/offers/codes-conversion/details/codes-conversion-agreements'

export const CodesConversionPersonalData = (): JSX.Element => (
  <OfferDetailsPersonalData
    agreements={<CodesConversionAgreements />}
    title={
      <span className="text-secondary">
        <strong className="d-block">Konwertuj</strong> swój kod.
      </span>
    }
  >
    <CodesConversionManualBox />
  </OfferDetailsPersonalData>
)
