import * as React from 'react'
import * as clsx from 'clsx'

export type ToggleButtonSelectionSide = 'left' | 'right'

interface Props {
  onSideChange: (selectedSide: ToggleButtonSelectionSide) => void
  defaultActiveSide?: ToggleButtonSelectionSide
  left: React.ReactNode
  right: React.ReactNode
  disabled?: boolean
  toggleInterval?: number
}

export const ToggleButton = ({
  onSideChange,
  defaultActiveSide = 'left',
  left,
  right,
  disabled,
  toggleInterval,
}: Props): JSX.Element => {
  let interval: NodeJS.Timeout

  const [activeSide, setActiveSide] = React.useState(defaultActiveSide)

  const onChange = (side: ToggleButtonSelectionSide) => (event?: React.MouseEvent) => {
    event?.stopPropagation()

    setActiveSide(side)
    onSideChange(side)
  }

  React.useEffect(() => {
    if (!toggleInterval) return
    clearInterval(interval)

    interval = setInterval(() => {
      onChange(activeSide === 'left' ? 'right' : 'left')()
    }, toggleInterval)

    return () => {
      clearInterval(interval)
    }
  }, [activeSide])

  return (
    <div className="toggle-button">
      <div
        className={clsx(
          'toggle-button__left-action',
          { 'is-active': activeSide === 'left' },
          { 'pe-none opacity-50': disabled },
        )}
        onClick={onChange('left')}
      >
        {left}
      </div>
      <div
        className={clsx(
          'toggle-button__right-action',
          { 'is-active': activeSide === 'right' },
          { 'pe-none opacity-50': disabled },
        )}
        onClick={onChange('right')}
      >
        {right}
      </div>
    </div>
  )
}
