import * as React from 'react'
import { useModal } from '@modals/use-modal'
import { IconWithText } from '@components/icon-with-text'
import { Button } from 'reactstrap'
import { useFormContext, useWatch } from 'react-hook-form'
import { BookingOffersFiltersParams } from '@modules/booking-offers/index'
import { BookingOffersAdvancedFiltersFormInputs } from '@modules/booking-offers/filters/advanced-filters/booking-offers-advanced-filters-modal'
import { BookingOffersAggregation } from '@modules/booking-offers/models'

interface Props {
  aggregation: BookingOffersAggregation
}

export const BookingOffersNotFound = ({ aggregation }: Props): JSX.Element => {
  const [showAdvancedFiltersModal] = useModal('BookingOffersAdvancedFiltersModal')

  const { control, setValue, reset } = useFormContext<BookingOffersFiltersParams>()

  const formValues = useWatch({ control })

  const handleFiltersChange = (filters: BookingOffersAdvancedFiltersFormInputs | null) => {
    setValue('advancedFilters', filters)
  }

  const clearFilters = () => {
    handleFiltersChange(null)
    reset()
  }

  const handleFiltersModalShow = () => {
    showAdvancedFiltersModal(null, {
      onFiltersChange: handleFiltersChange,
      initialFilters: formValues.advancedFilters,
      aggregation,
    })
  }

  return (
    <div className="mt-5 d-flex align-items-center justify-content-center flex-wrap">
      <div className="col-sm-6 col-12">
        <h4 className="fw-bold">Mamy dużo innych krótkich terminów</h4>
        <p className="font-xl fw-semi-bold">
          Niestety, chwilowo brak ofert o wybranych parametrach. <br /> Rozszerz lub wyzeruj parametry wyszukiwania.
        </p>

        <div className="d-flex gap-2 align-items-center mt-sm-5 mt-3">
          <Button
            color="light"
            outline
            className="border d-block order-2 order-md-1 btn-mobile-w-100 my-4 my-md-0"
            onClick={clearFilters}
          >
            <IconWithText
              text="Wyzeruj parametry"
              iconClassName="uil-times text-darker-gray me-2 font-xxl lh-1"
              textClassName="text-darker-gray fw-semi-bold"
              className="justify-content-center justify-content-md-start"
            />
          </Button>
          <Button
            color="light"
            outline
            className="border d-block order-2 order-md-1 btn-mobile-w-100 my-4 my-md-0"
            onClick={handleFiltersModalShow}
          >
            <IconWithText
              text="Zmień parametry"
              iconClassName="uil-sliders-v-alt text-darker-gray me-2"
              textClassName="text-darker-gray fw-semi-bold"
              className="justify-content-center justify-content-md-start"
            />
          </Button>
        </div>
      </div>
      <img
        src={require('@assets/images/search-image.svg')}
        alt="Holiday Park & Resort last minute"
        height={220}
        width={230}
      />
    </div>
  )
}
