import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { Button } from 'reactstrap'

interface Props {
  className?: string
  onClick: () => void
}

export const ShowDetailsButton = ({ className, onClick }: Props): JSX.Element => (
  <Button className="btn-transparent py-2 px-3 fw-bold border-0 pb-2 pb-xl-0" onClick={onClick}>
    <IconWithText
      text="szczegóły"
      iconClassName="uil-plus font-xxxl me-2 lh-initial"
      className={className}
      textClassName="font-xl"
    />
  </Button>
)
