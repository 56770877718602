import * as React from 'react'
import { OfferSection } from '@modules/offers/offer-section'
import { useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { formatPriceShort } from '@helpers/price-helper'
import { useGoHolidayProducts } from '@modules/offers/go-holiday/use-go-holiday-products'

export const GoHolidayOffer = (): JSX.Element => {
  const navigate = useNavigate()

  const { products } = useGoHolidayProducts()

  const cheapestOffer = React.useMemo(
    () =>
      [
        ...products.house.new_client,
        ...products.house.regular_client,
        ...products.apartment.new_client,
        ...products.apartment.regular_client,
      ].sort(
        (a, b) => parseFloat(a.subscription_product.price_brutto) - parseFloat(b.subscription_product.price_brutto),
      )[0],
    [products],
  )

  const handleShowDetails = () => {
    navigate(NavigationPath.GoHoliday)
  }

  return (
    <div className="container-lg">
      <OfferSection
        title={<span className="font-header">GO!Holiday</span>}
        rowClassName="flex-row-reverse"
        description={
          <>
            <strong>Pakiet 3 pobytów</strong>
            <span className="d-block">
              w domku w górach lub nad morzem,
              <span className="text-nowrap">
                {' '}
                już od {cheapestOffer ? formatPriceShort(cheapestOffer.subscription_product.price_brutto) : '0'}
              </span>
            </span>
          </>
        }
        onOrderClick={handleShowDetails}
        onImageClick={handleShowDetails}
        orderText="Zobacz więcej"
        offerKind="go-holiday"
        price={{
          pre: (
            <>
              <strong className="d-block font-header-lg text-end">Tanie</strong>
              pobytowanie
            </>
          ),
          after: 'przez 5 lat',
          shapePosition: 'bottom',
          className: 'font-sm',
        }}
      />
    </div>
  )
}
