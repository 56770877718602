import * as clsx from 'clsx'
import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { Input, InputProps } from 'reactstrap'
import { extractInnerRef } from '@helpers/forms'

interface Props {
  inputName: string
  formControlProps: InputProps | undefined
}

export const CustomFormControl = ({ inputName, formControlProps }: Props): JSX.Element => {
  const { register, control } = useFormContext()
  const value = useWatch({
    name: inputName,
    control,
  })

  return (
    <Input
      {...extractInnerRef(register(inputName))}
      {...formControlProps}
      className={clsx(
        'shadow-none form-control-height-lg font-sm form-control-with-status',
        { 'no-value': !value },
        formControlProps?.className,
      )}
      data-testid={`${inputName}-form-control`}
    />
  )
}
