import * as React from 'react'
import { ExpandableProductDescription } from '@components/expandable-product-description'

export const SubscriptionWithBenefitAdditionalCostsInfo = (): JSX.Element => (
  <section className="font-sm my-4">
    <ExpandableProductDescription isCollapsable>
      <p>
        Przy zakładaniu rezerwacji należy opłacić jednorazową usługę przygotowania lokalu. Do każdej rezerwacji zostanie
        doliczona opłata eksploatacyjna i opłata klimatyczna, płatne podczas meldowania. Przy wyjeździe należy uiścić
        opłatę z tytułu zużytych mediów. Wysokość opłat została wskazana w Cenniku oraz w Regulaminie Głównym Holiday
        Park & Resort.
      </p>
      <p>
        Do każdego pobytu zostanie doliczona kwota zabezpieczenia na poczet kaucji zwrotnej w wysokości 750 zł, płatna
        do 12 miesięcy przed rozpoczęciem rezerwacji.
      </p>
      <p>
        Dodatkowe środki na zakupy opaską można zrealizować w Kawiarni, Bistro, Beach Barze i Słodkim Kąciku (w ramach
        dostępności punktów) podczas rezerwacji z wykorzystaniem voucherów pobytowych we wskazanych latach.
      </p>

      <p>
        Terminy wykorzystania voucherów pobytowych różnią się w zależności od wariantu Pakietu: <br />{' '}
        <span className="fw-semi-bold">Pakiet Optimum</span> - możesz do nas przyjechać od 01.01 do 31.05 oraz od 01.10
        do 31.12*
        <br />
        <span className="fw-semi-bold">Pakiet Comfort</span> - możesz do nas przyjechać od 01.01 do 30.06 oraz od 01.09
        do 31.12*
        <br />
        <span className="fw-semi-bold">Pakiet Lux</span> - możesz do nas przyjechać od 01.01 do 07.07 oraz od 26.08 do
        31.12
        <div className="mt-1">*Z wyłączeniem terminów specjalnych.</div>
      </p>

      <p className="mb-0">Szczegółowe zasady wykorzystania określa Regulamin oferty.</p>
    </ExpandableProductDescription>
  </section>
)
