import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { throwMiddleware } from '@store/throw-middleware'
import modalState from '@store/slices/modal-slice'
import appState from '@store/slices/app-slice'
import notificationMessagesState from '@store/slices/notification-messages-slice'
import { bookingOffersApi } from '@api/booking-offers'
import webSocketState from '@store/slices/websocket-slice'

export const store = configureStore({
  reducer: {
    appState,
    modalState,
    notificationMessagesState,
    webSocketState,
    [bookingOffersApi.reducerPath]: bookingOffersApi.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat(bookingOffersApi.middleware, throwMiddleware),
})

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const getState = (): RootState => store.getState()
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default store
