import * as React from 'react'
import { BaseModalProps } from '@modals/types'
import { ModalWithImageWrapper } from '@modals/modal-with-image-wrapper'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { CustomFormGroup } from '@components/controls/custom-form-group'
import { PasswordFormGroup } from '@components/controls/password-form-group'
import { TextWithSpinner } from '@components/loaders/text-with-spinner'
import { IconWithText } from '@components/icon-with-text'
import { ModalHeader } from 'reactstrap'
import { LoginFormInputs, useLogin } from '@components/header/login/use-login'

export const LoginModal = ({ toggleIsVisible }: BaseModalProps): JSX.Element => {
  const methods = useForm<LoginFormInputs>({
    defaultValues: { username: '', password: '' },
  })

  const { password, username } = useWatch({ control: methods.control })

  const { isLoginPending, login, redirectToPasswordReset, redirectToAccountCreation } = useLogin(methods)

  const handleLogin = async () => {
    if (!username || !password) return

    login(username, password, toggleIsVisible)
  }

  return (
    <>
      <ModalHeader className="d-flex align-items-center justify-content-between py-0 ps-5" toggle={toggleIsVisible}>
        <div className="text-muted d-flex justify-content-between align-items-center w-100">
          <h4 className="text-primary text-start text-xl-center mb-0 py-3 fw-bold">Logowanie</h4>

          <button className="uil-multiply ms-2 custom-modal__close-button px-xl-2" onClick={toggleIsVisible} />
        </div>
      </ModalHeader>
      <ModalWithImageWrapper>
        <div className="px-xl-5 py-4 container-xl">
          <p className="text-darker-gray">
            <strong>Zaloguj się </strong> lub <strong>utwórz konto</strong> i ciesz się nowymi możliwościami w{' '}
            <span className="text-nowrap">Holiday Park & Resort</span>.
          </p>
          <p className="fw-bold text-primary">Pamiętaj - z subskrypcją możesz więcej!</p>

          <FormProvider {...methods}>
            <CustomFormGroup
              inputName="username"
              formControlProps={{
                type: 'email',
                placeholder: 'email@holidaypark.pl',
              }}
              label="Wpisz swój adres e-mail:*"
              formLabelProps={{ className: 'fw-normal text-darker-gray' }}
            />

            <PasswordFormGroup
              inputName="password"
              label="Wpisz hasło do konta:*"
              formLabelProps={{ className: 'fw-normal text-darker-gray' }}
              isLabelTop={true}
            />

            <button
              className="d-block ms-auto text-secondary cursor-pointer btn-unstyled fw-bold mt-2"
              onClick={redirectToPasswordReset}
            >
              Nie pamiętam hasła
            </button>

            <button
              className="d-block cursor-pointer btn btn-primary fw-bold text-white btn-thin w-100 mt-4"
              onClick={handleLogin}
              disabled={!password || !username || isLoginPending}
            >
              <TextWithSpinner
                isLoading={isLoginPending}
                loadingText="Proszę czekać"
                wrapperClassName="align-items-center d-flex"
                text="Zaloguj się"
              />
            </button>

            <button
              className="cursor-pointer btn-unstyled fw-bold mt-4 mx-auto d-block text-darker-gray"
              onClick={redirectToAccountCreation}
            >
              <IconWithText
                text="Nie posiadasz konta? Utwórz je teraz"
                textClassName="lh-0"
                iconClassName="uil-user me-1 font-size-xxl lh-0"
              />
            </button>
          </FormProvider>
        </div>
      </ModalWithImageWrapper>
    </>
  )
}
