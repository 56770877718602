import * as React from 'react'
import * as clsx from 'clsx'
import { FormGroup, FormGroupProps, Label, LabelProps } from 'reactstrap'
import { FormControlWithStatus } from '@components/controls/form-control-with-status'
import { FieldError } from 'react-hook-form'

export interface FormControlWithLabelProps {
  label?: React.ReactNode
  inputName: string
  formLabelProps?: LabelProps
  formGroupProps?: FormGroupProps
  isLoading?: boolean
  isSucceed?: boolean
  isFailed?: boolean
  inputSuffix?: React.ReactNode
  children: React.ReactNode
  renderError?: (error: FieldError) => React.ReactNode
}

export const FormControlWithLabel = ({
  label,
  inputName,
  formLabelProps,
  formGroupProps,
  isLoading,
  isSucceed,
  isFailed,
  inputSuffix,
  children,
  renderError,
}: FormControlWithLabelProps): React.ReactElement => (
  <FormGroup {...formGroupProps} className={clsx(formGroupProps?.className, 'mt-2 px-0 mb-lg-1')}>
    {label && (
      <Label {...formLabelProps} className={clsx('mb-0 d-flex font-sm', formLabelProps?.className)}>
        {label}
      </Label>
    )}
    <FormControlWithStatus
      inputName={inputName}
      isLoadingVisible={!!isLoading}
      isFailed={!isLoading && isFailed}
      isSucceed={!isLoading && isSucceed}
      inputSuffix={inputSuffix}
      renderError={renderError}
    >
      {children}
    </FormControlWithStatus>
  </FormGroup>
)
