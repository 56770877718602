import * as React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Props, StylesConfig } from 'react-select'
import { FormError } from '@components/controls/form-error'
import * as clsx from 'clsx'
import CreatableSelect from 'react-select/creatable'
import Select from 'react-select/base'

export function createSelectOption<L = unknown, V = unknown>(label: L, value: V): CustomReactSelectOption<L, V> {
  return {
    label,
    value,
  }
}

export interface CustomReactSelectOption<L = unknown, V = unknown> {
  label: L
  value: V
}

interface CustomReactSelectProps extends Props {
  label?: React.ReactNode
  labelClassName?: string
  onKeyDown?: (event) => void
  inputName: string
  isSelectMulti?: boolean
  options: CustomReactSelectOption[]
  placeholder?: React.ReactNode
  formatCreateLabel?: (userInput: string) => string
  selectStyles?: StylesConfig
  isCreatable?: boolean
}

export const CustomReactSelect: React.FC<CustomReactSelectProps> = ({
  label,
  labelClassName,
  onKeyDown,
  inputName,
  noOptionsMessage = () => 'brak opcji',
  placeholder,
  options,
  isSelectMulti,
  selectStyles,
  isCreatable = false,
  ...params
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  const hasError = !!errors[inputName]

  const styles = {
    menu: provided => ({ ...provided, marginTop: 0, zIndex: 9999 }),
    singleValue: provided => ({
      ...provided,
      fontSize: '0.95rem',
      color: '#576067',
    }),
    option: (provided, { isSelected }) => ({
      ...provided,
      backgroundColor: isSelected ? '#dee2e6' : null,
      borderBottom: '1px solid  #dee2e6',
      color: isSelected ? 'text-dark' : 'text-muted',
      cursor: 'pointer',
      fontSize: '0.95rem',
      '&:hover': { backgroundColor: '#dee2e6' },
    }),
    menuList: provided => ({ ...provided, marginTop: 0, marginBottom: 0, paddingTop: 0, paddingBottom: 0 }),
    control: provided => ({
      ...provided,
      minHeight: '35px',
      border: `1px solid ${hasError ? '#dd3535' : '#dee2e6'}`,
      cursor: 'pointer',
      boxShadow: 'none',
      '&:hover': { borderColor: '#adadad' },
    }),
    noOptionsMessage: provided => ({
      ...provided,
      fontSize: '0.95rem',
    }),
  }

  const selectComponents = {
    IndicatorSeparator: () => null,
  }

  const SelectComponent = (isCreatable ? CreatableSelect : Select) as CreatableSelect

  return (
    <>
      {label && (
        <label htmlFor={inputName} className={clsx('font-md fw-semi-bold', labelClassName)}>
          {label}
        </label>
      )}
      <Controller
        control={control}
        name={inputName}
        render={({ field }) => (
          <SelectComponent
            onKeyDown={onKeyDown}
            isMulti={!!isSelectMulti}
            styles={{ ...styles, ...selectStyles }}
            options={options}
            placeholder={placeholder || 'Wybierz..'}
            components={selectComponents}
            inputId={inputName}
            noOptionsMessage={noOptionsMessage}
            {...field}
            {...params}
          />
        )}
      />
      <FormError inputName={inputName} />
    </>
  )
}
