import Decimal from 'decimal.js'

interface Response {
  backgroundColor: string
}

export const useGastroCardDynamicBonusStyle = (multiplier: string | number): Response => {
  const backgroundOpacity = new Decimal(multiplier).minus(1.4).toString()

  return {
    backgroundColor: `rgb(100, 100, 100, ${backgroundOpacity})`,
  }
}
