export enum StaticRules {
  email_rules = 'Zgodnie z art. 10 ust. 2 ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną (tj.. Dz. U. z 2016 r. poz. 1030) wyrażam dobrowolnie zgodę na przesyłanie na wskazany przeze mnie adres e-mail informacji handlowej przez Holiday Park&Resort spółka z ograniczoną odpowiedzialnością spółka komandytowa - siedziba: 63-100 Śrem, ul. Fryderyka Chopina 1J.',
  sms_rules = 'Zgodnie z art. 172 ust. 1 ustawy z dnia 16 lipca 2004 r. Prawo telekomunikacyjne (tj. Dz. U. z 2016 r. poz. 1489) wyrażam dobrowolnie zgodę na przesyłanie na wskazany przeze mnie numer telefonu, którego jestem użytkownikiem, informacji handlowej przez Holiday Park&Resort spółka z ograniczoną odpowiedzialnością spółka komandytowa - siedziba: 63-100 Śrem, ul. Fryderyka Chopina 1J.',
  phone_call_rules = '(Wyrażam zgodę na przetwarzanie w przyszłości przez Holiday Park & Resort sp. z o.o. sp. k. w celach  marketingowych następujących dotyczących mnie danych osobowych: imienia, nazwiska, adresu zamieszkania, adresu do korespondencji, adresu poczty elektronicznej, numeru telefonu.)',
  marketing_rules = '(Wyrażam zgodę na przetwarzanie w przyszłości przez Holiday Park & Resort sp. z o.o. sp. k. w celach marketingowych następujących dotyczących mnie danych osobowych: imienia, nazwiska, adresu zamieszkania, adresu do korespondencji, adresu poczty elektronicznej, numeru telefonu.)',
}

export const payuPrivacyPolicy =
  'Administratorem Twoich danych osobowych jest PayU S.A. z siedzibą w Poznaniu (60-166), przy ul. Grunwaldzkiej 186. Twoje dane osobowe będą przetwarzane w celu realizacji transakcji płatniczej, powiadamiania Cię o statusie realizacji Twojej płatności, rozpatrywania reklamacji, a także w celu wypełnienia obowiązków prawnych ciążących na PayU.\n' +
  '\n' +
  'Odbiorcami Twoich danych osobowych mogą być podmioty współpracujące z PayU w procesie realizacji płatności. W zależności od wybranej przez Ciebie metody płatności mogą to być: banki, instytucje płatnicze, instytucje pożyczkowe, organizacje kart płatniczych, schematy płatnicze), ponadto podmioty wspierające działalność PayU tj. dostawcy infrastruktury IT, dostawcy narzędzi do analizy ryzyka płatności a także podmiotom uprawnionym do ich otrzymania na mocy obowiązujących przepisów prawa, w tym właściwym organom wymiaru sprawiedliwości. Twoje dane mogą zostać udostępnione akceptantom celem poinformowania ich o statusie realizacji płatności. Przysługuje Tobie prawo dostępu do danych, a także do ich sprostowania, ograniczenia ich przetwarzania, zgłoszenia sprzeciwu wobec ich przetwarzania, niepodlegania zautomatyzowanemu podejmowaniu decyzji w tym profilowania oraz do przenoszenia i usunięcia danych. Podanie danych jest dobrowolne jednak niezbędne do realizacji płatności, a brak podania danych może skutkować odrzuceniem płatności. Więcej informacji o zasadach przetwarzania Twoich danych osobowych przez PayU znajdziesz w Polityce Prywatności.'
