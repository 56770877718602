import * as React from 'react'
import { OfferDetailsRow } from '@modules/offers/common/details/offer-details-row'

export const GastroCardDynamicWhereToUse = (): JSX.Element => (
  <div className="container-xl mx-auto">
    <OfferDetailsRow
      title={
        <span className="text-secondary">
          Gdzie wykorzystasz <br />
          <strong className="text-nowrap">swój voucher?</strong>
        </span>
      }
    >
      <div className="row gap-3 gap-sm-0">
        <Item title="Bistro" image={require('@assets/images/offers/gastro-card-dynamic/burger.webp')} />
        <Item title="Kawiarnia" image={require('@assets/images/offers/gastro-card-dynamic/caffe.webp')} />
        <Item title="Beach-Bar" image={require('@assets/images/offers/gastro-card-dynamic/drink.webp')} />
        <Item title="Słodki Kącik" image={require('@assets/images/offers/gastro-card-dynamic/waffle.webp')} />
      </div>
    </OfferDetailsRow>
  </div>
)

const Item = ({ title, image }) => (
  <div className="col-md-3 col-sm-6 col-12">
    <div className="border rounded mx-auto" style={{ maxWidth: 300 }}>
      <img src={image} alt={title} width="100%" style={{ maxHeight: 200 }} />
      <span className="fw-semi-bold text-center d-block my-2 font-xl">{title}</span>
    </div>
  </div>
)
