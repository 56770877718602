import * as React from 'react'
import { InputGroup, InputGroupText } from 'reactstrap'
import { Controller, useFormContext } from 'react-hook-form'
import classNames from 'classnames'
import DatePickerComponent from '@components/controls/date-picker'

interface Props {
  name: string
  inputClassName?: string
  inputGroupClassName?: string
  isDisabled?: boolean
  wrapperClassName?: string
  minDate?: Date | null
  maxDate?: Date | null
  placeholder?: string
  dateFormat?: string
}

const DatePickerInput: React.FC<Props> = ({
  name,
  inputClassName,
  inputGroupClassName,
  isDisabled,
  wrapperClassName,
  minDate,
  maxDate,
  placeholder,
  dateFormat,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <InputGroup
      className={classNames('date-picker-input border rounded', inputGroupClassName, { disabled: isDisabled })}
    >
      <InputGroupText
        className={classNames('bg-transparent border-0', {
          'is-invalid border-danger': errors && !!errors[name],
        })}
      >
        <i className="uil-calender text-muted " />{' '}
      </InputGroupText>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          <DatePickerComponent
            date={field.value}
            onChange={field.onChange}
            inputClassName={`border-radius-left-0 border-left-0 ${inputClassName || ''}`}
            wrapperClassName={wrapperClassName}
            isInvalid={fieldState.invalid}
            isDisabled={isDisabled}
            minDate={minDate}
            maxDate={maxDate}
            placeholder={placeholder}
            dateFormat={dateFormat}
          />
        )}
      />
    </InputGroup>
  )
}

export default DatePickerInput
