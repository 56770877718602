import { useAppDispatch } from '@store/index'
import { setWebSocketData } from '@store/slices/websocket-slice'
import React from 'react'

const protocol = document.location.protocol === 'http:' ? 'ws' : 'wss'
const port = document.location.port ? `:${document.location.port}` : ''

export const socketUrl = `${protocol}://${document.location.hostname}${port}/ws/website/`

export const useWebsocketsHandler = (): void => {
  const dispatch = useAppDispatch()
  const [webSocket, setWebSocket] = React.useState<WebSocket | null>(null)

  React.useEffect(() => {
    setWebSocket(connect())

    return () => webSocket?.close()
  }, [])

  const connect = () => {
    const webSocket = new WebSocket(socketUrl)

    webSocket.onmessage = (e: MessageEvent) => {
      const data = JSON.parse(e.data)
      if (data.event) {
        dispatch(setWebSocketData(data.event))
      }
    }

    webSocket.onclose = () => {
      setTimeout(connect, 3000)
    }

    return webSocket
  }
}
