import * as React from 'react'
import { AppFooter } from '@components/footer/app-footer'

interface Props {
  children?: React.ReactNode
}

export const AppFooterSection = ({ children }: Props): JSX.Element => (
  <>
    {children}
    <AppFooter />
  </>
)
