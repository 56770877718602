import * as React from 'react'
import { OfferDetailsAgreements } from '@modules/offers/common/details/personal-data/offer-details-agreements'
import { useFormContext, useWatch } from 'react-hook-form'
import { CodesConversionFormInputs } from '@modules/offers/codes-conversion/details/index'

export const CodesConversionAgreements = (): JSX.Element => {
  const { control } = useFormContext<CodesConversionFormInputs>()
  const code = useWatch({ control, name: 'selectedCode' })

  return <OfferDetailsAgreements rulesUrl={code?.urls?.rules || ''} />
}
