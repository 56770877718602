import * as React from 'react'
import * as clsx from 'clsx'
import { OfferSelectionButton } from '@modules/offers/common/details/offer-selection-button'
import { formatPriceShort } from '@helpers/price-helper'
import { IconWithText } from '@components/icon-with-text'
import { ConvertableCodeAvailableConversion } from '@modules/offers/codes-conversion/models'
import { useFormContext, useWatch } from 'react-hook-form'
import { CodesConversionFormInputs } from '@modules/offers/codes-conversion/details/index'
import { useCodesConversionGtmEvents } from '@modules/offers/codes-conversion/details/use-codes-conversion-gtm-events'

type CodeVariant = 'gold' | 'silver' | 'gray' | 'brown' | 'default'

interface Props {
  className?: string
  variant: CodeVariant
  title: React.ReactNode
  arrowsCount: number
  children: React.ReactNode
  isCurrent: boolean
  isLowerOption?: boolean
  conversion?: ConvertableCodeAvailableConversion
  field: 'selectedConversionKind' | 'selectedConversionLocalization'
}

export const CodesConversionVariantItem = ({
  className,
  variant,
  title,
  arrowsCount,
  children,
  isCurrent,
  isLowerOption = false,
  conversion,
  field,
}: Props): React.ReactElement => {
  const { sendAddToCardEvent } = useCodesConversionGtmEvents()
  const { setValue, control } = useFormContext<CodesConversionFormInputs>()

  const fieldValue = useWatch({ control, name: field })

  const isSelected = fieldValue && conversion && fieldValue?.option_kind === conversion?.option_kind

  const handleSelect = () => {
    if (!conversion) return
    setValue(field, isSelected ? null : conversion)
    sendAddToCardEvent(conversion)
  }

  const arrows = [...Array(arrowsCount).keys()]

  return (
    <div className="position-relative">
      {isCurrent && <div className="codes-conversion__variant-item__active-code-label">Twój kod</div>}
      <div
        className={clsx(
          'codes-conversion__variant-item',
          `is-${variant}`,
          {
            'is-current': isCurrent,
            'is-disabled': isLowerOption,
            'is-card-selected': isSelected,
            'is-another-card-selected': fieldValue && !isSelected,
          },
          className,
        )}
      >
        <div className="col-sm-3 col-4 codes-conversion__variant-item__coupon">
          {title}
          {arrows.map((_, index) => (
            <img
              key={index}
              src={require('@assets/images/arrow.svg')}
              alt="Ulepszanie kodów"
              className={clsx(`codes-conversion__arrow codes-conversion__arrow__${index}`)}
            />
          ))}
        </div>

        <div className="col-sm-9 col-8 d-flex justify-content-between flex-wrap">
          <div className="d-flex ps-4 pt-3 pb-sm-3 pb-2">
            <i className="uil-check codes-conversion__variant-item__check" />
            {children}
          </div>
          {isCurrent ? (
            <IconWithText
              text="Twój aktualny kod"
              iconClassName="uil-check text-primary font-xxl me-2"
              textClassName="font-sm"
              className="me-4 ps-4 ps-xs-0"
            />
          ) : isLowerOption ? (
            <IconWithText
              text="Niedostępny"
              iconClassName="uil-exclamation-circle font-xxl me-2"
              className="me-4 ps-4 ps-xs-0"
            />
          ) : (
            conversion && (
              <OfferSelectionButton
                icon=""
                isSelected={!!isSelected}
                onSelectionChange={handleSelect}
                text={`Dopłata +${formatPriceShort(conversion.price_brutto)}`}
                className="mt-auto ms-auto"
              />
            )
          )}
        </div>
      </div>
    </div>
  )
}
