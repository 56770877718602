import * as React from 'react'
import { OfferModal } from '@modals/offer-modal/offer-modal'
import { BaseModalProps } from '@modals/types'
import { formatPrice } from '@helpers/price-helper'
import { IconWithText } from '@components/icon-with-text'

export const PackageVipOfferModal = ({ toggleIsVisible }: BaseModalProps): JSX.Element => {
  return (
    <OfferModal
      title="Pakiet VIP"
      toggleIsVisible={toggleIsVisible}
      price={formatPrice(200)}
      requiredRulesKeywords={[]}
      onSubmit={async () => {
        console.log('submit')
      }}
    >
      <div>
        <h4 className="text-primary">Poznaj Pakiet VIP dla wymagających Gości</h4>
        <p className="font-sm">
          Dodatkowe atrakcje, rabaty, liczne przywileje, a także butelka wyselekcjonowanego wina dodatkowo uatrakcyjnią
          pobyt i zapewnią komfort wypoczynku na najwyższym poziomie.
        </p>
        <div>
          <span className="text-primary mt-4 fw-semi-bold">Co zyskujesz wykupując pakiet VIP?</span>
          <div className="mt-3">
            <div className="d-block flex-column" style={{ columnCount: 2 }}>
              {vipImprovements.map(improvement => (
                <IconWithText
                  key={improvement.title}
                  textClassName="fw-normal font-sm align-self-start"
                  className="d-inline-flex w-100"
                  text={
                    <>
                      <span>{improvement.title} </span>
                      {!!improvement.discount && <span className="text-primary">{improvement.discount}</span>}
                    </>
                  }
                  iconClassName="d-block uil-check text-primary me-1 font-xl align-self-start"
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </OfferModal>
  )
}

const vipImprovements = [
  { title: 'Bezpłatna możliwość wybrania domku' },
  { title: 'Możliwość późniejszego wymeldowania - do godz. 12.00' },
  { title: 'Mozliwość wcześniejszego zameldowania - od godz. 13.00' },
  { title: 'Grill elektryczny' },
  { title: 'Mikrofalówka' },
  { title: '4 reczniki plażowe VIP' },
  { title: 'Kosmetyki VIP' },
  { title: 'Wino czerwone premium' },
  { title: 'Owoce - banany, pomarańcze, kiwi, owoce sezonowe' },
  { title: 'Zestaw beauty' },
  { title: 'Oznaczenie domku flagą VIP' },
  { title: 'Dziecko jako animator przez 3 godziny' },
  { title: 'Wynajem rowerów' },
  { title: 'Rabat w bistro HPR w godzinach 11:00 – 12:30' },
  { title: 'Warsztaty kulinarne dla dzieci 5x', discount: '(rabat 50%)' },
  { title: 'Zajęcia dla dzieci z animatorem w Holiday Kids Klub (5 godz.) *', discount: '(rabat 50%)' },
  { title: 'Okulary VR (5 godz.) *', discount: '(rabat 50%)' },
  { title: 'Krówki VIP' },
  { title: 'Opaski na rękę VIP' },
  { title: 'Pamiątkowy magnes' },
  { title: 'Ekspres do kawy z zestawem 20 kapsułek' },
  { title: 'Zegarek GPS dla dzieci' },
  { title: 'Loża VIP', discount: '(rabat 50%)' },
]
