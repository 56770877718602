import * as React from 'react'
import { formatRemainingTime } from '@helpers/date-formatter'

interface UseTimeElapseResponse {
  remainingTime: string
  isCalculating: boolean
}

export const useRemainingTime = (
  date: string | Date,
  remainingTimeFormatter?: ({ years, months, days, hours, minutes, seconds }: Duration) => string,
): UseTimeElapseResponse => {
  const [remainingTime, setRemainingTime] = React.useState('')

  React.useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime(formatRemainingTime(date, remainingTimeFormatter))
    }, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [date, remainingTimeFormatter])

  return {
    remainingTime,
    isCalculating: remainingTime === '',
  }
}
