import * as React from 'react'
import { SpinInput } from '@components/spin-input'
import { useFormContext } from 'react-hook-form'

export const MIN_GASTRO_CARD_AMOUNT = 500
const MAX_GASTRO_CARD_AMOUNT = 100000

export const BaseGastroCardAmountInput = (): JSX.Element => {
  const { setValue, watch } = useFormContext()

  const amount = watch('declared_sell_price')

  const updateValue = (amount: number) => {
    setValue('declared_sell_price', amount)
  }

  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value || '0')
    updateValue(Math.max(value || 0, MIN_GASTRO_CARD_AMOUNT))
  }

  const handleButtonClickValueChange = (value: number) => {
    setValue('declared_sell_price', Math.max(Math.min(value, MAX_GASTRO_CARD_AMOUNT), MIN_GASTRO_CARD_AMOUNT))
  }

  return (
    <SpinInput
      max={MAX_GASTRO_CARD_AMOUNT}
      value={amount}
      step={50}
      onBlur={handleBlur}
      onDecrement={handleButtonClickValueChange}
      onIncrement={handleButtonClickValueChange}
      onChange={updateValue}
    />
  )
}
