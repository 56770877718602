import * as React from 'react'
import * as clsx from 'clsx'
import { useToggle } from '@hooks/use-toggle'
import { IconWithText } from '@components/icon-with-text'
import { Button } from 'reactstrap'

interface Props {
  children: React.ReactNode
  isCollapsable?: boolean
  bottomElement?: React.ReactNode
  bottomElementClassName?: string
}

export const ExpandableProductDescription = ({
  children,
  isCollapsable = false,
  bottomElement,
  bottomElementClassName,
}: Props): JSX.Element => {
  const [isCollapsed, setIsCollapsed] = useToggle()

  return (
    <div
      className={clsx('expandable-product-description__wrapper', {
        'is-collapsed': isCollapsed,
        'is-full-height': !isCollapsable,
      })}
    >
      <div className="mt-2 text-darker-gray font-md col-12 col-sm-10 expandable-product-description">{children}</div>
      {isCollapsable && (
        <div className={clsx('d-flex align-items-center', bottomElementClassName)}>
          <Button onClick={setIsCollapsed} className="expandable-product-description__button">
            <IconWithText
              text={isCollapsed ? 'zwiń szczegóły' : 'rozwiń szczegóły'}
              iconClassName="uil-angle-down font-xl lh-1 expandable-product-description__toggle__icon text-darker-gray me-1 font-lg"
              textClassName="text-darker-gray font-sm fw-bold lh-1"
            />
          </Button>
          {bottomElement}
        </div>
      )}
    </div>
  )
}
