/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NotificationEvents } from '@requests/web-sockets/models'

interface State {
  payload: any
  message: NotificationEvents | undefined
}

const initialState: State = {
  payload: null,
  message: undefined,
}

export const webSocketSlice = createSlice({
  name: 'webSocket',
  initialState,
  reducers: {
    setWebSocketData(state, action: PayloadAction<any>) {
      state.payload = action.payload.payload
      state.message = action.payload.type
    },
  },
})

export const { setWebSocketData } = webSocketSlice.actions

export default webSocketSlice.reducer
