import axios, { AxiosRequestConfig } from 'axios'
import { BaseQueryApi } from '@reduxjs/toolkit/src/query/baseQueryTypes'
import { RootState } from '@store/index'
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { TypedUseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import * as queryString from 'querystring'
import { formatDate } from '@helpers/date-formatter'

// eslint-disable-next-line
export type TypedQueryResult<T> = TypedUseQueryStateResult<T, unknown, any> & { refetch: () => void }

const apiInstance = axios.create({
  baseURL: '',
  headers: { 'X-Requested-With': 'XMLHttpRequest' },
  responseType: 'json',
  paramsSerializer: params => {
    const payload = {}
    Object.entries(params).map(([key, value]) => {
      if (value instanceof Date) {
        payload[key] = formatDate(value, 'yyyy-MM-dd HH:mm')
      } else {
        payload[key] = value
      }
    })
    return queryString.stringify(payload)
  },
})

export interface BaseQueryParams {
  url: string
  method: AxiosRequestConfig['method']
  data?: AxiosRequestConfig['data']
  params?: AxiosRequestConfig['params']
  // eslint-disable-next-line
  responseTransformer?: (result) => any
}

export const axiosBaseQuery = async <ResultType = unknown>({
  url,
  method,
  data,
  params,
  responseTransformer,
}: BaseQueryParams): Promise<QueryReturnValue<ResultType, FetchBaseQueryError>> => {
  try {
    const result = await apiInstance({ url, method, data, params })
    const response = result.data

    return { ...result, data: (responseTransformer ? responseTransformer(response) : response) as ResultType }
  } catch (error) {
    return { error }
  }
}

export const apiQueryFunction =
  <ResultType, Params>(action: (state: RootState, data: Params) => BaseQueryParams) =>
  // eslint-disable-next-line
  async (data: Params, { getState }: BaseQueryApi) =>
    (await axiosBaseQuery<ResultType>(action(getState(), data))) as QueryReturnValue<ResultType, FetchBaseQueryError>
