import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { useModal } from '@modals/use-modal'
import { useAppSelector } from '@store/index'
import { selectClientDetails } from '@store/slices/app-slice'
import { HeaderUserMenu } from '@components/header/app-user-menu'

export const HeaderUserInfo = (): JSX.Element | null => {
  const client = useAppSelector(selectClientDetails)
  const [showLoginModal] = useModal('LoginModal')

  const text = client ? (
    <HeaderUserMenu client={client} />
  ) : (
    <span className="ps-3">
      Zaloguj się / <span className="text-nowrap">Utwórz konto</span>
    </span>
  )

  return (
    <IconWithText
      text={text}
      textClassName="fw-bold font-sm"
      iconClassName="uil-user font-xxl d-sm-inline d-none"
      className="py-2 text-end"
      {...(!client && { onClick: showLoginModal })}
    />
  )
}
