import * as React from 'react'
import { UnderlineText } from '@components/underline-text'
import { formatPercentage, formatPriceShort } from '@helpers/price-helper'
import Decimal from 'decimal.js'
import { GastroCardMultiplier } from '@models/app-state'
import { useFormContext } from 'react-hook-form'
import { useGastroDynamicMultipliers } from '@modules/offers/gastro-card/dynamic/details/use-gastro-dynamic-multipliers'

interface Props {
  nextOffer: GastroCardMultiplier
  amount: string
}

export const GastroCardDynamicHigherBonusUpgradeButton = ({ nextOffer, amount }: Props): React.ReactNode => {
  const { getPercentageBonus } = useGastroDynamicMultipliers()
  const { setValue } = useFormContext()

  const amountToUpgradeToNextOffer = new Decimal(nextOffer.minimal_amount).minus(amount).toString()

  const handleBonusUpgrade = () => {
    setValue('declared_sell_price', Number(nextOffer?.minimal_amount))
  }

  return (
    <div className="font-xs mt-2">
      <button className="btn-unstyled me-1" onClick={handleBonusUpgrade}>
        <i className="uil-plus-circle text-primary font-md me-1 lh-1" />
        <UnderlineText
          text={`Dodaj ${formatPriceShort(amountToUpgradeToNextOffer!)}, `}
          textClass="text-primary fw-bold"
          underlineColor="#b99153"
        />
      </button>{' '}
      aby zwiększyć bonus do{' '}
      <strong className="text-primary">+{formatPercentage(getPercentageBonus(nextOffer))}</strong>
    </div>
  )
}
