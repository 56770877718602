import { NotificationEvents } from '@requests/web-sockets/models'
import { useWebSocket } from '@requests/web-sockets/use-web-socket'
import { BookingOffer } from '@modules/booking-offers/models'
import { useBookingOfferOptimisticUpdate } from '@modules/booking-offers/use-booking-offer-optimistic-update'

export const useBookingOffersWebsocket = (): void => {
  const { updateOffer } = useBookingOfferOptimisticUpdate()

  useWebSocket<BookingOffer[], []>((message, payload) => {
    switch (message) {
      case NotificationEvents.BOOKING_OFFER_CHANGE:
        updateOffer(payload)
        break
    }
  }, [])
}
