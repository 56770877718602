import * as React from 'react'
import { useSubscriptionWithBenefits } from '@modules/offers/subscription-with-benefit/details/use-subscription-with-benefits'
import { OfferDetailsAgreements } from '@modules/offers/common/details/personal-data/offer-details-agreements'

export const SubscriptionWithBenefitAgreements = (): JSX.Element => {
  const { products } = useSubscriptionWithBenefits()

  const product = products.optimum?.house?.regular_client[0]

  return <OfferDetailsAgreements rulesUrl={product?.urls?.rules || ''} />
}
