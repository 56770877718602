import { createCartItem, useGtmEvents } from '@hooks/use-gtm-events'
import { useAppSelector } from '@store/index'
import { selectAppData } from '@store/slices/app-slice'
import { GoHolidayProductPrefix } from '@modules/offers/go-holiday/use-go-holiday-products'
import { Subscription } from '@models/app-state'

interface Response {
  sendAddToCartEvent: (product: Subscription) => void
  sendBeginCheckoutEvent: (product: Subscription) => void
  sentViewItemListEvent: () => void
}

export const useGoHolidayGtmEvents = (): Response => {
  const { subscriptions } = useAppSelector(selectAppData)
  const { viewItemList, addToCart, beginCheckout } = useGtmEvents()

  const sentViewItemListEvent = () => {
    const goHolidayProducts = subscriptions.filter(subscription => subscription.code.startsWith(GoHolidayProductPrefix))

    if (!goHolidayProducts.length) return
    viewItemList(goHolidayProducts.map(createGoHolidayEcommerceItem), 'go-holiday')
  }

  const sendAddToCartEvent = (product: Subscription) => {
    addToCart(product.subscription_product.price_brutto, createGoHolidayEcommerceItem(product))
  }

  const sendBeginCheckoutEvent = (product: Subscription) => {
    beginCheckout(product.subscription_product.price_brutto, [createGoHolidayEcommerceItem(product)])
  }

  return {
    sentViewItemListEvent,
    sendAddToCartEvent,
    sendBeginCheckoutEvent,
  }
}

const createGoHolidayEcommerceItem = (item: Subscription, index?: number) =>
  createCartItem({
    index,
    name: item.name,
    id: item.subscription_product.id,
    price: item.subscription_product.price_brutto,
    itemListName: 'go-holiday',
  })
