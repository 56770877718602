import * as React from 'react'
import { commonObjectPost } from '@requests/basic-requests'
import { useFormContext, useWatch } from 'react-hook-form'
import { BaseFormFields, OfferPaymentBar } from '@modules/offers/common/details/personal-data/offer-payment-bar'
import { CodesConversionFormInputs } from '@modules/offers/codes-conversion/details/index'
import { CodesConversionPaymentWrapper } from '@modules/offers/codes-conversion/details/codes-conversion-payment-wrapper'

export const CodesConversionPayment = (): JSX.Element => {
  const { control } = useFormContext<CodesConversionFormInputs>()

  const [selectedCode, selectedConversionKind, selectedConversionLocalization, codeOptionPurchaseRules] = useWatch({
    control,
    name: ['selectedCode', 'selectedConversionKind', 'selectedConversionLocalization', 'code_option_purchase_rules'],
  })

  const paymentAction = async (baseFields: BaseFormFields) => {
    return await commonObjectPost<{ urls: { sale: string } }>(selectedCode!.urls.sale, {
      option_kinds: [
        ...(selectedConversionKind ? [selectedConversionKind.option_kind] : []),
        ...(selectedConversionLocalization ? [selectedConversionLocalization.option_kind] : []),
      ],
      code_option_purchase_rules: codeOptionPurchaseRules,
      ...baseFields,
    })
  }

  const totalPrice =
    parseFloat(selectedConversionKind?.price_brutto ?? '0') +
    parseFloat(selectedConversionLocalization?.price_brutto ?? '0')

  return (
    <CodesConversionPaymentWrapper
      selectedCode={selectedCode}
      selectedConversionKind={selectedConversionKind}
      selectedConversionLocalization={selectedConversionLocalization}
      totalPrice={totalPrice.toString()}
    >
      <OfferPaymentBar
        paymentAction={paymentAction}
        canSubmit={!!selectedConversionKind || !!selectedConversionLocalization}
        totalPrice={totalPrice.toString()}
        rulesKeywords={['code_option_purchase_rules']}
      />
    </CodesConversionPaymentWrapper>
  )
}
