import * as React from 'react'
import { BaseGastroCardAmount } from '@modules/offers/common/base-gastro-card-amount/base-gastro-card-amount'
import { formatPriceShort } from '@helpers/price-helper'

const REGULAR_FACTOR = 2
const SUBSCRIPTION_FACTOR = 2

export const GastroCardAmount = (): JSX.Element => {
  return (
    <BaseGastroCardAmount
      regularFactor={REGULAR_FACTOR}
      subscriptionFactor={SUBSCRIPTION_FACTOR}
      kindClassName="is-gastro-card"
      sectionTitle={
        <span className="text-secondary">
          <strong>Dodaj</strong> swój <br /> <strong>voucher</strong>
        </span>
      }
      centerCardTitle={<p className="mb-5">Otrzymujesz</p>}
    >
      {({ amountToSpent }) => (
        <div className="text-center text-md-start">
          <span className="text-primary font-xxxl fw-semi-bold d-block">Twój voucher</span>
          <span className="font-xxxl text-white lh-1 fw-semi-bold d-block mt-3 pt-5">
            Podwójna Radość <br /> 2026/2027
          </span>

          <section className="mt-5 mb-5 mb-lg-3">
            <span className="text-primary fw-semi-bold font-xl">Wartość vouchera:</span>
            <span className="font-header fw-bold d-block text-white">{formatPriceShort(amountToSpent)}</span>
          </section>
        </div>
      )}
    </BaseGastroCardAmount>
  )
}
