import * as React from 'react'

interface Props {
  title: string
  subtitle: string
  leftSection: React.ReactNode
  footer?: React.ReactNode
  children: React.ReactNode
}

export const CodesConversionBlock = ({ title, subtitle, leftSection, children, footer }: Props): React.ReactElement => (
  <div className="bg-light-gray rounded mt-3 px-sm-5 px-3 py-4 d-flex flex-wrap justify-content-between">
    <div className="col-sm-4 col-12 d-flex flex-column flex-wrap">
      <div className="flex-1 text-center text-sm-start">
        <span className="font-xxl">{title}</span>
        <span className="fw-semi-bold d-block font-xxl lh-initial">{subtitle}</span>
        <div className="d-flex py-3 col-12 text-start flex-wrap">{leftSection}</div>
      </div>
      <div className="mt-auto">{footer}</div>
    </div>
    <div className="col-sm-7 col-12">{children}</div>
  </div>
)
