import * as React from 'react'
import clsx from 'clsx'
import { useRemainingTime } from '@hooks/use-remaining-time'
import { parseISO } from 'date-fns'
import { IconWithText } from '@components/icon-with-text'
import { GastroCardMultiplier } from '@models/app-state'
import { Spinner } from '@components/loaders/spinner'

interface Props {
  gastroProductMultiplier: GastroCardMultiplier
  spinnerClassName?: string
}

export const GastroCardDynamicOfferExpirationTime = ({
  gastroProductMultiplier,
  spinnerClassName,
}: Props): React.ReactNode => {
  const { remainingTime, isCalculating: isRemainingTimeCalculating } = useRemainingTime(
    parseISO(gastroProductMultiplier.active_to),
  )

  return (
    <IconWithText
      text={
        isRemainingTimeCalculating ? <Spinner className={clsx('spinner-border-sm', spinnerClassName)} /> : remainingTime
      }
      iconClassName={isRemainingTimeCalculating ? '' : 'uil-clock-five me-2 font-xl'}
      className="ms-1"
    />
  )
}
