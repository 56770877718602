import { useFormRequest } from '@hooks/use-request'
import { commonObjectPost } from '@requests/basic-requests'
import { ClientDetails } from '@models/client'
import { selectAccountUrls, setClientDetails } from '@store/slices/app-slice'
import { CLIENT_APP_URLS } from '@helpers/consts'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '@store/index'
import { FieldPath, UseFormReturn } from 'react-hook-form'
import { FieldValues } from 'react-hook-form/dist/types/fields'

export interface LoginFormInputs {
  username: string
  password: string
}

interface Response {
  redirectToPasswordReset: () => void
  redirectToAccountCreation: () => void
  login: (username: string, password: string, onSuccess?: () => void) => void
  isLoginPending: boolean
}

export const useLogin = <T extends FieldValues>(methods: UseFormReturn<T>): Response => {
  const { login: loginUrl } = useSelector(selectAccountUrls)
  const dispatch = useAppDispatch()

  const { action: login, isLoading: isLoginPending } = useFormRequest(
    async (username: string, password: string, onSuccess?: () => void) => {
      methods.clearErrors('password' as FieldPath<T>)

      try {
        const data = await commonObjectPost<{ client_details: ClientDetails }>(loginUrl, { password, username })
        dispatch(setClientDetails(data.client_details))
        onSuccess?.()
      } catch (error) {
        if (error.response?.status === 403) {
          methods.setError('password' as FieldPath<T>, { message: 'Nieprawidłowe dane' })
        }
      }
    },
    methods.setError,
  )

  const redirectToPasswordReset = () => {
    window.open(CLIENT_APP_URLS.RESET_PASSWORD, '_blank')
  }

  const redirectToAccountCreation = () => {
    window.open(CLIENT_APP_URLS.ACCOUNT_CREATE, '_blank')
  }

  return {
    redirectToPasswordReset,
    redirectToAccountCreation,
    login,
    isLoginPending,
  }
}
