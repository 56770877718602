import * as React from 'react'
import { FooterRules } from '@components/footer/footer-rules'

export const AppFooter = (): JSX.Element => {
  const scrollTop = React.useCallback(() => window.scrollTo(0, 0), [])

  return (
    <div className="app-footer">
      <div className="bg-dark-blue app-footer__background py-5">
        <div className="container text-white d-flex align-items-center justify-content-center flex-wrap flex-column-reverse flex-sm-row">
          <div className="col-4 d-sm-block d-none">
            <a href="https://holidaypark.pl">
              <img src={require('@assets/images/logo_holiday_sklep_white.svg')} alt="holidaypark logo" height="40" />
            </a>
          </div>
          <div
            className="col-sm-4 col-12 justify-content-center align-items-center d-inline-flex mt-5 mt-sm-0"
            onClick={scrollTop}
          >
            <span className="d-sm-none me-3">Wróć na górę</span>
            <img
              src={require('@assets/images/icons/arrow-top.svg')}
              height="33"
              alt="Scroll to top"
              className="cursor-pointer"
            />
          </div>
          <div className="d-flex gap-2 align-items-center col-4 d-flex flex-sm-row flex-column justify-content-end font-sm">
            <div className="d-flex gap-5">
              {socialMedia.map(item => (
                <a key={item.alt} href={item.url} target="_blank">
                  <img alt={item.alt} src={item.image} height="25" />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
      <FooterRules />
    </div>
  )
}

const socialMedia = [
  {
    url: 'https://pl-pl.facebook.com/HolidayParkAndResort/',
    image: require('@assets/images/icons/facebook.svg'),
    alt: 'Holiday Park & Resort Facebook',
  },
  {
    url: 'https://www.instagram.com/holiday_park_resort/',
    image: require('@assets/images/icons/instagram.svg'),
    alt: 'Holiday Park & Resort Instagram',
  },
  {
    url: 'https://www.youtube.com/@holidayparkresort1678',
    image: require('@assets/images/icons/youtube.svg'),
    alt: 'Holiday Park & Resort YouTube',
  },
  {
    image: require('@assets/images/icons/trip-advisor.svg'),
    alt: 'Holiday Park & Resort Trip Advisor',
  },
  {
    image: require('@assets/images/icons/booking.svg'),
    alt: 'Holiday Park & Resort Booking',
  },
]
