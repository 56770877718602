import * as React from 'react'
import { formatPriceShort } from '@helpers/price-helper'
import { BaseGastroCardAmountInput } from '@modules/offers/common/base-gastro-card-amount/base-gastro-card-amount-input'

export const BaseGastroCardAmountBox = (): JSX.Element => (
  <div className="gastro-card-boost__amount-box">
    <span className="text-darker-gray text-center d-block font-xxxl lh-initial fw-semi-bold">Wpłacasz</span>
    <div className="my-4 col-8 mx-auto">
      <BaseGastroCardAmountInput />
    </div>

    <span className="d-block text-center font-md">
      Minimalna kwota zakupu <br />
      to {formatPriceShort(500)}.
    </span>
  </div>
)
