import * as React from 'react'
import { CodesConversionBlock } from '@modules/offers/codes-conversion/details/codes-conversion-block'
import { CodesConversionVariantItem } from '@modules/offers/codes-conversion/details/codes-conversion-variant-item'
import {
  ConvertableCode,
  ConvertableCodeAvailableConversion,
  ConvertableCodeSubscriptionKind,
} from '@modules/offers/codes-conversion/models'
import { toDefaultDateFormat } from '@helpers/date-formatter'
import { AlertMessage } from '@modules/offers/codes-conversion/alert-message'

interface Props {
  selectedCode: ConvertableCode | null
  conversions: ConvertableCodeAvailableConversion[]
}

export const CodesConversionVariantBlock = ({ selectedCode, conversions }: Props): React.ReactElement | null => {
  if (!conversions.length && selectedCode?.subscription_kind !== 'gold') return null

  const getConversion = (kind: ConvertableCodeSubscriptionKind) =>
    conversions.find(conversion => conversion.option_kind.endsWith(`_${kind}`))

  const isKindActive = (kind: ConvertableCodeSubscriptionKind) => selectedCode?.subscription_kind === kind

  const isLowerOption = (kind: ConvertableCodeSubscriptionKind) => {
    if (selectedCode?.subscription_kind === 'gold' && ['silver', 'brown', 'gray'].includes(kind)) return true
    if (selectedCode?.subscription_kind === 'silver' && ['brown', 'gray'].includes(kind)) return true
    if (selectedCode?.subscription_kind === 'brown' && kind === 'gray') return true
    return false
  }

  const hasConversion = (kind: ConvertableCodeSubscriptionKind) =>
    getConversion(kind) || selectedCode?.subscription_kind === 'gold' || selectedCode?.subscription_kind === kind

  const createVariant = (kind: ConvertableCodeSubscriptionKind, title: string, date: string, level: number) =>
    hasConversion(kind) ? [{ variant: kind, title: title, date, level }] : []

  const variantCards = [
    ...createVariant('gold', 'złotego', '26.08 - 07.07', 4),
    ...createVariant('silver', 'srebrnego', '01.09 - 30.06*', 3),
    ...createVariant('brown', 'brązowego', '01.10 - 31.05*', 2),
    ...createVariant('gray', 'szarego', '01.09 - 14.06*', 1),
  ] as { variant: ConvertableCodeSubscriptionKind; title: string; date: string; level: number }[]

  return (
    <CodesConversionBlock
      title="Ulepsz swój kod"
      subtitle="wybierając wyższy pakiet"
      leftSection={<LeftSection selectedCode={selectedCode} />}
      footer={<span className="font-sm">* Z wyłączeniem Terminów Specjalnych</span>}
    >
      {variantCards.map(item => (
        <CodesConversionVariantItem
          key={item.variant}
          field="selectedConversionKind"
          isCurrent={isKindActive(item.variant)}
          isLowerOption={isLowerOption(item.variant)}
          arrowsCount={item.level}
          variant={item.variant}
          className="mb-2"
          title={<Title text={item.title} />}
          conversion={getConversion(item.variant)}
        >
          <UsageDate date={item.date} />
        </CodesConversionVariantItem>
      ))}
    </CodesConversionBlock>
  )
}

const LeftSection = ({ selectedCode }: { selectedCode: ConvertableCode | null }) => (
  <>
    <div className="d-flex">
      <i className="uil-check codes-conversion__variant-item__check is-default" />
      {selectedCode ? `Kod jest ważny do dnia ${toDefaultDateFormat(selectedCode.expire_after)}` : ''}
    </div>

    {selectedCode?.subscription_kind === 'gold' && (
      <AlertMessage message="Wpisany kod jest w najwyższej wersji. Nie możesz go ulepszyć." className="w-100" />
    )}
  </>
)

const UsageDate = ({ date }) => (
  <div>
    <div>Wykorzystasz w dniach</div>
    <div className="border rounded bg-lighter-gray fw-semi-bold py-1 px-2 font-xs d-inline-block">{date}</div>
  </div>
)

const Title = ({ text }) => (
  <span>
    Kod <br />
    pakietu <br />
    {text}
  </span>
)
