import * as React from 'react'
import { CodesConversionBlock } from '@modules/offers/codes-conversion/details/codes-conversion-block'
import { CodesConversionVariantItem } from '@modules/offers/codes-conversion/details/codes-conversion-variant-item'
import { ConvertableCode, ConvertableCodeAvailableConversion } from '@modules/offers/codes-conversion/models'
import { AlertMessage } from '@modules/offers/codes-conversion/alert-message'

interface Props {
  conversions: ConvertableCodeAvailableConversion[]
  selectedCode: ConvertableCode | null
}

export const CodesConversionLocalizationBlock = ({ selectedCode, conversions }: Props): React.ReactElement | null => {
  const hasLocalizationConversion = conversions.some(conversion => conversion.option_kind === 'localization')

  if (!hasLocalizationConversion && selectedCode?.localization_kind !== 'universal') return null

  const localizationConversion = conversions.find(conversion => conversion.option_kind === 'localization')

  return (
    <CodesConversionBlock
      title="Nie lubisz ograniczeń?"
      subtitle="Zmień kod na uniwersalny"
      leftSection={<LeftSection isUniversal={selectedCode?.localization_kind === 'universal'} />}
    >
      <CodesConversionVariantItem
        field="selectedConversionLocalization"
        conversion={localizationConversion}
        isCurrent={selectedCode?.localization_kind === 'universal'}
        arrowsCount={4}
        variant="default"
        className="mb-2"
        title={
          <span className="text-darker-gray">
            Wszystkie <br /> ośrodki
          </span>
        }
      >
        <Usage title="Wykorzystasz" bannerText="nad morzem i w górach" />
      </CodesConversionVariantItem>
      <CodesConversionVariantItem
        field="selectedConversionLocalization"
        isCurrent={['sea', 'mountains'].includes(selectedCode?.localization_kind ?? '')}
        isLowerOption={selectedCode?.localization_kind === 'universal'}
        arrowsCount={0}
        variant="default"
        className="mb-2"
        title={
          <span className="text-darker-gray">
            Ośrodki <br />
            {selectedCode?.localization_kind === 'sea' && 'nadmorskie'}
            {selectedCode?.localization_kind === 'mountains' && 'górskie'}
          </span>
        }
      >
        <Usage
          title="Wykorzystasz w ośrodkach"
          bannerText={getBannerText(selectedCode?.localization_kind) ?? 'tylko nad morzem'}
        />
      </CodesConversionVariantItem>
    </CodesConversionBlock>
  )
}

const LeftSection = ({ isUniversal }) => (
  <>
    <div className="d-flex col-sm-11 col-12">
      <i className="uil-check codes-conversion__variant-item__check is-default" />
      <span>
        Dzięki konwersji będziesz mógł wykorzystać kod w dowolnym z 7 ośrodków{' '}
        <span className="text-nowrap">(nad morzem i w górach)</span>
      </span>
    </div>

    {isUniversal && <AlertMessage message="Wpisany kod jest uniwersalny." className="w-100" />}
  </>
)

const getBannerText = kind =>
  ({
    sea: 'nad morzem',
    mountains: 'w górach',
  })[kind]

const Usage = ({ title, bannerText }) => (
  <div>
    <div>{title}</div>
    {bannerText && (
      <div className="border rounded bg-lighter-gray fw-semi-bold py-1 px-2 font-xs d-inline-block">{bannerText}</div>
    )}
  </div>
)
