import * as React from 'react'
import { NavigationPath } from '@models/routes'
import { GastroCardBaseSubscriptionBonusesInformation } from '@modules/offers/common/base-gastro-card-amount/gastro-card-base-subscription-bonuses-information'
import { useModal } from '@modals/use-modal'

interface Props {
  subscriptionPercentageBonus: number
}

export const GastroCardSubscriptionBonusesInformation = ({ subscriptionPercentageBonus }: Props): JSX.Element => {
  const [showLoginModal] = useModal('LoginModal')

  return (
    <GastroCardBaseSubscriptionBonusesInformation
      noActiveSubscriptionMessage={
        <span>
          <a
            href={NavigationPath.SubscriptionContract}
            target="_blank"
            className="fw-bold text-darker-gray text-decoration-none"
          >
            Zakup subskrypcję
          </a>
          , aby zwiększyć bonus do {subscriptionPercentageBonus}%
        </span>
      }
      activeSubscriptionMessage={
        <span>
          <strong>Wszystko gotowe</strong>, skorzystaj z bonusu {subscriptionPercentageBonus}%
        </span>
      }
      noClientMessage={
        <span>
          <button className="btn-unstyled fw-bold" onClick={showLoginModal}>
            Zaloguj się
          </button>
          , aby zwiększyć bonus do {subscriptionPercentageBonus}%
        </span>
      }
    />
  )
}
