import { useAppDispatch } from '@store/index'
import * as React from 'react'
import { setNotificationMessage } from '@store/slices/notification-messages-slice'
import { NotificationMessage } from '@models/notification-messages'

interface Response {
  notify: (msg: NotificationMessage) => void
}

export const useNotification = (): Response => {
  const dispatch = useAppDispatch()

  React.useEffect(() => {}, [])

  const notify = (message: NotificationMessage) => {
    dispatch(setNotificationMessage(message))
  }

  return {
    notify,
  }
}
