export type EventData<T = unknown> = {
  event: string
  pagePath?: string
  userID?: number | string
  date_range?: string
  number_of_days?: number
} & T

export interface EcommerceEventItem {
  item_id: string | number
  item_name: string
  index: number
  item_brand: string
  item_category: string
  item_list_name: string
  price: string
  quantity?: number
}

export interface EcommerceEventData {
  currency: string
  value: string
  items: Partial<EcommerceEventItem>[]
}

export interface ViewItemListEventData {
  item_list_name: string
}

export enum GTMEvents {
  PageView = 'pageview',
  ViewItemList = 'view_item_list',
  AddToCart = 'add_to_cart',
  BeginCheckout = 'begin_checkout',
}

export enum GTMItemCategory {
  Ecommerce = 'e-sklep',
}
