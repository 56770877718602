import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { useAppSelector } from '@store/index'
import { selectClientDetails } from '@store/slices/app-slice'
import { OfferDetailsPersonalData } from '@modules/offers/common/details/personal-data/offer-details-personal-data'
import { GoHolidayAgreements } from '@modules/offers/go-holiday/details/go-holiday-agreements'
import { GoHolidayManualBox } from '@modules/offers/go-holiday/details/go-holiday-manual-box'

export const GoHolidayPersonalData = (): JSX.Element => {
  const client = useAppSelector(selectClientDetails)
  const { setValue } = useFormContext()

  React.useEffect(() => {
    setValue('clientVariant', client ? 'regular_client' : 'new_client')
  }, [client])

  return (
    <OfferDetailsPersonalData
      agreements={<GoHolidayAgreements />}
      title={
        <span>
          <strong className="d-block">Zamów</strong> swój voucher {client && `${client?.first_name}!`}
        </span>
      }
    >
      <GoHolidayManualBox />
    </OfferDetailsPersonalData>
  )
}
