import * as React from 'react'
import { commonObjectPost } from '@requests/basic-requests'
import { useFormContext, useWatch } from 'react-hook-form'
import { BaseFormFields, OfferPaymentBar } from '@modules/offers/common/details/personal-data/offer-payment-bar'
import { useGastroCardProduct } from '@modules/offers/gastro-card-boost/details/use-gastro-card-product'
import { useSelector } from 'react-redux'
import { selectClientDetails } from '@store/slices/app-slice'
import { hasActiveSubscriptionContract } from '@modules/offers/gastro-card-boost/utils'

export const GastroCardDynamicPayment = (): JSX.Element => {
  const { control, getValues } = useFormContext()

  const gastroCardProduct = useGastroCardProduct('gastro_card_dynamic')

  const client = useSelector(selectClientDetails)

  const hasActiveSubscription = hasActiveSubscriptionContract(client)

  const [amountToSpent, declaredSellPrice] = useWatch({
    control,
    name: ['amountToSpent', 'declared_sell_price'],
  })

  const rules = {
    subscription: 'gastro_card_dynamic_for_subscription_rules',
    regular: 'gastro_card_dynamic_rules',
  } as const

  const paymentAction = async (baseFields: BaseFormFields) => {
    const payload = getValues()

    return await commonObjectPost<{ urls: { sale: string } }>(gastroCardProduct.urls.sale, {
      declared_sell_price: declaredSellPrice,
      ...(hasActiveSubscription
        ? { [rules.subscription]: payload[rules.subscription] }
        : { [rules.regular]: payload[rules.regular] }),
      ...baseFields,
    })
  }

  return (
    <OfferPaymentBar
      paymentAction={paymentAction}
      canSubmit={!!amountToSpent}
      totalPrice={declaredSellPrice}
      rulesKeywords={hasActiveSubscription ? [rules.subscription] : [rules.regular]}
    />
  )
}
