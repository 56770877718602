import { useIntervalWhen } from 'rooks'
import * as React from 'react'
import { intervalToDuration } from 'date-fns'

interface Response {
  duration: string
}

export const useTimeCountUp = (initialCount: number, countWhen: boolean): Response => {
  const [count, setCount] = React.useState(initialCount)

  useIntervalWhen(
    () => {
      setCount(state => state + 1)
    },
    1000,
    countWhen,
  )

  const getDuration = () => {
    const duration = intervalToDuration({ start: 0, end: count * 1000 })
    let durationString = ''

    if (duration.hours) durationString += `${duration.hours}h `
    if (duration.minutes || duration.hours) durationString += `${duration.minutes}m `
    if (duration.seconds || duration.hours || duration.minutes) durationString += `${duration.seconds}s`

    return durationString
  }

  return {
    duration: getDuration(),
  }
}
