import * as React from 'react'
import { Button } from 'reactstrap'
import { IconWithText } from '@components/icon-with-text'
import { BookingOffersFilterSort } from '@modules/booking-offers/filters/booking-offers-filter-sort'
import { BookingOffersFilterSeason } from '@modules/booking-offers/filters/booking-offers-filter-season'
import { useModal } from '@modals/use-modal'
import { BookingOffersAdvancedFiltersFormInputs } from '@modules/booking-offers/filters/advanced-filters/booking-offers-advanced-filters-modal'
import { BookingOffersAggregation } from '@modules/booking-offers/models'
import classNames from 'classnames'

interface Props {
  onFiltersChange: (filters: BookingOffersAdvancedFiltersFormInputs | null) => void
  initialFilters: BookingOffersAdvancedFiltersFormInputs | null
  aggregation: BookingOffersAggregation
}

export const BookingOfferFilters = ({ onFiltersChange, initialFilters, aggregation }: Props): JSX.Element => {
  const [showAdvancedFiltersModal] = useModal('BookingOffersAdvancedFiltersModal', {
    onFiltersChange,
    initialFilters,
    aggregation,
  })

  const handleFiltersClear = () => {
    onFiltersChange(null)
  }

  return (
    <div className="d-flex flex-column flex-md-row justify-content-between mt-md-5 mt-4 flex-wrap">
      <div className="d-flex">
        <Button
          color="light"
          outline
          className="border d-block order-2 order-md-1 btn-mobile-w-100 my-4 my-md-0"
          onClick={showAdvancedFiltersModal}
        >
          <IconWithText
            text="Wyszukiwanie zaawansowane"
            iconClassName="uil-sliders-v-alt text-darker-gray me-2"
            textClassName="text-darker-gray fw-semi-bold"
            className="justify-content-center justify-content-md-start"
          />
        </Button>
        {initialFilters && (
          <Button
            className={classNames('d-block order-2 order-md-1 my-4 my-md-0 btn-transparent border-0', {
              'opacity-1': initialFilters,
            })}
            onClick={handleFiltersClear}
          >
            <IconWithText
              text="Wyzeruj"
              iconClassName="uil-times text-darker-gray me-2 font-xl lh-1"
              textClassName="text-darker-gray fw-semi-bold d-sm-block d-none"
              className="justify-content-center justify-content-md-start ms-2 ms-sm-0"
            />
          </Button>
        )}
      </div>
      <BookingOffersFilterSeason />
      <BookingOffersFilterSort />
    </div>
  )
}
