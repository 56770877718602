import * as React from 'react'
import { CheckButton } from '@components/controls/buttons/check-button'
import { Accommodation } from '@modules/offers/subscription-with-benefit/details/variants/card/subscription-with-benefit-variants-card'
import { useFormContext, useWatch } from 'react-hook-form'
import { OfferDetailsRow } from '@modules/offers/common/details/offer-details-row'

export const SubscriptionContractAccommodationVariants = (): JSX.Element => {
  const { setValue, control } = useFormContext()

  const selectedAccommodationVariant = useWatch({ control, name: 'accommodation' })

  const handleAccommodationTypeChange = (accommodation: Accommodation) => (event: React.MouseEvent) => {
    event.stopPropagation()

    setValue('accommodation', accommodation)
  }

  return (
    <div className="container-xl">
      <OfferDetailsRow
        title={
          <span className="text-secondary">
            <strong className="d-lg-block">Wybierz</strong> typ lokalu
          </span>
        }
      >
        <div className="d-flex flex-wrap gap-3">
          <CheckButton
            className="flex-row-reverse col-lg-5 col-12 p-0 justify-content-between pe-4"
            isActive={selectedAccommodationVariant === 'apartment'}
            onClick={handleAccommodationTypeChange('apartment')}
            title="Apartament"
            description={<span className="fw-semi-bold">Wybierz plan subskrypcji</span>}
            contentClassName="d-flex flex-row-reverse align-items-center"
          >
            <img
              src={require('@assets/images/apartment.webp')}
              alt="Holiday Park & Resort"
              height={78}
              width={120}
              className="me-4 subscription-with-benefit__variant-card__image"
            />
          </CheckButton>

          <CheckButton
            className="flex-row-reverse col-lg-5 col-12 p-0 justify-content-between pe-4"
            isActive={selectedAccommodationVariant === 'house'}
            onClick={handleAccommodationTypeChange('house')}
            title="Domek"
            description={<span className="fw-semi-bold">Wybierz plan subskrypcji</span>}
            contentClassName="d-flex flex-row-reverse align-items-center"
          >
            <img
              src={require('@assets/images/house.webp')}
              alt="Holiday Park & Resort"
              height={78}
              width={120}
              className="me-4 subscription-with-benefit__variant-card__image"
            />
          </CheckButton>
        </div>
      </OfferDetailsRow>
    </div>
  )
}
