import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppData, AppDataAccountUrls, AppDataWithClientDetails } from '@models/app-state'
import { RootState } from '@store/index'
import { ClientDetails } from '@models/client'

interface AppState {
  isAppReady: boolean
  appData: AppData
  clientDetails: null | ClientDetails
}

const initialState: AppState = {
  clientDetails: null,
  isAppReady: false,
  appData: {
    accommodation_types: [],
    subscription_global_dates: [],
    subscriptions: [],
    subscription_rules: [],
    gastro_cards: [],
    subscription_contracts: [],
    subscription_contract_plans: {
      plan_1: { name: '', single_price_brutto: '', accommodation_types: [], urls: { rules: '', sale: '' } },
      plan_2: { name: '', single_price_brutto: '', accommodation_types: [], urls: { rules: '', sale: '' } },
      plan_3: { name: '', single_price_brutto: '', accommodation_types: [], urls: { rules: '', sale: '' } },
      plan_4: { name: '', single_price_brutto: '', accommodation_types: [], urls: { rules: '', sale: '' } },
      plan_5: { name: '', single_price_brutto: '', accommodation_types: [], urls: { rules: '', sale: '' } },
      plan_6: { name: '', single_price_brutto: '', accommodation_types: [], urls: { rules: '', sale: '' } },
      plan_7: { name: '', single_price_brutto: '', accommodation_types: [], urls: { rules: '', sale: '' } },
      plan_8: { name: '', single_price_brutto: '', accommodation_types: [], urls: { rules: '', sale: '' } },
    },
    resorts: [],
    urls: {
      account: {
        reset_password_link: '',
        login: '',
        logout: '',
        register: '',
        reset_password: '',
        user_details: '',
        user_check: '',
      },
      sale: {
        nip_search: '',
      },
      products: {
        codes: '',
      },
      booking_offers: { booking_offers: '' },
    },
    rules: [],
  },
}

export const appSlice = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    setClientDetails(state, action: PayloadAction<ClientDetails | null>) {
      state.clientDetails = action.payload
    },
    setAppData(state, action: PayloadAction<AppDataWithClientDetails>) {
      state.appData = action.payload
      state.clientDetails = action.payload.user
      state.isAppReady = true
    },
  },
})

export const { setAppData, setClientDetails } = appSlice.actions

export const selectAppState = (state: RootState): AppState => state.appState
export const selectAppData = (state: RootState): AppData => state.appState.appData
export const selectClientDetails = (state: RootState): ClientDetails | null => state.appState.clientDetails
export const selectAccountUrls = (state: RootState): AppDataAccountUrls => state.appState.appData.urls.account

export default appSlice.reducer
