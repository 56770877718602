import * as React from 'react'
import { GastroCardMultiplier } from '@models/app-state'
import { formatDate } from '@helpers/date-formatter'
import { useGastroCardDynamicBonusStyle } from '@modules/offers/gastro-card/dynamic/details/modals/offer-description/use-gastro-card-dynamic-bonus-style'
import { formatPercentage, formatPriceShort } from '@helpers/price-helper'
import Decimal from 'decimal.js'
import { useSelector } from 'react-redux'
import { selectClientDetails } from '@store/slices/app-slice'
import { hasActiveSubscriptionContract } from '@modules/offers/gastro-card-boost/utils'

interface MonthlyOfferData {
  month: string
  multiplier: string
}

interface MonthlyOffer {
  amount: string
  data: MonthlyOfferData[]
}

interface Props {
  months: string[]
  sortedGastroMultipliers: GastroCardMultiplier[]
}

export const GastroCardDynamicDescriptionBonuses = ({ months, sortedGastroMultipliers }: Props): React.ReactNode => {
  const client = useSelector(selectClientDetails)
  const hasActiveSubscription = hasActiveSubscriptionContract(client)

  const monthlyOfferData: MonthlyOffer[] = sortedGastroMultipliers.reduce(
    (previousMonthlyOffers: MonthlyOffer[], multiplier) => {
      const dataForAmount = previousMonthlyOffers.find(el => el.amount === multiplier.minimal_amount)
      const dataItem = {
        month: formatDate(multiplier.active_from, 'LLLL'),
        multiplier: hasActiveSubscription ? multiplier.multiplier_value_for_subscriber : multiplier.multiplier_value,
      }

      if (dataForAmount) {
        return previousMonthlyOffers.map((offer: MonthlyOffer) =>
          offer.amount === multiplier.minimal_amount ? { ...offer, data: [...offer.data, dataItem] } : offer,
        )
      }
      return [...previousMonthlyOffers, { amount: multiplier.minimal_amount, data: [dataItem] }]
    },
    [],
  )

  const sortedOffers = monthlyOfferData.sort((a, b) => Number(a.amount) - Number(b.amount))

  return sortedOffers.map(({ amount, data }) => (
    <tr key={amount}>
      <td className="fw-semi-bold text-nowrap">{formatPriceShort(amount)}</td>
      {months.map(month => {
        const monthData = data.find(el => el.month === month)
        return monthData ? <Bonus key={month} monthData={monthData} /> : <td key={month} />
      })}
    </tr>
  ))
}

const Bonus = ({ monthData }) => {
  const { backgroundColor } = useGastroCardDynamicBonusStyle(monthData.multiplier)
  const percentageBonus = new Decimal(monthData.multiplier).minus(1).mul(100).toString()

  return (
    <td className="text-center fw-semi-bold">
      <div className="rounded border text-center p-2 fw-semi-bold" style={{ backgroundColor }}>
        +{formatPercentage(percentageBonus)}
      </div>
    </td>
  )
}
