import * as React from 'react'
import { ToggleSwitch } from '@components/controls/toggle-switch'
import { useFormContext, useWatch } from 'react-hook-form'
import { BookingOffersFiltersParams } from '@modules/booking-offers'
import classNames from 'classnames'

export const BookingOffersFilterSeason = (): JSX.Element => {
  const { setValue, control } = useFormContext<BookingOffersFiltersParams>()
  const activeSeason = useWatch({ control, name: 'season' })
  const isSummerSeasonActive = activeSeason === 'summer'

  const toggleSeason = () => {
    setValue('season', isSummerSeasonActive ? 'winter' : 'summer')
  }

  return (
    <div className="d-flex order-1 order-md-2">
      <div
        className={classNames('d-flex align-items-center me-4  cursor-pointer', {
          'opacity-50': !isSummerSeasonActive,
        })}
        onClick={() => setValue('season', 'summer')}
      >
        <img height={32} width={32} src={require('@assets/images/icons/sun.svg')} alt="Holiday Park & Resort" />
        <strong className="booking-offers__season--summer font-xl">Wiosna / Lato</strong>
      </div>
      <ToggleSwitch onClick={toggleSeason} isActive={isSummerSeasonActive} className="is-gold" />
      <div
        className={classNames('d-flex align-items-center ms-4 cursor-pointer', { 'opacity-50': isSummerSeasonActive })}
        onClick={() => setValue('season', 'winter')}
      >
        <strong className="booking-offers__season--winter font-xl">Jesień / Zima</strong>

        <img height={32} width={32} src={require('@assets/images/icons/snowflake.svg')} alt="Holiday Park & Resort" />
      </div>
    </div>
  )
}
