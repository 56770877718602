import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { CustomFormGroup } from '@components/controls/custom-form-group'
import { getCompanyData } from '@requests/invoice'
import { OfferModalFormInputs } from '@modals/offer-modal/offer-modal'
import { useFormRequest } from '@hooks/use-request'
import { Button } from 'reactstrap'

export const OfferModalPaymentInvoiceTaxNumber = (): JSX.Element => {
  const { getValues, setError, setValue, watch } = useFormContext<OfferModalFormInputs>()

  const invoiceType = watch('invoice_type')

  const { isLoading, action: fetchData } = useFormRequest(async () => {
    const companyData = await getCompanyData(getValues('invoice_nip') as string)
    setValue('invoice_city', companyData.city)
    setValue('invoice_company', companyData.name)
    setValue('invoice_postcode', companyData.postal_code)
    setValue('invoice_street', companyData.street_address)
  }, setError)

  const handleEnterPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      fetchData()
      event.preventDefault()
    }
  }

  return (
    <div className="text-darker-gray mt-3 mt-sm-0">
      {invoiceType === 'company' ? (
        <div className="d-flex">
          <CustomFormGroup
            label="Numer NIP:"
            formLabelProps={{ className: 'fw-bold' }}
            inputName="invoice_nip"
            formGroupProps={{ className: 'pe-3 col-6' }}
            maskedFormControlProps={{
              mask: '***-***-**-**',
              alwaysShowMask: true,
              type: 'tel',
              className: 'letter-spacing-2',
              onKeyDown: handleEnterPress,
            }}
          />
          <Button
            onClick={fetchData}
            type="button"
            disabled={isLoading}
            className="h-fit align-self-end mb-3 mb-xl-1 col-6"
          >
            <span className="fw-semi-bold">{isLoading ? 'Pobieranie..' : 'Pobierz dane z GUS'}</span>
          </Button>
        </div>
      ) : (
        <CustomFormGroup
          label="PESEL:"
          inputName="invoice_nip"
          formControlProps={{ type: 'text' }}
          formLabelProps={{ className: 'fw-bold' }}
          maskedFormControlProps={{
            mask: '***********',
            alwaysShowMask: true,
            className: 'letter-spacing-2',
            type: 'tel',
          }}
        />
      )}
    </div>
  )
}
