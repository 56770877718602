import { commonObjectPost } from '@requests/basic-requests'
import { API_ERROR_CODES, API_ERROR_HEADERS } from '@helpers/consts'
import { useAppSelector } from '@store/index'
import { selectAppData, selectClientDetails } from '@store/slices/app-slice'
import { UseFormSetError } from 'react-hook-form/dist/types/form'
import { FieldValues } from 'react-hook-form/dist/types/fields'
import { FieldPath } from 'react-hook-form'

export const USER_CHECK_MESSAGE = {
  EXISTING_ACTIVE_USER: 'Posiadasz już swoje konto, proszę się zalogować.',
  EXISTING_INACTIVE_USER: 'Podane dane istnieją już w bazie',
}

interface Response {
  checkEmail: (email: string) => Promise<boolean>
}

export const useUserCheck = <T extends FieldValues>(setError: UseFormSetError<T>): Response => {
  const { urls } = useAppSelector(selectAppData)
  const clientDetails = useAppSelector(selectClientDetails)

  const checkEmail = async (email: string) => {
    if (clientDetails) return true

    try {
      await commonObjectPost(urls.account.user_check, { email })
      setError('email' as FieldPath<T>, { message: USER_CHECK_MESSAGE.EXISTING_ACTIVE_USER })
      return false
    } catch (error) {
      if (error.response.status !== 404) return false

      const responseHeaderCode = error.response.headers[API_ERROR_HEADERS.API_ERROR_CODE]

      if (responseHeaderCode === API_ERROR_CODES.NO_USER) {
        return true
      }

      if (responseHeaderCode === API_ERROR_CODES.NO_ACTIVE_USER) {
        setError('email' as FieldPath<T>, { message: USER_CHECK_MESSAGE.EXISTING_INACTIVE_USER })
        return false
      }
    }

    return true
  }

  return {
    checkEmail,
  }
}
