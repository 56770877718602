import * as React from 'react'
import * as clsx from 'clsx'
import { CustomFormGroup } from '@components/controls/custom-form-group'
import { TextWithSpinner } from '@components/loaders/text-with-spinner'
import { useLogin } from '@components/header/login/use-login'
import { useFormContext, useWatch } from 'react-hook-form'
import { useAppSelector } from '@store/index'
import { selectClientDetails } from '@store/slices/app-slice'
import { ClientAppInformation } from '@components/clent-app-information'

export const OfferDetailsPersonalDataLogin = (): JSX.Element => {
  const client = useAppSelector(selectClientDetails)
  const methods = useFormContext()
  const { password, username } = useWatch({ control: methods.control })

  const { isLoginPending, login, redirectToPasswordReset } = useLogin(methods)

  const handleLogin = async () => {
    login(username, password)
  }

  return (
    <div className={clsx('mt-4 mt-md-0', client ? 'col-12' : 'col-md-5 col-12')}>
      <div className="border rounded bg-lighter-gray p-4 h-100 d-flex flex-column">
        <div className="text-center text-md-start">
          <strong className="text-primary font-xxl">Masz konto? Zaloguj się</strong>
          <p>Produkt dodamy do Twojego konta</p>

          <CustomFormGroup
            inputName="username"
            formControlProps={{ placeholder: 'email@nazwa.pl', type: 'email' }}
            label="Wpisz adres e-mail:"
            formLabelProps={{ className: 'font-md fw-semi-bold' }}
          />
          <CustomFormGroup
            inputName="password"
            formControlProps={{ placeholder: 'Hasło', type: 'password' }}
            label="Wpisz hasło:"
            formLabelProps={{ className: 'font-md fw-semi-bold' }}
          />
          <button className="text-primary d-block ms-auto btn-unstyled fw-bold mt-1" onClick={redirectToPasswordReset}>
            Przypomnij hasło
          </button>
          <button
            className="d-block mt-3 btn btn-primary w-100 text-white fw-bold"
            disabled={!username || !password || isLoginPending}
            onClick={handleLogin}
          >
            <TextWithSpinner
              isLoading={isLoginPending}
              loadingText="Proszę czekać"
              wrapperClassName="align-items-center d-flex"
              text="Zaloguj się"
            />
          </button>
        </div>
        <div className="mt-auto">
          <hr className="mx-n4 my-4" />
          <ClientAppInformation />
        </div>
      </div>
    </div>
  )
}
