import { bookingOffersApi } from '@api/booking-offers'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { BookingOffer, BookingOffersResponse } from '@modules/booking-offers/models'

interface Response {
  updateOffer: (bookingOffers: BookingOffer[]) => void
}

export const useBookingOfferOptimisticUpdate = (): Response => {
  const state = useAppSelector((state: RootState) => state)
  const dispatch = useAppDispatch()

  const updateOffer = (bookingOffers: BookingOffer[]) => {
    dispatch(
      bookingOffersApi.util?.updateQueryData(
        'getBookingOffers',
        bookingOffersApi.util?.selectCachedArgsForQuery(state, 'getBookingOffers').at(-1),
        (draft: BookingOffersResponse) => ({
          ...draft,
          results: draft.results.map(offer => {
            const bookingOffer = bookingOffers.find(row => row.id === offer.id)
            return bookingOffer || offer
          }),
        }),
      ),
    )
  }

  return {
    updateOffer,
  }
}
